.section-newsletter{
	position: relative;
	z-index: 10;
	.item-content{
		padding-top: 60px;
		padding-bottom: 60px;
		position: relative;
		z-index: 10;
		background: $burgundy;
        @include media-breakpoint-down(sm) {
			padding: 70px 0;
        }
	}
}
body.page-template-home{
	.section.section-newsletter{
        @include media-breakpoint-down(md) {
        	padding-top: 0px !important;
        	padding-bottom: 0px !important;
		}
	}
}

/*
*
*	ressourceCard
*---------------
*/
.hebdoCard-ctr{
	margin-bottom: 30px;

}
.news-hebdo{
	.hebdoCard-link{
		border-color: $gold;
	}
	.hebdo-label{
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		width: 37px;
		height: 100%;
		background-color: $gold;
		margin: auto;
		&:after{
			content: 'nouveau';
			text-transform: uppercase;
			color: $white;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			position: absolute;
	        transform: rotate(-90deg) translate(-50%, 100%);
		    transform-origin: left bottom;
		    height: 37px;
		    font-size: 0.8em;
		    line-height: 0.8em;
		    letter-spacing: 2px;
		    font-weight: 200;
		    display: flex;
		    justify-content: center;
		    align-items: center;
		    width: auto;
		}
	}
}
.hebdoCard-link{
	position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0px;
	transition: 0.2s linear;
	background-color: $light-gold;
	border: 2px solid $light-gold;
	.hebdoCard-content{
		width: 100%;
		padding:  25px 30px 25px 60px;
		position: relative;
    	z-index: 10;
    	display: flex;
    	flex-wrap: wrap;
    	align-items: center;
	    width: 100%;
	    color: $burgundy;
	    border-bottom: 1px solid $gold;
    	@include media-breakpoint-down(sm){
    		flex-direction: column;
    	}
	}
	> div{
		width: 100%;
	}
	.hebdoCard-text{
		width: 100%;
		padding: 30px 30px 20px 60px;
	    line-height: 1.7em;
        flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.hebdoCard-date{
		padding-right: 20px;
		text-align: center;
		width: 80px;
		position: relative;
		&:after{
			content: '';
			position: absolute;
			margin: auto;
			top: 0;
			bottom: 0;
			right: 0;
			height: 45px;
			width: 4px;
			background-color: $green;
			transform: translateX(50%);
		}
		.day-date{
			font-size: 2em;
			line-height: 1em;
			font-weight: 600;
		}
		.month-date{
			font-size: 0.9em;
			font-weight: 400;
		}
		.year-date{
			font-weight: 200;
			font-size: 1.1em;
			line-height: 1.2em;
		}
	}
	.hebdoCard-title{
		padding:  0px 0px 0px 30px;
		flex: 1;
	}
	.hebdoCard-lieu{
		display: flex;
		align-items:center;
		padding-left: 0;
		padding-right: 10px;
		width: 120px;
	}
	.hebdoCard-svg{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		height: max-content;
	}
	
	&:hover{
		text-decoration: none;
		
	}
}