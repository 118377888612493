/*
|      Principal bottom
|--------------------
*/
$item_principal-color: $burgundy;
$item_principal-hover-color: $gold;

#header {
    width: 100%;
    position: fixed;
    z-index: 500;
    @include media-breakpoint-down("lg") {
        position: fixed;
        top: 0;
    }
    .item-link {
        color: $item_principal-color;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 1.2px;
        transition: 0.2s linear;
        &:hover {
            text-decoration: none;
            color: $light-grey;
        }
    }
    .item-sub-link {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    /*
    |      Search box 
    |--------------------
    */
    #search-box {
        position: absolute;
        bottom: -1px;
        transform: translateY(100%);
        left: 0;
        right: 0;
        overflow: hidden;
        pointer-events: none;
        .search-form {
            padding: 15px;
            display: flex;
            align-items: center;
            background-color: $gold;
            transform: translateY(-100%);
            transition: 0.6s $easeInQuint;
            input[type="search"] {
                position: relative;
                border: 0px;
                padding: 15px;
                width: 100%;
            }
            .btn-block {
                width: 80px;
                .icon {
                    svg {
                        [fill^="#"] {
                            fill: $gold;
                        }
                    }
                    &:after {
                        display: none;
                    }
                }
                &:hover {
                    .icon {
                        svg {
                            [fill^="#"] {
                                fill: $gold;
                            }
                        }
                    }
                }
            }
        }

        &.is-active {
            pointer-events: auto;
            .search-form {
                transform: translateY(0%);
            }
        }
    }

    /*
    |      MENU PRINCIPAL 
    |--------------------
    */
    .header-surmenu {
        position: relative;
        z-index: 5;
        background-color: $white;

        .submenu-line {
            position: absolute;
            left: 10px;
            bottom: 0;
            width: 98%;
            height: 1px;
            z-index: 5;
            background-color: #d9dffc;
        }

        .item-surmenu-link {
            position: relative;
            color: #c59217;

            &:hover {
                .icon-svg-gold {
                    transform: translate3d(3px, -3px, 0px);
                }
            }

            .title-link {
                margin-right: 30px;
            }

            .icon-svg-gold {
                position: absolute;
                display: flex;
                right: 2px;
                bottom: 5px;
                transition: transform 0.4s ease-in-out;

                svg {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }

    .header-surmenu,
    .header-principal {
        position: relative;
        z-index: 5;
        background-color: $white;

        /*
        |      MTYPE DE SOUS menu
        |--------------------
        */
        .header-container {
            border-bottom: 1px solid $white;
            padding-top: 15px;
            padding-bottom: 15px;
            position: relative;
            @include media-breakpoint-down(lg) {
                max-width: 100%;
            }
        }

        .menu-item {
            margin-right: 32px;
            display: flex;
            align-items: center;
            @include media-breakpoint-down(xl) {
                margin-right: 30px;
            }
            @include media-breakpoint-down(lg) {
                margin-right: 15px;
            }
            &.search-wp {
                &:hover {
                }
            }

            &.menu-item-btn {
                margin-left: 32px;
                margin-right: auto !important;
            }

            .btn-block {
                .icon {
                    transition: 0.2s ease-In-Out;
                    width: 40px;
                    border-left: 0px;
                    border-right: 0px;
                    border-top: 0px;
                    padding: 5px;
                    &:after {
                        height: 2px;
                        background-color: $white;
                    }
                }
                &:hover {
                    .icon {
                        &:after {
                            background-color: $blue;
                        }
                        svg {
                            //[fill^="#"]{fill : $blue;}
                        }
                    }
                }
            }
            &:last-child {
                margin-right: 0px;
            }
            .item-link {
                padding: 20px 15px 20px 15px;
                height: auto;
                .text {
                    position: relative;
                    z-index: 2;
                }
                &:hover {
                    color: $gold;
                }
            }

            .header-submenu {
                transition: 0.4s ease-in-out;
                opacity: 0;
                visibility: hidden;
                transition: opacity 1.2s cubic-bezier(0.19, 1, 0.22, 1);
            }
            &.current_page_item {
                .item-link {
                    &:before {
                        opacity: 1;
                        transform: scaleY(1);
                        transform-origin: 0 50%;
                    }
                }
            }
            &.menu-item-has-children {
                .item-link {
                    padding-right: 30px;
                }
                &:hover {
                    .header-submenu {
                        opacity: 1;
                        visibility: visible;
                        .menu-panel {
                            transform: translateY(0%);
                        }
                    }
                }
            }
        }
    }

    .header-submenu {
        z-index: 1;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
    }

    /*
    |      Global
    |--------------------
    */
    .header-container {
        @extend .container;
        display: flex;
        padding-top: 5px;
        padding-bottom: 5px;
        @include media-breakpoint-down("lg") {
            padding-top: 15px;
            padding-bottom: 15px;
        }

        .nav-ctr {
            flex: 1;
            display: flex;
        }
        .logo-ctr {
        }
        .item-logo {
            width: 90px;
            height: auto;
            justify-content: center;
            align-items: center;
            @include media-breakpoint-down("lg") {
                padding-bottom: 0px;
                width: 80px;
                height: 70px;
            }
            svg {
                width: 100%;
                height: 100%;
            }
        }

        .item-nav {
            display: flex;
            justify-content: flex-end;
            flex: 1;

            .item-ul {
                display: flex;
                width: 100%;

                .menu-item {
                    @include media-breakpoint-down("md") {
                        display: none;
                        margin-right: 0px;
                    }
                    &.menu-item-btn {
                        @include media-breakpoint-down("md") {
                            display: block;
                        }
                    }
                    .item-link {
                    }
                }
            }
        }
    }

    li.menu-item-has-children {
        span.ico-target {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 10px;
            height: 10px;
            margin: auto;
            transition: 0.2s linear;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: rotate(90deg);
            svg {
                width: 100%;
                height: 100%;
                path {
                    fill: $red;
                }
            }
        }
        &:hover {
            span.ico-target {
                transform: rotate(-90deg);
            }
        }
    }
    .btn-default {
        @include media-breakpoint-down(xl) {
            padding: 15px 20px 15px 20px;
        }
    }
    .btn-menu {
        margin-top: 5px;
        //margin-left: 50px;
        margin-right: 50px;
        @include media-breakpoint-down(xl) {
            margin-right: 20px;
        }
        @include media-breakpoint-down(lg) {
            margin-right: 0px;
        }

        @include media-breakpoint-down(md) {
            margin-top: 0px;
            margin-right: 0px;
            transform: scale(0.9);
        }
    }
}

.logo-menu-item {
    img {
        width: 70px;
    }
}
