.section-archive{
	position: relative;
	z-index: 10;
	.form-filter{
		transform: translateY(-50%);
		padding: 30px 60px;
		background-color: $gold;
		@include media-breakpoint-down(md){
			transform: none;
		}
	}
}


.section-listing-docs{
	.btn-container{
		position: relative;

		&.loading {
			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 40px;
				height: 40px;
				border-radius: 50%;
				border: 2px solid $white;
				border-top-color: transparent;
				animation: spin 1s linear infinite;
				// background-color: red;
				background-image: url(../img/svg-loaders/puff-orange.svg);
				background-repeat: no-repeat;
				background-size: 2.5rem;
				background-position: center;
			}
		}
	}
	.btn-load-more-doc{
		position: relative;
		pointer-events: auto;
		transition: 0.2s linear;
		&.d-none{
			pointer-events: none;
    		opacity: 0.5;
		}

		&.no-more{
			pointer-events: none;
			opacity: 0;
		}
	}
}