.section-presentation {
	position: relative;
	min-height: 550px;
	display: flex;
	align-items: center;

	.presentation-content {
		position: relative;
		z-index: 5;
		@include media-breakpoint-down(md) {
			img {
				max-width: 270px;
			}
		}
		.cms {
			padding-right: 50px;
			@include media-breakpoint-down(md) {
				padding-right: 0px;
			}
		}
	}
	.presentation-img {
		position: absolute;
		z-index: 1;
		top: 0;
		bottom: 0;
		flex: 0 0 52%;
		max-width: 52%;
		background-color: $white;
		@include media-breakpoint-down(md) {
			flex: 0 0 100%;
			max-width: 100%;
			height: 300px;
			bottom: auto;
		}
	}

	&.section-presentation-left {
		.presentation-img {
			right: auto;
			left: 0;
		}
		.presentation-content .row-item {
			flex-direction: row-reverse;
		}
	}
	&.section-presentation-right {
		.presentation-content .row-item {
			flex-direction: row;
		}
		.presentation-img {
			right: 0;
			left: auto;
		}
	}

	&.presentation-home {
		min-height: 550px;
		@include media-breakpoint-down(md) {
			min-height: auto;
			padding-top: 350px !important;
		}
	}

	&.conseil-administration {
		@include media-breakpoint-down(md) {
			min-height: auto;
			padding-top: 350px !important;
		}
		.img-container .lazy-ctr {
			background-color: #f3e5ca;
		}
	}

	&.presentation-hebdo {
	}

	&.presentation-collapse {
	}

	&.presentation-cms {
		min-height: auto;
	}

	&.presentation-single {
		min-height: auto;
		.row-reverse {
			flex-direction: row-reverse;
			.presentation-text {
				padding-right: 0px;
				padding-left: 80px;
				@include media-breakpoint-down(md) {
					padding-left: 15px;
					padding-right: 15px;
				}
			}
		}
		.presentation-text {
			padding-right: 80px;
			padding-left: 0px;
			@include media-breakpoint-down(md) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		.presentation-title {
			padding-right: 10%;
			@include media-breakpoint-down(md) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		.presentation-text-left {
		}
		.presentation-text-right {
			padding-left: 80px;
			@include media-breakpoint-down(md) {
				padding-left: 15px;
			}
		}
	}
}

.section-thrid-colonne {
	.bg-img-portrait.bg-img-portrait-bottom {
		.lazy-ctr-contain {
			img {
				bottom: auto;
			}
		}
	}
}
