/* Bootstrap lightweight */
/*!
 * Bootstrap Grid v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  text-align: left; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0; }

p {
  margin-top: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bold; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

th {
  text-align: inherit; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container, #header .header-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container, #header .header-container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, #header .header-container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, #header .header-container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container, #header .header-container {
      max-width: 1140px; } }
  @media (min-width: 1400px) {
    .container, #header .header-container {
      max-width: 1400px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row, .block-form .gform_body .gform_fields, .gravity-form-bootstrap .gform_fields {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.4s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

/* Function */
/*
|---------------------------------------------------------------
| Functions
|---------------------------------------------------------------
| Functions used to add classes to generation
|
|
*/
/*
|
| Add Buttons
|-----------------------
|
*/
/*
|
| Add Blocks
|-----------------------
|
*/
/*
|
| Add Links
|-----------------------
|
*/
/* variables */
/*
|---------------------------------------------------------------
| Variables
|---------------------------------------------------------------
| Import variables
|
|
*/
/*
|---------------------------------------------------------------
| SPACES
|---------------------------------------------------------------
| Margins and paddings
|
|
*/
/*
|--------------
| Space sizes
|--------------
| Variables used to generate magins & paddings classes (including responsive)
| - Normal classes: .{property}-{size} / .mb-md / margin-bottom: $md  
| - Responsive classes: .{screen}-{up/down/only}-{property}-{size} / .md-down-mb-md /  (max-width: 992px) { margin-bottom: $md }
|
*/
/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/
/*
|
| Colors declaration
|----------------------
|
*/
/*
|
| Assign colors
|---------------
|
*/
/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .color-{color}
|
*/
/*
|---------------------------------------------------------------
| TEXTS
|---------------------------------------------------------------
| Set all text properties
|
|
*/
/*
|
|
| FONT-SIZES
|
|
|----------------------------------------------
| Font sizes settings (for each screen sizes)
|----------------------------------------------
| Variables used to generate font classes (including responsive)
| - Generated classes: .font-{size}
| - Exemple: .font-md
|
*/
/*
|
|
| TITLE-SIZES
|
|
|-----------------------------------------------
| Title sizes settings (for each screen sizes)
|-----------------------------------------------
| Variables used to generate title classes (including responsive)
| - Generated classes: .title-{size}
| - Exemple: .title-md
|
*/
/*
|
| FONT-FAMILIES
|----------------
|
*/
/*
|-------------------------------
| Font family classes creation
|-------------------------------
| How to use: add lines to create more "font family classes" 
| - Generated classes: .font-{family}
| - Exemple: .font-custom
|
*/
/*
|-----------------
| LETTER-SPACING
|-----------------
| Variables used to generate letter-spacing classes
| - Generated classes: .ls-{size}
| - Exemple: .ls-md
|
*/
/*
|
| LINE-HEIGHT
|--------------
|
*/
/*
|
| Default font settings
|------------------------
|
*/
/*
|
| Default titles settings
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| LINKS
|---------------------------------------------------------------
| Generate and assign links colors
|
|
*/
/*
|
| Links default
|----------------
|
*/
/*
|------------------------
| Links colors creation
|------------------------
| - How to use: Add --> $links-colors: map-set-links($map, $index, $color, $color-hover)
| - Generated classes: .link-{color} & .links-{color} (on parent)
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Buttons base default
|----------------------
|
*/
/*
|
| Buttons size setting
|-----------------------
|
*/
/*
|-------------------
| Buttons creation
|-------------------
| - How to use: $button-colors: map-set-buttons($map, $index, $background-color, $border-color, $text-color)
| - Generated classes: .btn-{size}, .btn-{color}, .btn-icon-{direction}.
|
*/
/*
|---------------------------------------------------------------
| SECTIONS
|---------------------------------------------------------------
| Generate responsive sections
|
|
*/
/*
|-------------------
| Sections setting
|-------------------
| - Generated class: .section
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Bg img default
|----------------------
|
*/
/*
|-------------------
| Bg img creation
|-------------------
| - Generated classes: .bg-img-{size}
|
*/
/* Components */
/*
|---------------------------------------------------------------
| Helpers
|---------------------------------------------------------------
| Define helper classes
|
|
*/
/*
|
| Text Transform
|----------------------------
|
*/
.tt-u {
  text-transform: uppercase !important; }

.tt-l {
  text-transform: lowercase !important; }

.tt-c {
  text-transform: capitalize !important; }

/*
|
| Underline
|------------
|
*/
.td-u {
  text-decoration: underline !important; }

.td-n {
  text-decoration: none !important; }

/*
|
| Font styles
|--------------
|
*/
.fs-i {
  font-style: italic !important; }

.fs-o {
  font-style: oblique !important; }

.fs-n {
  font-style: normal !important; }

/*
|
| Background cover
|-------------------
|
*/
.bg-cover, .bg-cover-top, .bg-cover-bottom {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important; }

.bg-cover-top {
  background-position: center top !important; }

.bg-cover-bottom {
  background-position: center bottom !important; }

/*
|
| Overlays
|-------------
|
*/
.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6); }
  .overlay + * {
    position: relative;
    z-index: 2; }

/*
|
| Overflows
|-------------
|
*/
.o-v {
  overflow: visible !important; }

.ox-v {
  overflow-x: visible !important; }

.oy-v {
  overflow-y: visible !important; }

.o-h {
  overflow: hidden !important; }

.ox-h {
  overflow-x: hidden !important; }

.oy-h {
  overflow-y: hidden !important; }

/*
|
| Positions
|-------------
|
*/
.p-r {
  position: relative !important; }

.p-a {
  position: absolute !important; }

.p-f {
  position: fixed !important; }

.p-s {
  position: static !important; }

/*
|
| Flex Alignment
|-----------------
|
*/
.ai-start {
  align-items: flex-start; }

.ai-end {
  align-items: flex-end; }

.ai-center {
  align-items: center; }

.ai-baseline {
  align-items: baseline; }

.ai-stretch {
  align-items: stretch; }

/*
|
| Flex Justify
|---------------
|
*/
.jc-start {
  justify-content: flex-start; }

.jc-end {
  justify-content: flex-end; }

.jc-center {
  justify-content: center; }

.jc-between {
  justify-content: space-between; }

.jc-around {
  justify-content: space-around; }

/*
|
| White space
|--------------
|
*/
.ws-inherit {
  white-space: inherit; }

.ws-normal {
  white-space: normal; }

.ws-nowrap {
  white-space: nowrap; }

.ws-pre {
  white-space: pre; }

.ws-pre-wrap {
  white-space: pre-wrap; }

.ws-pre-line {
  white-space: pre-line; }

/*
|
| Pointer events
|-----------------
|
*/
.pe-inherit {
  pointer-events: inherit; }

.pe-none {
  pointer-events: none; }

.pe-auto {
  pointer-events: auto; }

/*
|
| Optimize animation
|---------------------
|
*/
.optimize-animation {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| absolute-full
|-----------------
|
*/
.absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/*
|
| Clear floats
|---------------
|
*/
.clear {
  clear: both; }

/*
|
| img full
|-----------
|
*/
.img-full {
  display: inline-block;
  max-width: inherit;
  width: 100%; }

/*
|
| W-100 H-100
|-----------
|
*/
.w-100 {
  width: 100%; }

.h-100 {
  height: 100%; }

/* Core */
/*
|---------------------------------------------------------------
| MAPS
|---------------------------------------------------------------
| Sass maps used in classes generation
|
|
*/
/*
|
| Font Sizes Map
|---------------------
|
*/
/*
|
| Title Sizes Map
|---------------------
|
*/
/*
|
| Buttons Sizes Map
|---------------------
|
*/
/*
|
| Margins & Paddings Maps
|--------------------------
|
*/
/*
|-------------------------------
| Font weight classes creation
|-------------------------------
| How to use: add lines to create more "font weight classes"
| - Generated classes: .font-{weight}
| - Exemple: .font-regular
|
*/
/*
|
| Font-weights
|---------------
|
*/
/*
|
| Text Align Map
|--------------------------
|
*/
/*
|-------------------------------
| Line-height classes creation
|-------------------------------
| How to use: add lines to create more "line-height classes"
| - Generated classes: .lh-{weight}
| - Exemple: .lh-sm
|
*/
/*
|
| Letter Spacing Map
|--------------------------
|
*/
/*
|
| Display Map
|--------------------------
|
*/
/*
|
| Section Map
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| MIXINS
|---------------------------------------------------------------
| Mixins called from generator to generate classes
|
|
*/
/*
|
| Commonly used mixins
|-----------------------
|
*/
/*
|
| Margins & Paddings
|-----------------------
|
*/
/*
|
| Responsive Margins & Paddings
|--------------------------------
|
*/
/*
|
| Font-sizes
|-----------------------
|
*/
/*
|
| Titles
|-----------------------
|
*/
/*
|
| Links
|-----------------------
|
*/
/*
|
| Buttons
|-----------------------
|
*/
/*
|
| Hidden classes
|-----------------------
|
*/
/*
|
| Display
|-----------------------
|
*/
/*
|
| Text Align
|-----------------------
|
*/
/*
|
| Sections
|-----------------------
|
*/
/*
|
| svg color
|-------------
|
*/
/*
|
| Bg imgs
|-------------
|
*/
/*
|---------------------------------------------------------------
| CLASS GENERATOR
|---------------------------------------------------------------
| Mixins calls to generate classes
|
|
*/
/*------- DISPLAY -------*/
@media (max-width: 1199.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 991.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 992px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1200px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1199.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 991.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 992px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1200px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1199.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 991.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 992px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1200px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 991.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 992px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1200px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 991.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 992px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1200px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1199.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 991.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 992px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1200px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- FONT-SIZES -------*/
.fs-xs {
  font-size: 12px !important; }
  @media (max-width: 1199.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 991.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 767.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 575.98px) {
    .fs-xs {
      font-size: 12px !important; } }

.fs-sm {
  font-size: 16px !important; }
  @media (max-width: 1199.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 991.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 767.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 575.98px) {
    .fs-sm {
      font-size: 16px !important; } }

.fs-md {
  font-size: 18px !important; }
  @media (max-width: 1199.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 991.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 767.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 575.98px) {
    .fs-md {
      font-size: 18px !important; } }

.fs-lg {
  font-size: 28px !important; }
  @media (max-width: 1199.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 991.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 767.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 575.98px) {
    .fs-lg {
      font-size: 28px !important; } }

.fs-xl {
  font-size: 36px !important; }
  @media (max-width: 1199.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 991.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 767.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 575.98px) {
    .fs-xl {
      font-size: 36px !important; } }

.fs-xxl {
  font-size: 36px !important; }
  @media (max-width: 1199.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 991.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 767.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 575.98px) {
    .fs-xxl {
      font-size: 36px !important; } }

/*------- TITLES -------*/
.title-xs, .cms h6 {
  color: inherit;
  font-family: "TheSans", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 16px; }
  @media (max-width: 1199.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 991.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 767.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 575.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }

.title-sm, .cms h5 {
  color: inherit;
  font-family: "TheSans", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 22px; }
  @media (max-width: 1199.98px) {
    .title-sm, .cms h5 {
      font-size: 22px; } }
  @media (max-width: 991.98px) {
    .title-sm, .cms h5 {
      font-size: 22px; } }
  @media (max-width: 767.98px) {
    .title-sm, .cms h5 {
      font-size: 22px; } }
  @media (max-width: 575.98px) {
    .title-sm, .cms h5 {
      font-size: 22px; } }

.title-md, .cms h4 {
  color: inherit;
  font-family: "TheSans", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 26px; }
  @media (max-width: 1199.98px) {
    .title-md, .cms h4 {
      font-size: 26px; } }
  @media (max-width: 991.98px) {
    .title-md, .cms h4 {
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .title-md, .cms h4 {
      font-size: 26px; } }
  @media (max-width: 575.98px) {
    .title-md, .cms h4 {
      font-size: 26px; } }

.title-lg, .cms h3 {
  color: inherit;
  font-family: "TheSans", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 29px; }
  @media (max-width: 1199.98px) {
    .title-lg, .cms h3 {
      font-size: 29px; } }
  @media (max-width: 991.98px) {
    .title-lg, .cms h3 {
      font-size: 29px; } }
  @media (max-width: 767.98px) {
    .title-lg, .cms h3 {
      font-size: 29px; } }
  @media (max-width: 575.98px) {
    .title-lg, .cms h3 {
      font-size: 29px; } }

.title-xl, .cms h2 {
  color: inherit;
  font-family: "TheSans", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 41px; }
  @media (max-width: 1199.98px) {
    .title-xl, .cms h2 {
      font-size: 42px; } }
  @media (max-width: 991.98px) {
    .title-xl, .cms h2 {
      font-size: 42px; } }
  @media (max-width: 767.98px) {
    .title-xl, .cms h2 {
      font-size: 28px; } }
  @media (max-width: 575.98px) {
    .title-xl, .cms h2 {
      font-size: 28px; } }

.title-xxl, .cms h1, #page-404 .section-content .item-title {
  color: inherit;
  font-family: "TheSans", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 50px; }
  @media (max-width: 1199.98px) {
    .title-xxl, .cms h1, #page-404 .section-content .item-title {
      font-size: 50px; } }
  @media (max-width: 991.98px) {
    .title-xxl, .cms h1, #page-404 .section-content .item-title {
      font-size: 50px; } }
  @media (max-width: 767.98px) {
    .title-xxl, .cms h1, #page-404 .section-content .item-title {
      font-size: 35px; } }
  @media (max-width: 575.98px) {
    .title-xxl, .cms h1, #page-404 .section-content .item-title {
      font-size: 35px; } }

/*------- FONT-FAMILY -------*/
.ff-default {
  font-family: "TheSans", sans-serif ; }

.ff-custom {
  font-family: "TheSans", sans-serif ; }

.ff-extra {
  font-family: "TheSans", sans-serif ; }

/*------- FONT-WEIGHTS -------*/
.fw-100 {
  font-weight: 100 ; }

.fw-200 {
  font-weight: 200 ; }

.fw-300 {
  font-weight: 300 ; }

.fw-400 {
  font-weight: 400 ; }

.fw-500 {
  font-weight: 500 ; }

.fw-600 {
  font-weight: 600 ; }

.fw-700 {
  font-weight: 700 ; }

.fw-800 {
  font-weight: 800 ; }

.fw-900 {
  font-weight: 900 ; }

/*------- LINE-HEIGHTS -------*/
.lh-xs {
  line-height: 1 ; }

.lh-sm {
  line-height: 1.2 ; }

.lh-md {
  line-height: 1.5 ; }

.lh-lg {
  line-height: 1.6 ; }

.lh-xl {
  line-height: 1.8 ; }

.lh-xxl {
  line-height: 2 ; }

/*------- COLORS -------*/
.c-green {
  color: #BED600 ; }

.c-very-light-blue {
  color: #ECF0FA ; }

.c-light-blue {
  color: #EBEFFA ; }

.c-blue {
  color: #1A4194 ; }

.c-dark-blue {
  color: #29264C ; }

.c-red {
  color: #E42022 ; }

.c-dark-red {
  color: #E42022 ; }

.c-burgundy {
  color: #6E273D ; }

.c-dark-burgundy {
  color: #501d2d ; }

.c-very-dark-burgundy {
  color: #381520 ; }

.c-light-gold {
  color: #f9f4e7 ; }

.c-gold {
  color: #C59217 ; }

.c-dark-gold {
  color: #623C1B ; }

.c-black {
  color: #000000 ; }

.c-light-brown {
  color: #A5764E ; }

.c-brown {
  color: #623C1B ; }

.c-very-dark-grey {
  color: #3A3A3A ; }

.c-dark-grey {
  color: #646464 ; }

.c-grey {
  color: #333333 ; }

.c-beige {
  color: #dec27d ; }

.c-light-grey {
  color: #949494 ; }

.c-very-light-grey {
  color: #F6F6F6 ; }

.c-white {
  color: #FFFFFF ; }

/*------- LINKS -------*/
.svg-green [fill^="#"] {
  fill: #BED600; }

.svg-green [stroke^="#"] {
  stroke: #BED600; }

.svg-green[fill^="#"] {
  fill: #BED600; }

.svg-green[stroke^="#"] {
  stroke: #BED600; }

.svg-very-light-blue [fill^="#"] {
  fill: #ECF0FA; }

.svg-very-light-blue [stroke^="#"] {
  stroke: #ECF0FA; }

.svg-very-light-blue[fill^="#"] {
  fill: #ECF0FA; }

.svg-very-light-blue[stroke^="#"] {
  stroke: #ECF0FA; }

.svg-light-blue [fill^="#"] {
  fill: #EBEFFA; }

.svg-light-blue [stroke^="#"] {
  stroke: #EBEFFA; }

.svg-light-blue[fill^="#"] {
  fill: #EBEFFA; }

.svg-light-blue[stroke^="#"] {
  stroke: #EBEFFA; }

.svg-blue [fill^="#"] {
  fill: #1A4194; }

.svg-blue [stroke^="#"] {
  stroke: #1A4194; }

.svg-blue[fill^="#"] {
  fill: #1A4194; }

.svg-blue[stroke^="#"] {
  stroke: #1A4194; }

.svg-dark-blue [fill^="#"] {
  fill: #29264C; }

.svg-dark-blue [stroke^="#"] {
  stroke: #29264C; }

.svg-dark-blue[fill^="#"] {
  fill: #29264C; }

.svg-dark-blue[stroke^="#"] {
  stroke: #29264C; }

.svg-red [fill^="#"] {
  fill: #E42022; }

.svg-red [stroke^="#"] {
  stroke: #E42022; }

.svg-red[fill^="#"] {
  fill: #E42022; }

.svg-red[stroke^="#"] {
  stroke: #E42022; }

.svg-dark-red [fill^="#"] {
  fill: #E42022; }

.svg-dark-red [stroke^="#"] {
  stroke: #E42022; }

.svg-dark-red[fill^="#"] {
  fill: #E42022; }

.svg-dark-red[stroke^="#"] {
  stroke: #E42022; }

.svg-burgundy [fill^="#"] {
  fill: #6E273D; }

.svg-burgundy [stroke^="#"] {
  stroke: #6E273D; }

.svg-burgundy[fill^="#"] {
  fill: #6E273D; }

.svg-burgundy[stroke^="#"] {
  stroke: #6E273D; }

.svg-dark-burgundy [fill^="#"] {
  fill: #501d2d; }

.svg-dark-burgundy [stroke^="#"] {
  stroke: #501d2d; }

.svg-dark-burgundy[fill^="#"] {
  fill: #501d2d; }

.svg-dark-burgundy[stroke^="#"] {
  stroke: #501d2d; }

.svg-very-dark-burgundy [fill^="#"] {
  fill: #381520; }

.svg-very-dark-burgundy [stroke^="#"] {
  stroke: #381520; }

.svg-very-dark-burgundy[fill^="#"] {
  fill: #381520; }

.svg-very-dark-burgundy[stroke^="#"] {
  stroke: #381520; }

.svg-light-gold [fill^="#"] {
  fill: #f9f4e7; }

.svg-light-gold [stroke^="#"] {
  stroke: #f9f4e7; }

.svg-light-gold[fill^="#"] {
  fill: #f9f4e7; }

.svg-light-gold[stroke^="#"] {
  stroke: #f9f4e7; }

.svg-gold [fill^="#"] {
  fill: #C59217; }

.svg-gold [stroke^="#"] {
  stroke: #C59217; }

.svg-gold[fill^="#"] {
  fill: #C59217; }

.svg-gold[stroke^="#"] {
  stroke: #C59217; }

.svg-dark-gold [fill^="#"] {
  fill: #623C1B; }

.svg-dark-gold [stroke^="#"] {
  stroke: #623C1B; }

.svg-dark-gold[fill^="#"] {
  fill: #623C1B; }

.svg-dark-gold[stroke^="#"] {
  stroke: #623C1B; }

.svg-black [fill^="#"] {
  fill: #000000; }

.svg-black [stroke^="#"] {
  stroke: #000000; }

.svg-black[fill^="#"] {
  fill: #000000; }

.svg-black[stroke^="#"] {
  stroke: #000000; }

.svg-light-brown [fill^="#"] {
  fill: #A5764E; }

.svg-light-brown [stroke^="#"] {
  stroke: #A5764E; }

.svg-light-brown[fill^="#"] {
  fill: #A5764E; }

.svg-light-brown[stroke^="#"] {
  stroke: #A5764E; }

.svg-brown [fill^="#"] {
  fill: #623C1B; }

.svg-brown [stroke^="#"] {
  stroke: #623C1B; }

.svg-brown[fill^="#"] {
  fill: #623C1B; }

.svg-brown[stroke^="#"] {
  stroke: #623C1B; }

.svg-very-dark-grey [fill^="#"] {
  fill: #3A3A3A; }

.svg-very-dark-grey [stroke^="#"] {
  stroke: #3A3A3A; }

.svg-very-dark-grey[fill^="#"] {
  fill: #3A3A3A; }

.svg-very-dark-grey[stroke^="#"] {
  stroke: #3A3A3A; }

.svg-dark-grey [fill^="#"] {
  fill: #646464; }

.svg-dark-grey [stroke^="#"] {
  stroke: #646464; }

.svg-dark-grey[fill^="#"] {
  fill: #646464; }

.svg-dark-grey[stroke^="#"] {
  stroke: #646464; }

.svg-grey [fill^="#"] {
  fill: #333333; }

.svg-grey [stroke^="#"] {
  stroke: #333333; }

.svg-grey[fill^="#"] {
  fill: #333333; }

.svg-grey[stroke^="#"] {
  stroke: #333333; }

.svg-beige [fill^="#"] {
  fill: #dec27d; }

.svg-beige [stroke^="#"] {
  stroke: #dec27d; }

.svg-beige[fill^="#"] {
  fill: #dec27d; }

.svg-beige[stroke^="#"] {
  stroke: #dec27d; }

.svg-light-grey [fill^="#"] {
  fill: #949494; }

.svg-light-grey [stroke^="#"] {
  stroke: #949494; }

.svg-light-grey[fill^="#"] {
  fill: #949494; }

.svg-light-grey[stroke^="#"] {
  stroke: #949494; }

.svg-very-light-grey [fill^="#"] {
  fill: #F6F6F6; }

.svg-very-light-grey [stroke^="#"] {
  stroke: #F6F6F6; }

.svg-very-light-grey[fill^="#"] {
  fill: #F6F6F6; }

.svg-very-light-grey[stroke^="#"] {
  stroke: #F6F6F6; }

.svg-white [fill^="#"] {
  fill: #FFFFFF; }

.svg-white [stroke^="#"] {
  stroke: #FFFFFF; }

.svg-white[fill^="#"] {
  fill: #FFFFFF; }

.svg-white[stroke^="#"] {
  stroke: #FFFFFF; }

/*------- LINKS -------*/
.link-white {
  color: #FFFFFF; }
  .link-white:hover {
    color: #949494; }

.links-white a {
  color: #FFFFFF; }
  .links-white a:hover {
    color: #949494; }

.link-black {
  color: #000000; }
  .link-black:hover {
    color: #333333; }

.links-black a {
  color: #000000; }
  .links-black a:hover {
    color: #333333; }

/*------- BUTTONS -------*/
.btn, .btn-black, .btn-white, .btn-xs, .btn-sm, .btn-md, .btn-lg {
  position: relative;
  font-family: "TheSans", sans-serif;
  display: inline-block;
  padding: 16px 35px 15px 35px;
  font-size: 1em;
  background: #FFFFFF;
  color: #A5764E;
  border: 1px solid #949494;
  border-radius: 30px;
  transition: all 0.3s;
  box-shadow: none !important;
  white-space: inherit;
  cursor: pointer; }
  .btn:hover, .btn-black:hover, .btn-white:hover, .btn-xs:hover, .btn-sm:hover, .btn-md:hover, .btn-lg:hover {
    color: #A5764E; }
  .btn .icon, .btn-black .icon, .btn-white .icon, .btn-xs .icon, .btn-sm .icon, .btn-md .icon, .btn-lg .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn .icon svg, .btn-black .icon svg, .btn-white .icon svg, .btn-xs .icon svg, .btn-sm .icon svg, .btn-md .icon svg, .btn-lg .icon svg, .btn .icon img, .btn-black .icon img, .btn-white .icon img, .btn-xs .icon img, .btn-sm .icon img, .btn-md .icon img, .btn-lg .icon img {
      width: 100%;
      max-height: 100%; }
  .btn.btn-icon-right, .btn-icon-right.btn-black, .btn-icon-right.btn-white, .btn-icon-right.btn-xs, .btn-icon-right.btn-sm, .btn-icon-right.btn-md, .btn-icon-right.btn-lg {
    text-align: right;
    padding-right: 60px; }
    .btn.btn-icon-right .icon, .btn-icon-right.btn-black .icon, .btn-icon-right.btn-white .icon, .btn-icon-right.btn-xs .icon, .btn-icon-right.btn-sm .icon, .btn-icon-right.btn-md .icon, .btn-icon-right.btn-lg .icon {
      right: 20px; }
  .btn.btn-icon-left, .btn-icon-left.btn-black, .btn-icon-left.btn-white, .btn-icon-left.btn-xs, .btn-icon-left.btn-sm, .btn-icon-left.btn-md, .btn-icon-left.btn-lg {
    text-align: left;
    padding-left: 60px; }
    .btn.btn-icon-left .icon, .btn-icon-left.btn-black .icon, .btn-icon-left.btn-white .icon, .btn-icon-left.btn-xs .icon, .btn-icon-left.btn-sm .icon, .btn-icon-left.btn-md .icon, .btn-icon-left.btn-lg .icon {
      left: 20px; }

.btn-black {
  background-color: #000000;
  border-color: #000000;
  color: #FFFFFF; }
  .btn-black:hover {
    color: #FFFFFF; }
  .btn-black .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-black .icon svg, .btn-black .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-black.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-black.btn-icon-right .icon {
      right: 20px; }
  .btn-black.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-black.btn-icon-left .icon {
      left: 20px; }

.btn-white {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: #000000; }
  .btn-white:hover {
    color: #000000; }
  .btn-white .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-white .icon svg, .btn-white .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-white.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-white.btn-icon-right .icon {
      right: 20px; }
  .btn-white.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-white.btn-icon-left .icon {
      left: 20px; }

.btn-xs {
  padding: 2px 5px;
  font-size: 10px; }
  .btn-xs .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 10px; }
    .btn-xs .icon svg, .btn-xs .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-xs.btn-icon-right {
    text-align: right;
    padding-right: 25px; }
    .btn-xs.btn-icon-right .icon {
      right: 5px; }
  .btn-xs.btn-icon-left {
    text-align: left;
    padding-left: 25px; }
    .btn-xs.btn-icon-left .icon {
      left: 5px; }

.btn-sm {
  padding: 5px 10px;
  font-size: 14px; }
  .btn-sm .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 20px; }
    .btn-sm .icon svg, .btn-sm .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-sm.btn-icon-right {
    text-align: right;
    padding-right: 40px; }
    .btn-sm.btn-icon-right .icon {
      right: 10px; }
  .btn-sm.btn-icon-left {
    text-align: left;
    padding-left: 40px; }
    .btn-sm.btn-icon-left .icon {
      left: 10px; }

.btn-md {
  padding: 10px 20px;
  font-size: 16px; }
  .btn-md .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-md .icon svg, .btn-md .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-md.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-md.btn-icon-right .icon {
      right: 20px; }
  .btn-md.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-md.btn-icon-left .icon {
      left: 20px; }

.btn-lg {
  padding: 20px 30px;
  font-size: 20px; }
  .btn-lg .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 50px; }
    .btn-lg .icon svg, .btn-lg .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-lg.btn-icon-right {
    text-align: right;
    padding-right: 90px; }
    .btn-lg.btn-icon-right .icon {
      right: 30px; }
  .btn-lg.btn-icon-left {
    text-align: left;
    padding-left: 90px; }
    .btn-lg.btn-icon-left .icon {
      left: 30px; }

/*------- BACKGROUND-COLORS -------*/
.bg-green {
  background-color: #BED600 ; }

.bg-very-light-blue {
  background-color: #ECF0FA ; }

.bg-light-blue {
  background-color: #EBEFFA ; }

.bg-blue {
  background-color: #1A4194 ; }

.bg-dark-blue {
  background-color: #29264C ; }

.bg-red {
  background-color: #E42022 ; }

.bg-dark-red {
  background-color: #E42022 ; }

.bg-burgundy {
  background-color: #6E273D ; }

.bg-dark-burgundy {
  background-color: #501d2d ; }

.bg-very-dark-burgundy {
  background-color: #381520 ; }

.bg-light-gold {
  background-color: #f9f4e7 ; }

.bg-gold {
  background-color: #C59217 ; }

.bg-dark-gold {
  background-color: #623C1B ; }

.bg-black {
  background-color: #000000 ; }

.bg-light-brown {
  background-color: #A5764E ; }

.bg-brown {
  background-color: #623C1B ; }

.bg-very-dark-grey {
  background-color: #3A3A3A ; }

.bg-dark-grey {
  background-color: #646464 ; }

.bg-grey {
  background-color: #333333 ; }

.bg-beige {
  background-color: #dec27d ; }

.bg-light-grey {
  background-color: #949494 ; }

.bg-very-light-grey {
  background-color: #F6F6F6 ; }

.bg-white {
  background-color: #FFFFFF ; }

/*------- TEXT-ALIGN -------*/
.ta-r {
  text-align: right ; }

.ta-l {
  text-align: left ; }

.ta-c {
  text-align: center ; }

.ta-j {
  text-align: justify ; }

@media (max-width: 1199.98px) {
  .lg-down-ta-r {
    text-align: right; } }

@media (max-width: 991.98px) {
  .md-down-ta-r {
    text-align: right; } }

@media (max-width: 767.98px) {
  .sm-down-ta-r {
    text-align: right; } }

@media (max-width: 575.98px) {
  .xs-down-ta-r {
    text-align: right; } }

.xs-up-ta-r {
  text-align: right; }

@media (min-width: 576px) {
  .sm-up-ta-r {
    text-align: right; } }

@media (min-width: 768px) {
  .md-up-ta-r {
    text-align: right; } }

@media (min-width: 992px) {
  .lg-up-ta-r {
    text-align: right; } }

@media (min-width: 1200px) {
  .xl-up-ta-r {
    text-align: right; } }

@media (max-width: 1199.98px) {
  .lg-down-ta-l {
    text-align: left; } }

@media (max-width: 991.98px) {
  .md-down-ta-l {
    text-align: left; } }

@media (max-width: 767.98px) {
  .sm-down-ta-l {
    text-align: left; } }

@media (max-width: 575.98px) {
  .xs-down-ta-l {
    text-align: left; } }

.xs-up-ta-l {
  text-align: left; }

@media (min-width: 576px) {
  .sm-up-ta-l {
    text-align: left; } }

@media (min-width: 768px) {
  .md-up-ta-l {
    text-align: left; } }

@media (min-width: 992px) {
  .lg-up-ta-l {
    text-align: left; } }

@media (min-width: 1200px) {
  .xl-up-ta-l {
    text-align: left; } }

@media (max-width: 1199.98px) {
  .lg-down-ta-c {
    text-align: center; } }

@media (max-width: 991.98px) {
  .md-down-ta-c {
    text-align: center; } }

@media (max-width: 767.98px) {
  .sm-down-ta-c {
    text-align: center; } }

@media (max-width: 575.98px) {
  .xs-down-ta-c {
    text-align: center; } }

.xs-up-ta-c {
  text-align: center; }

@media (min-width: 576px) {
  .sm-up-ta-c {
    text-align: center; } }

@media (min-width: 768px) {
  .md-up-ta-c {
    text-align: center; } }

@media (min-width: 992px) {
  .lg-up-ta-c {
    text-align: center; } }

@media (min-width: 1200px) {
  .xl-up-ta-c {
    text-align: center; } }

@media (max-width: 1199.98px) {
  .lg-down-ta-j {
    text-align: justify; } }

@media (max-width: 991.98px) {
  .md-down-ta-j {
    text-align: justify; } }

@media (max-width: 767.98px) {
  .sm-down-ta-j {
    text-align: justify; } }

@media (max-width: 575.98px) {
  .xs-down-ta-j {
    text-align: justify; } }

.xs-up-ta-j {
  text-align: justify; }

@media (min-width: 576px) {
  .sm-up-ta-j {
    text-align: justify; } }

@media (min-width: 768px) {
  .md-up-ta-j {
    text-align: justify; } }

@media (min-width: 992px) {
  .lg-up-ta-j {
    text-align: justify; } }

@media (min-width: 1200px) {
  .xl-up-ta-j {
    text-align: justify; } }

/*------- LETTER SPACING -------*/
.ls-xs {
  letter-spacing: 1px ; }

.ls-sm {
  letter-spacing: 2px ; }

.ls-md {
  letter-spacing: 3px ; }

.ls-lg {
  letter-spacing: 4px ; }

.ls-xl {
  letter-spacing: 5px ; }

.ls-xxl {
  letter-spacing: 6px ; }

/*------- DISPLAYS -------*/
.d-none {
  display: none ; }

.d-i {
  display: inline ; }

.d-b {
  display: block ; }

.d-ib {
  display: inline-block ; }

.d-f {
  display: flex ; }

.d-if {
  display: inline-flex ; }

@media (max-width: 1199.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 991.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 992px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1200px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1199.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 991.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 992px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1200px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1199.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 991.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 992px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1200px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 991.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 992px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1200px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 991.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 992px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1200px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1199.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 991.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 992px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1200px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- SECTIONS -------*/
.section {
  position: relative;
  padding-top: 70px !important;
  padding-bottom: 70px !important; }
  @media (max-width: 1199.98px) {
    .section {
      padding-top: 70px !important;
      padding-bottom: 70px !important; } }
  @media (max-width: 991.98px) {
    .section {
      padding-top: 50px !important;
      padding-bottom: 50px !important; } }
  @media (max-width: 767.98px) {
    .section {
      padding-top: 30px !important;
      padding-bottom: 30px !important; } }
  @media (max-width: 575.98px) {
    .section {
      padding-top: 30px !important;
      padding-bottom: 30px !important; } }

/*------- BG IMG -------*/
.bg-img, .bg-img-square, .bg-img-almost-square, .bg-img-landscape, .bg-img-landscape-sm, .bg-img-portrait, .bg-img-video, .bg-img-square-sm, .bg-img-landscape-xs {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .bg-img:after, .bg-img-square:after, .bg-img-almost-square:after, .bg-img-landscape:after, .bg-img-landscape-sm:after, .bg-img-portrait:after, .bg-img-video:after, .bg-img-square-sm:after, .bg-img-landscape-xs:after {
    content: '';
    display: block; }

.bg-img-square:after {
  padding-bottom: 100%; }

.bg-img-almost-square:after {
  padding-bottom: 85%; }

.bg-img-landscape:after {
  padding-bottom: 70%; }

.bg-img-landscape-sm:after {
  padding-bottom: 60%; }

.bg-img-portrait:after {
  padding-bottom: 130%; }

/*------- MARGINS & PADDINGS -------*/
.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs {
  margin: 5px !important; }

.mt-xs {
  margin-top: 5px !important; }

.mb-xs {
  margin-bottom: 5px !important; }

.my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs {
  margin-left: 5px !important; }

.mr-xs {
  margin-right: 5px !important; }

.mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs {
  padding: 5px !important; }

.pt-xs {
  padding-top: 5px !important; }

.pb-xs {
  padding-bottom: 5px !important; }

.py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs {
  padding-left: 5px !important; }

.pr-xs {
  padding-right: 5px !important; }

.px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm {
  margin: 15px !important; }

.mt-sm {
  margin-top: 15px !important; }

.mb-sm {
  margin-bottom: 15px !important; }

.my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm {
  margin-left: 15px !important; }

.mr-sm {
  margin-right: 15px !important; }

.mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm {
  padding: 15px !important; }

.pt-sm {
  padding-top: 15px !important; }

.pb-sm {
  padding-bottom: 15px !important; }

.py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm {
  padding-left: 15px !important; }

.pr-sm {
  padding-right: 15px !important; }

.px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md {
  margin: 30px !important; }

.mt-md {
  margin-top: 30px !important; }

.mb-md {
  margin-bottom: 30px !important; }

.my-md {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.ml-md {
  margin-left: 30px !important; }

.mr-md {
  margin-right: 30px !important; }

.mx-md {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-md {
  padding: 30px !important; }

.pt-md {
  padding-top: 30px !important; }

.pb-md {
  padding-bottom: 30px !important; }

.py-md {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pl-md {
  padding-left: 30px !important; }

.pr-md {
  padding-right: 30px !important; }

.px-md {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.m-lg {
  margin: 50px !important; }

.mt-lg {
  margin-top: 50px !important; }

.mb-lg {
  margin-bottom: 50px !important; }

.my-lg {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.ml-lg {
  margin-left: 50px !important; }

.mr-lg {
  margin-right: 50px !important; }

.mx-lg {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.p-lg {
  padding: 50px !important; }

.pt-lg {
  padding-top: 50px !important; }

.pb-lg {
  padding-bottom: 50px !important; }

.py-lg {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pl-lg {
  padding-left: 50px !important; }

.pr-lg {
  padding-right: 50px !important; }

.px-lg {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.m-xl {
  margin: 70px !important; }

.mt-xl {
  margin-top: 70px !important; }

.mb-xl {
  margin-bottom: 70px !important; }

.my-xl {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.ml-xl {
  margin-left: 70px !important; }

.mr-xl {
  margin-right: 70px !important; }

.mx-xl {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.p-xl {
  padding: 70px !important; }

.pt-xl {
  padding-top: 70px !important; }

.pb-xl {
  padding-bottom: 70px !important; }

.py-xl {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.pl-xl {
  padding-left: 70px !important; }

.pr-xl {
  padding-right: 70px !important; }

.px-xl {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.m-xxl {
  margin: 140px !important; }

.mt-xxl {
  margin-top: 140px !important; }

.mb-xxl {
  margin-bottom: 140px !important; }

.my-xxl {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.ml-xxl {
  margin-left: 140px !important; }

.mr-xxl {
  margin-right: 140px !important; }

.mx-xxl {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.p-xxl {
  padding: 140px !important; }

.pt-xxl {
  padding-top: 140px !important; }

.pb-xxl {
  padding-bottom: 140px !important; }

.py-xxl {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.pl-xxl {
  padding-left: 140px !important; }

.pr-xxl {
  padding-right: 140px !important; }

.px-xxl {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.m-0-child > * {
  margin: 0 !important; }

.m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.mt-0-child > * {
  margin-top: 0 !important; }

.mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.mb-0-child > * {
  margin-bottom: 0 !important; }

.mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0-child > * {
  margin-left: 0 !important; }

.ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.mr-0-child > * {
  margin-right: 0 !important; }

.mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0-child > * {
  padding: 0 !important; }

.p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.pt-0-child > * {
  padding-top: 0 !important; }

.pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.pb-0-child > * {
  padding-bottom: 0 !important; }

.pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0-child > * {
  padding-left: 0 !important; }

.pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.pr-0-child > * {
  padding-right: 0 !important; }

.pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs-child > * {
  margin: 5px !important; }

.m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.mt-xs-child > * {
  margin-top: 5px !important; }

.mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.mb-xs-child > * {
  margin-bottom: 5px !important; }

.mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs-child > * {
  margin-left: 5px !important; }

.ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.mr-xs-child > * {
  margin-right: 5px !important; }

.mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs-child > * {
  padding: 5px !important; }

.p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.pt-xs-child > * {
  padding-top: 5px !important; }

.pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.pb-xs-child > * {
  padding-bottom: 5px !important; }

.pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs-child > * {
  padding-left: 5px !important; }

.pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.pr-xs-child > * {
  padding-right: 5px !important; }

.pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm-child > * {
  margin: 15px !important; }

.m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.mt-sm-child > * {
  margin-top: 15px !important; }

.mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.mb-sm-child > * {
  margin-bottom: 15px !important; }

.mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm-child > * {
  margin-left: 15px !important; }

.ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.mr-sm-child > * {
  margin-right: 15px !important; }

.mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm-child > * {
  padding: 15px !important; }

.p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.pt-sm-child > * {
  padding-top: 15px !important; }

.pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.pb-sm-child > * {
  padding-bottom: 15px !important; }

.pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm-child > * {
  padding-left: 15px !important; }

.pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.pr-sm-child > * {
  padding-right: 15px !important; }

.pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md-child > * {
  margin: 30px !important; }

.m-md-child-not-last > *:not(:last-child) {
  margin: 30px !important; }

.mt-md-child > * {
  margin-top: 30px !important; }

.mt-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important; }

.mb-md-child > * {
  margin-bottom: 30px !important; }

.mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 30px !important; }

.my-md-child > * {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.my-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.ml-md-child > * {
  margin-left: 30px !important; }

.ml-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important; }

.mr-md-child > * {
  margin-right: 30px !important; }

.mr-md-child-not-last > *:not(:last-child) {
  margin-right: 30px !important; }

.mx-md-child > * {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.mx-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-md-child > * {
  padding: 30px !important; }

.p-md-child-not-last > *:not(:last-child) {
  padding: 30px !important; }

.pt-md-child > * {
  padding-top: 30px !important; }

.pt-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important; }

.pb-md-child > * {
  padding-bottom: 30px !important; }

.pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 30px !important; }

.py-md-child > * {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.py-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pl-md-child > * {
  padding-left: 30px !important; }

.pl-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important; }

.pr-md-child > * {
  padding-right: 30px !important; }

.pr-md-child-not-last > *:not(:last-child) {
  padding-right: 30px !important; }

.px-md-child > * {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.px-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.m-lg-child > * {
  margin: 50px !important; }

.m-lg-child-not-last > *:not(:last-child) {
  margin: 50px !important; }

.mt-lg-child > * {
  margin-top: 50px !important; }

.mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important; }

.mb-lg-child > * {
  margin-bottom: 50px !important; }

.mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 50px !important; }

.my-lg-child > * {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.my-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.ml-lg-child > * {
  margin-left: 50px !important; }

.ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important; }

.mr-lg-child > * {
  margin-right: 50px !important; }

.mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 50px !important; }

.mx-lg-child > * {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.p-lg-child > * {
  padding: 50px !important; }

.p-lg-child-not-last > *:not(:last-child) {
  padding: 50px !important; }

.pt-lg-child > * {
  padding-top: 50px !important; }

.pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important; }

.pb-lg-child > * {
  padding-bottom: 50px !important; }

.pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 50px !important; }

.py-lg-child > * {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.py-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pl-lg-child > * {
  padding-left: 50px !important; }

.pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important; }

.pr-lg-child > * {
  padding-right: 50px !important; }

.pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 50px !important; }

.px-lg-child > * {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.px-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.m-xl-child > * {
  margin: 70px !important; }

.m-xl-child-not-last > *:not(:last-child) {
  margin: 70px !important; }

.mt-xl-child > * {
  margin-top: 70px !important; }

.mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important; }

.mb-xl-child > * {
  margin-bottom: 70px !important; }

.mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 70px !important; }

.my-xl-child > * {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.my-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.ml-xl-child > * {
  margin-left: 70px !important; }

.ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important; }

.mr-xl-child > * {
  margin-right: 70px !important; }

.mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 70px !important; }

.mx-xl-child > * {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.p-xl-child > * {
  padding: 70px !important; }

.p-xl-child-not-last > *:not(:last-child) {
  padding: 70px !important; }

.pt-xl-child > * {
  padding-top: 70px !important; }

.pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important; }

.pb-xl-child > * {
  padding-bottom: 70px !important; }

.pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 70px !important; }

.py-xl-child > * {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.py-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.pl-xl-child > * {
  padding-left: 70px !important; }

.pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important; }

.pr-xl-child > * {
  padding-right: 70px !important; }

.pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 70px !important; }

.px-xl-child > * {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.px-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.m-xxl-child > * {
  margin: 140px !important; }

.m-xxl-child-not-last > *:not(:last-child) {
  margin: 140px !important; }

.mt-xxl-child > * {
  margin-top: 140px !important; }

.mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important; }

.mb-xxl-child > * {
  margin-bottom: 140px !important; }

.mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 140px !important; }

.my-xxl-child > * {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.ml-xxl-child > * {
  margin-left: 140px !important; }

.ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important; }

.mr-xxl-child > * {
  margin-right: 140px !important; }

.mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 140px !important; }

.mx-xxl-child > * {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.p-xxl-child > * {
  padding: 140px !important; }

.p-xxl-child-not-last > *:not(:last-child) {
  padding: 140px !important; }

.pt-xxl-child > * {
  padding-top: 140px !important; }

.pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important; }

.pb-xxl-child > * {
  padding-bottom: 140px !important; }

.pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 140px !important; }

.py-xxl-child > * {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.pl-xxl-child > * {
  padding-left: 140px !important; }

.pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important; }

.pr-xxl-child > * {
  padding-right: 140px !important; }

.pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 140px !important; }

.px-xxl-child > * {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important;
  padding-right: 140px !important; }

@media (max-width: 1199.98px) {
  .lg-down-m-0 {
    margin: 0 !important; }
  .lg-down-m-0-child > * {
    margin: 0 !important; }
  .lg-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-0 {
    margin-top: 0 !important; }
  .lg-down-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-0 {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-0 {
    margin-left: 0 !important; }
  .lg-down-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-0 {
    margin-right: 0 !important; }
  .lg-down-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-0 {
    padding: 0 !important; }
  .lg-down-p-0-child > * {
    padding: 0 !important; }
  .lg-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-0 {
    padding-top: 0 !important; }
  .lg-down-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-0 {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-0 {
    padding-left: 0 !important; }
  .lg-down-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-0 {
    padding-right: 0 !important; }
  .lg-down-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-xs {
    margin: 5px !important; }
  .lg-down-m-xs-child > * {
    margin: 5px !important; }
  .lg-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-xs {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-xs {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-xs {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-xs {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-xs {
    padding: 5px !important; }
  .lg-down-p-xs-child > * {
    padding: 5px !important; }
  .lg-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-xs {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-xs {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-xs {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-xs {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-sm {
    margin: 15px !important; }
  .lg-down-m-sm-child > * {
    margin: 15px !important; }
  .lg-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-sm {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-sm {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-sm {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-sm {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-sm {
    padding: 15px !important; }
  .lg-down-p-sm-child > * {
    padding: 15px !important; }
  .lg-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-sm {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-sm {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-sm {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-sm {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-md {
    margin: 30px !important; }
  .lg-down-m-md-child > * {
    margin: 30px !important; }
  .lg-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-md {
    margin-top: 30px !important; }
  .lg-down-mt-md-child > * {
    margin-top: 30px !important; }
  .lg-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-md {
    margin-bottom: 30px !important; }
  .lg-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .lg-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-md {
    margin-left: 30px !important; }
  .lg-down-ml-md-child > * {
    margin-left: 30px !important; }
  .lg-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-md {
    margin-right: 30px !important; }
  .lg-down-mr-md-child > * {
    margin-right: 30px !important; }
  .lg-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-md {
    padding: 30px !important; }
  .lg-down-p-md-child > * {
    padding: 30px !important; }
  .lg-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-md {
    padding-top: 30px !important; }
  .lg-down-pt-md-child > * {
    padding-top: 30px !important; }
  .lg-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-md {
    padding-bottom: 30px !important; }
  .lg-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .lg-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-md {
    padding-left: 30px !important; }
  .lg-down-pl-md-child > * {
    padding-left: 30px !important; }
  .lg-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-md {
    padding-right: 30px !important; }
  .lg-down-pr-md-child > * {
    padding-right: 30px !important; }
  .lg-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-lg {
    margin: 50px !important; }
  .lg-down-m-lg-child > * {
    margin: 50px !important; }
  .lg-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-lg {
    margin-top: 50px !important; }
  .lg-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .lg-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-lg {
    margin-bottom: 50px !important; }
  .lg-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .lg-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-lg {
    margin-left: 50px !important; }
  .lg-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .lg-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-lg {
    margin-right: 50px !important; }
  .lg-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .lg-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-lg {
    padding: 50px !important; }
  .lg-down-p-lg-child > * {
    padding: 50px !important; }
  .lg-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-lg {
    padding-top: 50px !important; }
  .lg-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .lg-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-lg {
    padding-bottom: 50px !important; }
  .lg-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .lg-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-lg {
    padding-left: 50px !important; }
  .lg-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .lg-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-lg {
    padding-right: 50px !important; }
  .lg-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .lg-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-xl {
    margin: 70px !important; }
  .lg-down-m-xl-child > * {
    margin: 70px !important; }
  .lg-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-xl {
    margin-top: 70px !important; }
  .lg-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .lg-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-xl {
    margin-bottom: 70px !important; }
  .lg-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .lg-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-xl {
    margin-left: 70px !important; }
  .lg-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .lg-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-xl {
    margin-right: 70px !important; }
  .lg-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .lg-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-xl {
    padding: 70px !important; }
  .lg-down-p-xl-child > * {
    padding: 70px !important; }
  .lg-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-xl {
    padding-top: 70px !important; }
  .lg-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .lg-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-xl {
    padding-bottom: 70px !important; }
  .lg-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .lg-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-xl {
    padding-left: 70px !important; }
  .lg-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .lg-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-xl {
    padding-right: 70px !important; }
  .lg-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .lg-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-xxl {
    margin: 140px !important; }
  .lg-down-m-xxl-child > * {
    margin: 140px !important; }
  .lg-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-xxl {
    margin-top: 140px !important; }
  .lg-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .lg-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-xxl {
    margin-bottom: 140px !important; }
  .lg-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .lg-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-xxl {
    margin-left: 140px !important; }
  .lg-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .lg-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-xxl {
    margin-right: 140px !important; }
  .lg-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .lg-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-xxl {
    padding: 140px !important; }
  .lg-down-p-xxl-child > * {
    padding: 140px !important; }
  .lg-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-xxl {
    padding-top: 140px !important; }
  .lg-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .lg-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-xxl {
    padding-bottom: 140px !important; }
  .lg-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .lg-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-xxl {
    padding-left: 140px !important; }
  .lg-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .lg-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-xxl {
    padding-right: 140px !important; }
  .lg-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .lg-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-0 {
    margin: 0 !important; }
  .md-down-m-0-child > * {
    margin: 0 !important; }
  .md-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-0 {
    margin-top: 0 !important; }
  .md-down-mt-0-child > * {
    margin-top: 0 !important; }
  .md-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-0 {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-0 {
    margin-left: 0 !important; }
  .md-down-ml-0-child > * {
    margin-left: 0 !important; }
  .md-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-0 {
    margin-right: 0 !important; }
  .md-down-mr-0-child > * {
    margin-right: 0 !important; }
  .md-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-p-0 {
    padding: 0 !important; }
  .md-down-p-0-child > * {
    padding: 0 !important; }
  .md-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-0 {
    padding-top: 0 !important; }
  .md-down-pt-0-child > * {
    padding-top: 0 !important; }
  .md-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-0 {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-0 {
    padding-left: 0 !important; }
  .md-down-pl-0-child > * {
    padding-left: 0 !important; }
  .md-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-0 {
    padding-right: 0 !important; }
  .md-down-pr-0-child > * {
    padding-right: 0 !important; }
  .md-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xs {
    margin: 5px !important; }
  .md-down-m-xs-child > * {
    margin: 5px !important; }
  .md-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xs {
    margin-top: 5px !important; }
  .md-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xs {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xs {
    margin-left: 5px !important; }
  .md-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xs {
    margin-right: 5px !important; }
  .md-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xs {
    padding: 5px !important; }
  .md-down-p-xs-child > * {
    padding: 5px !important; }
  .md-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xs {
    padding-top: 5px !important; }
  .md-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xs {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xs {
    padding-left: 5px !important; }
  .md-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xs {
    padding-right: 5px !important; }
  .md-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-sm {
    margin: 15px !important; }
  .md-down-m-sm-child > * {
    margin: 15px !important; }
  .md-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-sm {
    margin-top: 15px !important; }
  .md-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-sm {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-sm {
    margin-left: 15px !important; }
  .md-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-sm {
    margin-right: 15px !important; }
  .md-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-sm {
    padding: 15px !important; }
  .md-down-p-sm-child > * {
    padding: 15px !important; }
  .md-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-sm {
    padding-top: 15px !important; }
  .md-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-sm {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-sm {
    padding-left: 15px !important; }
  .md-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-sm {
    padding-right: 15px !important; }
  .md-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-md {
    margin: 30px !important; }
  .md-down-m-md-child > * {
    margin: 30px !important; }
  .md-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-md {
    margin-top: 30px !important; }
  .md-down-mt-md-child > * {
    margin-top: 30px !important; }
  .md-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-md {
    margin-bottom: 30px !important; }
  .md-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .md-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-md {
    margin-left: 30px !important; }
  .md-down-ml-md-child > * {
    margin-left: 30px !important; }
  .md-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-md {
    margin-right: 30px !important; }
  .md-down-mr-md-child > * {
    margin-right: 30px !important; }
  .md-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-md {
    padding: 30px !important; }
  .md-down-p-md-child > * {
    padding: 30px !important; }
  .md-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-md {
    padding-top: 30px !important; }
  .md-down-pt-md-child > * {
    padding-top: 30px !important; }
  .md-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-md {
    padding-bottom: 30px !important; }
  .md-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .md-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-md {
    padding-left: 30px !important; }
  .md-down-pl-md-child > * {
    padding-left: 30px !important; }
  .md-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-md {
    padding-right: 30px !important; }
  .md-down-pr-md-child > * {
    padding-right: 30px !important; }
  .md-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-lg {
    margin: 50px !important; }
  .md-down-m-lg-child > * {
    margin: 50px !important; }
  .md-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-lg {
    margin-top: 50px !important; }
  .md-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .md-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-lg {
    margin-bottom: 50px !important; }
  .md-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .md-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-lg {
    margin-left: 50px !important; }
  .md-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .md-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-lg {
    margin-right: 50px !important; }
  .md-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .md-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-lg {
    padding: 50px !important; }
  .md-down-p-lg-child > * {
    padding: 50px !important; }
  .md-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-lg {
    padding-top: 50px !important; }
  .md-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .md-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-lg {
    padding-bottom: 50px !important; }
  .md-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .md-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-lg {
    padding-left: 50px !important; }
  .md-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .md-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-lg {
    padding-right: 50px !important; }
  .md-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .md-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xl {
    margin: 70px !important; }
  .md-down-m-xl-child > * {
    margin: 70px !important; }
  .md-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xl {
    margin-top: 70px !important; }
  .md-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .md-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xl {
    margin-bottom: 70px !important; }
  .md-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .md-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xl {
    margin-left: 70px !important; }
  .md-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .md-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xl {
    margin-right: 70px !important; }
  .md-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .md-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xl {
    padding: 70px !important; }
  .md-down-p-xl-child > * {
    padding: 70px !important; }
  .md-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xl {
    padding-top: 70px !important; }
  .md-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .md-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xl {
    padding-bottom: 70px !important; }
  .md-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .md-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xl {
    padding-left: 70px !important; }
  .md-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .md-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xl {
    padding-right: 70px !important; }
  .md-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .md-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xxl {
    margin: 140px !important; }
  .md-down-m-xxl-child > * {
    margin: 140px !important; }
  .md-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xxl {
    margin-top: 140px !important; }
  .md-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .md-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xxl {
    margin-bottom: 140px !important; }
  .md-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .md-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xxl {
    margin-left: 140px !important; }
  .md-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .md-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xxl {
    margin-right: 140px !important; }
  .md-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .md-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xxl {
    padding: 140px !important; }
  .md-down-p-xxl-child > * {
    padding: 140px !important; }
  .md-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xxl {
    padding-top: 140px !important; }
  .md-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .md-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xxl {
    padding-bottom: 140px !important; }
  .md-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .md-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xxl {
    padding-left: 140px !important; }
  .md-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .md-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xxl {
    padding-right: 140px !important; }
  .md-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .md-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-0 {
    margin: 0 !important; }
  .sm-down-m-0-child > * {
    margin: 0 !important; }
  .sm-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-0 {
    margin-top: 0 !important; }
  .sm-down-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-0 {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-0 {
    margin-left: 0 !important; }
  .sm-down-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-0 {
    margin-right: 0 !important; }
  .sm-down-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-0 {
    padding: 0 !important; }
  .sm-down-p-0-child > * {
    padding: 0 !important; }
  .sm-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-0 {
    padding-top: 0 !important; }
  .sm-down-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-0 {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-0 {
    padding-left: 0 !important; }
  .sm-down-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-0 {
    padding-right: 0 !important; }
  .sm-down-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xs {
    margin: 5px !important; }
  .sm-down-m-xs-child > * {
    margin: 5px !important; }
  .sm-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xs {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xs {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xs {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xs {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xs {
    padding: 5px !important; }
  .sm-down-p-xs-child > * {
    padding: 5px !important; }
  .sm-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xs {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xs {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xs {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xs {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-sm {
    margin: 15px !important; }
  .sm-down-m-sm-child > * {
    margin: 15px !important; }
  .sm-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-sm {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-sm {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-sm {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-sm {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-sm {
    padding: 15px !important; }
  .sm-down-p-sm-child > * {
    padding: 15px !important; }
  .sm-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-sm {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-sm {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-sm {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-sm {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-md {
    margin: 30px !important; }
  .sm-down-m-md-child > * {
    margin: 30px !important; }
  .sm-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-md {
    margin-top: 30px !important; }
  .sm-down-mt-md-child > * {
    margin-top: 30px !important; }
  .sm-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-md {
    margin-bottom: 30px !important; }
  .sm-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .sm-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-md {
    margin-left: 30px !important; }
  .sm-down-ml-md-child > * {
    margin-left: 30px !important; }
  .sm-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-md {
    margin-right: 30px !important; }
  .sm-down-mr-md-child > * {
    margin-right: 30px !important; }
  .sm-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-md {
    padding: 30px !important; }
  .sm-down-p-md-child > * {
    padding: 30px !important; }
  .sm-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-md {
    padding-top: 30px !important; }
  .sm-down-pt-md-child > * {
    padding-top: 30px !important; }
  .sm-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-md {
    padding-bottom: 30px !important; }
  .sm-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .sm-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-md {
    padding-left: 30px !important; }
  .sm-down-pl-md-child > * {
    padding-left: 30px !important; }
  .sm-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-md {
    padding-right: 30px !important; }
  .sm-down-pr-md-child > * {
    padding-right: 30px !important; }
  .sm-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-lg {
    margin: 50px !important; }
  .sm-down-m-lg-child > * {
    margin: 50px !important; }
  .sm-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-lg {
    margin-top: 50px !important; }
  .sm-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .sm-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-lg {
    margin-bottom: 50px !important; }
  .sm-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .sm-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-lg {
    margin-left: 50px !important; }
  .sm-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .sm-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-lg {
    margin-right: 50px !important; }
  .sm-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .sm-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-lg {
    padding: 50px !important; }
  .sm-down-p-lg-child > * {
    padding: 50px !important; }
  .sm-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-lg {
    padding-top: 50px !important; }
  .sm-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .sm-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-lg {
    padding-bottom: 50px !important; }
  .sm-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .sm-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-lg {
    padding-left: 50px !important; }
  .sm-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .sm-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-lg {
    padding-right: 50px !important; }
  .sm-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .sm-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xl {
    margin: 70px !important; }
  .sm-down-m-xl-child > * {
    margin: 70px !important; }
  .sm-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xl {
    margin-top: 70px !important; }
  .sm-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .sm-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xl {
    margin-bottom: 70px !important; }
  .sm-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .sm-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xl {
    margin-left: 70px !important; }
  .sm-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .sm-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xl {
    margin-right: 70px !important; }
  .sm-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .sm-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xl {
    padding: 70px !important; }
  .sm-down-p-xl-child > * {
    padding: 70px !important; }
  .sm-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xl {
    padding-top: 70px !important; }
  .sm-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .sm-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xl {
    padding-bottom: 70px !important; }
  .sm-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .sm-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xl {
    padding-left: 70px !important; }
  .sm-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .sm-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xl {
    padding-right: 70px !important; }
  .sm-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .sm-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xxl {
    margin: 140px !important; }
  .sm-down-m-xxl-child > * {
    margin: 140px !important; }
  .sm-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xxl {
    margin-top: 140px !important; }
  .sm-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .sm-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xxl {
    margin-bottom: 140px !important; }
  .sm-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .sm-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xxl {
    margin-left: 140px !important; }
  .sm-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .sm-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xxl {
    margin-right: 140px !important; }
  .sm-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .sm-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xxl {
    padding: 140px !important; }
  .sm-down-p-xxl-child > * {
    padding: 140px !important; }
  .sm-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xxl {
    padding-top: 140px !important; }
  .sm-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .sm-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xxl {
    padding-bottom: 140px !important; }
  .sm-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .sm-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xxl {
    padding-left: 140px !important; }
  .sm-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .sm-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xxl {
    padding-right: 140px !important; }
  .sm-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .sm-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-0 {
    margin: 0 !important; }
  .xs-down-m-0-child > * {
    margin: 0 !important; }
  .xs-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-0 {
    margin-top: 0 !important; }
  .xs-down-mt-0-child > * {
    margin-top: 0 !important; }
  .xs-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-0 {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-0 {
    margin-left: 0 !important; }
  .xs-down-ml-0-child > * {
    margin-left: 0 !important; }
  .xs-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-0 {
    margin-right: 0 !important; }
  .xs-down-mr-0-child > * {
    margin-right: 0 !important; }
  .xs-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-0 {
    padding: 0 !important; }
  .xs-down-p-0-child > * {
    padding: 0 !important; }
  .xs-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-0 {
    padding-top: 0 !important; }
  .xs-down-pt-0-child > * {
    padding-top: 0 !important; }
  .xs-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-0 {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-0 {
    padding-left: 0 !important; }
  .xs-down-pl-0-child > * {
    padding-left: 0 !important; }
  .xs-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-0 {
    padding-right: 0 !important; }
  .xs-down-pr-0-child > * {
    padding-right: 0 !important; }
  .xs-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xs {
    margin: 5px !important; }
  .xs-down-m-xs-child > * {
    margin: 5px !important; }
  .xs-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xs {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xs {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xs {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xs {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xs {
    padding: 5px !important; }
  .xs-down-p-xs-child > * {
    padding: 5px !important; }
  .xs-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xs {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xs {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xs {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xs {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-sm {
    margin: 15px !important; }
  .xs-down-m-sm-child > * {
    margin: 15px !important; }
  .xs-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-sm {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-sm {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-sm {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-sm {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-sm {
    padding: 15px !important; }
  .xs-down-p-sm-child > * {
    padding: 15px !important; }
  .xs-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-sm {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-sm {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-sm {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-sm {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-md {
    margin: 30px !important; }
  .xs-down-m-md-child > * {
    margin: 30px !important; }
  .xs-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-md {
    margin-top: 30px !important; }
  .xs-down-mt-md-child > * {
    margin-top: 30px !important; }
  .xs-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-md {
    margin-bottom: 30px !important; }
  .xs-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .xs-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xs-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xs-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-md {
    margin-left: 30px !important; }
  .xs-down-ml-md-child > * {
    margin-left: 30px !important; }
  .xs-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-md {
    margin-right: 30px !important; }
  .xs-down-mr-md-child > * {
    margin-right: 30px !important; }
  .xs-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xs-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xs-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-md {
    padding: 30px !important; }
  .xs-down-p-md-child > * {
    padding: 30px !important; }
  .xs-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-md {
    padding-top: 30px !important; }
  .xs-down-pt-md-child > * {
    padding-top: 30px !important; }
  .xs-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-md {
    padding-bottom: 30px !important; }
  .xs-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .xs-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xs-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xs-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-md {
    padding-left: 30px !important; }
  .xs-down-pl-md-child > * {
    padding-left: 30px !important; }
  .xs-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-md {
    padding-right: 30px !important; }
  .xs-down-pr-md-child > * {
    padding-right: 30px !important; }
  .xs-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xs-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xs-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-lg {
    margin: 50px !important; }
  .xs-down-m-lg-child > * {
    margin: 50px !important; }
  .xs-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-lg {
    margin-top: 50px !important; }
  .xs-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .xs-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-lg {
    margin-bottom: 50px !important; }
  .xs-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .xs-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xs-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xs-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-lg {
    margin-left: 50px !important; }
  .xs-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .xs-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-lg {
    margin-right: 50px !important; }
  .xs-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .xs-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xs-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xs-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-lg {
    padding: 50px !important; }
  .xs-down-p-lg-child > * {
    padding: 50px !important; }
  .xs-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-lg {
    padding-top: 50px !important; }
  .xs-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .xs-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-lg {
    padding-bottom: 50px !important; }
  .xs-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .xs-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xs-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xs-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-lg {
    padding-left: 50px !important; }
  .xs-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .xs-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-lg {
    padding-right: 50px !important; }
  .xs-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .xs-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xs-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xs-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xl {
    margin: 70px !important; }
  .xs-down-m-xl-child > * {
    margin: 70px !important; }
  .xs-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xl {
    margin-top: 70px !important; }
  .xs-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .xs-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xl {
    margin-bottom: 70px !important; }
  .xs-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .xs-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xs-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xs-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xl {
    margin-left: 70px !important; }
  .xs-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .xs-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xl {
    margin-right: 70px !important; }
  .xs-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .xs-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xs-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xs-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xl {
    padding: 70px !important; }
  .xs-down-p-xl-child > * {
    padding: 70px !important; }
  .xs-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xl {
    padding-top: 70px !important; }
  .xs-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .xs-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xl {
    padding-bottom: 70px !important; }
  .xs-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .xs-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xs-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xs-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xl {
    padding-left: 70px !important; }
  .xs-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .xs-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xl {
    padding-right: 70px !important; }
  .xs-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .xs-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xs-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xs-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xxl {
    margin: 140px !important; }
  .xs-down-m-xxl-child > * {
    margin: 140px !important; }
  .xs-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xxl {
    margin-top: 140px !important; }
  .xs-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .xs-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xxl {
    margin-bottom: 140px !important; }
  .xs-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .xs-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xs-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xs-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xxl {
    margin-left: 140px !important; }
  .xs-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .xs-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xxl {
    margin-right: 140px !important; }
  .xs-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .xs-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xs-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xs-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xxl {
    padding: 140px !important; }
  .xs-down-p-xxl-child > * {
    padding: 140px !important; }
  .xs-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xxl {
    padding-top: 140px !important; }
  .xs-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .xs-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xxl {
    padding-bottom: 140px !important; }
  .xs-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .xs-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xs-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xs-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xxl {
    padding-left: 140px !important; }
  .xs-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .xs-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xxl {
    padding-right: 140px !important; }
  .xs-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .xs-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xs-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xs-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

.xs-up-m-0 {
  margin: 0 !important; }

.xs-up-m-0-child > * {
  margin: 0 !important; }

.xs-up-m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.xs-up-mt-0 {
  margin-top: 0 !important; }

.xs-up-mt-0-child > * {
  margin-top: 0 !important; }

.xs-up-mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.xs-up-mb-0 {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child > * {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.xs-up-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-ml-0 {
  margin-left: 0 !important; }

.xs-up-ml-0-child > * {
  margin-left: 0 !important; }

.xs-up-ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.xs-up-mr-0 {
  margin-right: 0 !important; }

.xs-up-mr-0-child > * {
  margin-right: 0 !important; }

.xs-up-mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.xs-up-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-p-0 {
  padding: 0 !important; }

.xs-up-p-0-child > * {
  padding: 0 !important; }

.xs-up-p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.xs-up-pt-0 {
  padding-top: 0 !important; }

.xs-up-pt-0-child > * {
  padding-top: 0 !important; }

.xs-up-pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.xs-up-pb-0 {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child > * {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.xs-up-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-pl-0 {
  padding-left: 0 !important; }

.xs-up-pl-0-child > * {
  padding-left: 0 !important; }

.xs-up-pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.xs-up-pr-0 {
  padding-right: 0 !important; }

.xs-up-pr-0-child > * {
  padding-right: 0 !important; }

.xs-up-pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.xs-up-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-m-xs {
  margin: 5px !important; }

.xs-up-m-xs-child > * {
  margin: 5px !important; }

.xs-up-m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.xs-up-mt-xs {
  margin-top: 5px !important; }

.xs-up-mt-xs-child > * {
  margin-top: 5px !important; }

.xs-up-mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.xs-up-mb-xs {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child > * {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.xs-up-my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-ml-xs {
  margin-left: 5px !important; }

.xs-up-ml-xs-child > * {
  margin-left: 5px !important; }

.xs-up-ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.xs-up-mr-xs {
  margin-right: 5px !important; }

.xs-up-mr-xs-child > * {
  margin-right: 5px !important; }

.xs-up-mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.xs-up-mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-p-xs {
  padding: 5px !important; }

.xs-up-p-xs-child > * {
  padding: 5px !important; }

.xs-up-p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.xs-up-pt-xs {
  padding-top: 5px !important; }

.xs-up-pt-xs-child > * {
  padding-top: 5px !important; }

.xs-up-pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.xs-up-pb-xs {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child > * {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.xs-up-py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-pl-xs {
  padding-left: 5px !important; }

.xs-up-pl-xs-child > * {
  padding-left: 5px !important; }

.xs-up-pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.xs-up-pr-xs {
  padding-right: 5px !important; }

.xs-up-pr-xs-child > * {
  padding-right: 5px !important; }

.xs-up-pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.xs-up-px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-m-sm {
  margin: 15px !important; }

.xs-up-m-sm-child > * {
  margin: 15px !important; }

.xs-up-m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.xs-up-mt-sm {
  margin-top: 15px !important; }

.xs-up-mt-sm-child > * {
  margin-top: 15px !important; }

.xs-up-mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.xs-up-mb-sm {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child > * {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.xs-up-my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-ml-sm {
  margin-left: 15px !important; }

.xs-up-ml-sm-child > * {
  margin-left: 15px !important; }

.xs-up-ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.xs-up-mr-sm {
  margin-right: 15px !important; }

.xs-up-mr-sm-child > * {
  margin-right: 15px !important; }

.xs-up-mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.xs-up-mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-p-sm {
  padding: 15px !important; }

.xs-up-p-sm-child > * {
  padding: 15px !important; }

.xs-up-p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.xs-up-pt-sm {
  padding-top: 15px !important; }

.xs-up-pt-sm-child > * {
  padding-top: 15px !important; }

.xs-up-pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.xs-up-pb-sm {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child > * {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.xs-up-py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-pl-sm {
  padding-left: 15px !important; }

.xs-up-pl-sm-child > * {
  padding-left: 15px !important; }

.xs-up-pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.xs-up-pr-sm {
  padding-right: 15px !important; }

.xs-up-pr-sm-child > * {
  padding-right: 15px !important; }

.xs-up-pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.xs-up-px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-m-md {
  margin: 30px !important; }

.xs-up-m-md-child > * {
  margin: 30px !important; }

.xs-up-m-md-child-not-last > *:not(:last-child) {
  margin: 30px !important; }

.xs-up-mt-md {
  margin-top: 30px !important; }

.xs-up-mt-md-child > * {
  margin-top: 30px !important; }

.xs-up-mt-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important; }

.xs-up-mb-md {
  margin-bottom: 30px !important; }

.xs-up-mb-md-child > * {
  margin-bottom: 30px !important; }

.xs-up-mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 30px !important; }

.xs-up-my-md {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-my-md-child > * {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-my-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-ml-md {
  margin-left: 30px !important; }

.xs-up-ml-md-child > * {
  margin-left: 30px !important; }

.xs-up-ml-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important; }

.xs-up-mr-md {
  margin-right: 30px !important; }

.xs-up-mr-md-child > * {
  margin-right: 30px !important; }

.xs-up-mr-md-child-not-last > *:not(:last-child) {
  margin-right: 30px !important; }

.xs-up-mx-md {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-mx-md-child > * {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-mx-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-p-md {
  padding: 30px !important; }

.xs-up-p-md-child > * {
  padding: 30px !important; }

.xs-up-p-md-child-not-last > *:not(:last-child) {
  padding: 30px !important; }

.xs-up-pt-md {
  padding-top: 30px !important; }

.xs-up-pt-md-child > * {
  padding-top: 30px !important; }

.xs-up-pt-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important; }

.xs-up-pb-md {
  padding-bottom: 30px !important; }

.xs-up-pb-md-child > * {
  padding-bottom: 30px !important; }

.xs-up-pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 30px !important; }

.xs-up-py-md {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-py-md-child > * {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-py-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-pl-md {
  padding-left: 30px !important; }

.xs-up-pl-md-child > * {
  padding-left: 30px !important; }

.xs-up-pl-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important; }

.xs-up-pr-md {
  padding-right: 30px !important; }

.xs-up-pr-md-child > * {
  padding-right: 30px !important; }

.xs-up-pr-md-child-not-last > *:not(:last-child) {
  padding-right: 30px !important; }

.xs-up-px-md {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-px-md-child > * {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-px-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-m-lg {
  margin: 50px !important; }

.xs-up-m-lg-child > * {
  margin: 50px !important; }

.xs-up-m-lg-child-not-last > *:not(:last-child) {
  margin: 50px !important; }

.xs-up-mt-lg {
  margin-top: 50px !important; }

.xs-up-mt-lg-child > * {
  margin-top: 50px !important; }

.xs-up-mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important; }

.xs-up-mb-lg {
  margin-bottom: 50px !important; }

.xs-up-mb-lg-child > * {
  margin-bottom: 50px !important; }

.xs-up-mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 50px !important; }

.xs-up-my-lg {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-my-lg-child > * {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-my-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-ml-lg {
  margin-left: 50px !important; }

.xs-up-ml-lg-child > * {
  margin-left: 50px !important; }

.xs-up-ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important; }

.xs-up-mr-lg {
  margin-right: 50px !important; }

.xs-up-mr-lg-child > * {
  margin-right: 50px !important; }

.xs-up-mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 50px !important; }

.xs-up-mx-lg {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-mx-lg-child > * {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-p-lg {
  padding: 50px !important; }

.xs-up-p-lg-child > * {
  padding: 50px !important; }

.xs-up-p-lg-child-not-last > *:not(:last-child) {
  padding: 50px !important; }

.xs-up-pt-lg {
  padding-top: 50px !important; }

.xs-up-pt-lg-child > * {
  padding-top: 50px !important; }

.xs-up-pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important; }

.xs-up-pb-lg {
  padding-bottom: 50px !important; }

.xs-up-pb-lg-child > * {
  padding-bottom: 50px !important; }

.xs-up-pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 50px !important; }

.xs-up-py-lg {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-py-lg-child > * {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-py-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-pl-lg {
  padding-left: 50px !important; }

.xs-up-pl-lg-child > * {
  padding-left: 50px !important; }

.xs-up-pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important; }

.xs-up-pr-lg {
  padding-right: 50px !important; }

.xs-up-pr-lg-child > * {
  padding-right: 50px !important; }

.xs-up-pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 50px !important; }

.xs-up-px-lg {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-px-lg-child > * {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-px-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-m-xl {
  margin: 70px !important; }

.xs-up-m-xl-child > * {
  margin: 70px !important; }

.xs-up-m-xl-child-not-last > *:not(:last-child) {
  margin: 70px !important; }

.xs-up-mt-xl {
  margin-top: 70px !important; }

.xs-up-mt-xl-child > * {
  margin-top: 70px !important; }

.xs-up-mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important; }

.xs-up-mb-xl {
  margin-bottom: 70px !important; }

.xs-up-mb-xl-child > * {
  margin-bottom: 70px !important; }

.xs-up-mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 70px !important; }

.xs-up-my-xl {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-my-xl-child > * {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-my-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-ml-xl {
  margin-left: 70px !important; }

.xs-up-ml-xl-child > * {
  margin-left: 70px !important; }

.xs-up-ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important; }

.xs-up-mr-xl {
  margin-right: 70px !important; }

.xs-up-mr-xl-child > * {
  margin-right: 70px !important; }

.xs-up-mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 70px !important; }

.xs-up-mx-xl {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-mx-xl-child > * {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-p-xl {
  padding: 70px !important; }

.xs-up-p-xl-child > * {
  padding: 70px !important; }

.xs-up-p-xl-child-not-last > *:not(:last-child) {
  padding: 70px !important; }

.xs-up-pt-xl {
  padding-top: 70px !important; }

.xs-up-pt-xl-child > * {
  padding-top: 70px !important; }

.xs-up-pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important; }

.xs-up-pb-xl {
  padding-bottom: 70px !important; }

.xs-up-pb-xl-child > * {
  padding-bottom: 70px !important; }

.xs-up-pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 70px !important; }

.xs-up-py-xl {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-py-xl-child > * {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-py-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-pl-xl {
  padding-left: 70px !important; }

.xs-up-pl-xl-child > * {
  padding-left: 70px !important; }

.xs-up-pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important; }

.xs-up-pr-xl {
  padding-right: 70px !important; }

.xs-up-pr-xl-child > * {
  padding-right: 70px !important; }

.xs-up-pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 70px !important; }

.xs-up-px-xl {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-px-xl-child > * {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-px-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-m-xxl {
  margin: 140px !important; }

.xs-up-m-xxl-child > * {
  margin: 140px !important; }

.xs-up-m-xxl-child-not-last > *:not(:last-child) {
  margin: 140px !important; }

.xs-up-mt-xxl {
  margin-top: 140px !important; }

.xs-up-mt-xxl-child > * {
  margin-top: 140px !important; }

.xs-up-mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important; }

.xs-up-mb-xxl {
  margin-bottom: 140px !important; }

.xs-up-mb-xxl-child > * {
  margin-bottom: 140px !important; }

.xs-up-mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 140px !important; }

.xs-up-my-xxl {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-my-xxl-child > * {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-ml-xxl {
  margin-left: 140px !important; }

.xs-up-ml-xxl-child > * {
  margin-left: 140px !important; }

.xs-up-ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important; }

.xs-up-mr-xxl {
  margin-right: 140px !important; }

.xs-up-mr-xxl-child > * {
  margin-right: 140px !important; }

.xs-up-mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 140px !important; }

.xs-up-mx-xxl {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-mx-xxl-child > * {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-p-xxl {
  padding: 140px !important; }

.xs-up-p-xxl-child > * {
  padding: 140px !important; }

.xs-up-p-xxl-child-not-last > *:not(:last-child) {
  padding: 140px !important; }

.xs-up-pt-xxl {
  padding-top: 140px !important; }

.xs-up-pt-xxl-child > * {
  padding-top: 140px !important; }

.xs-up-pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important; }

.xs-up-pb-xxl {
  padding-bottom: 140px !important; }

.xs-up-pb-xxl-child > * {
  padding-bottom: 140px !important; }

.xs-up-pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 140px !important; }

.xs-up-py-xxl {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-py-xxl-child > * {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-pl-xxl {
  padding-left: 140px !important; }

.xs-up-pl-xxl-child > * {
  padding-left: 140px !important; }

.xs-up-pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important; }

.xs-up-pr-xxl {
  padding-right: 140px !important; }

.xs-up-pr-xxl-child > * {
  padding-right: 140px !important; }

.xs-up-pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 140px !important; }

.xs-up-px-xxl {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.xs-up-px-xxl-child > * {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.xs-up-px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important;
  padding-right: 140px !important; }

@media (min-width: 576px) {
  .sm-up-m-0 {
    margin: 0 !important; }
  .sm-up-m-0-child > * {
    margin: 0 !important; }
  .sm-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mt-0 {
    margin-top: 0 !important; }
  .sm-up-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mb-0 {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-ml-0 {
    margin-left: 0 !important; }
  .sm-up-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mr-0 {
    margin-right: 0 !important; }
  .sm-up-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-p-0 {
    padding: 0 !important; }
  .sm-up-p-0-child > * {
    padding: 0 !important; }
  .sm-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pt-0 {
    padding-top: 0 !important; }
  .sm-up-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pb-0 {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pl-0 {
    padding-left: 0 !important; }
  .sm-up-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pr-0 {
    padding-right: 0 !important; }
  .sm-up-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-m-xs {
    margin: 5px !important; }
  .sm-up-m-xs-child > * {
    margin: 5px !important; }
  .sm-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xs {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xs {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xs {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xs {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xs {
    padding: 5px !important; }
  .sm-up-p-xs-child > * {
    padding: 5px !important; }
  .sm-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xs {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xs {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xs {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xs {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-m-sm {
    margin: 15px !important; }
  .sm-up-m-sm-child > * {
    margin: 15px !important; }
  .sm-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-sm {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-sm {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-sm {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-sm {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-p-sm {
    padding: 15px !important; }
  .sm-up-p-sm-child > * {
    padding: 15px !important; }
  .sm-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-sm {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-sm {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-sm {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-sm {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-m-md {
    margin: 30px !important; }
  .sm-up-m-md-child > * {
    margin: 30px !important; }
  .sm-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-md {
    margin-top: 30px !important; }
  .sm-up-mt-md-child > * {
    margin-top: 30px !important; }
  .sm-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-md {
    margin-bottom: 30px !important; }
  .sm-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .sm-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-md {
    margin-left: 30px !important; }
  .sm-up-ml-md-child > * {
    margin-left: 30px !important; }
  .sm-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-md {
    margin-right: 30px !important; }
  .sm-up-mr-md-child > * {
    margin-right: 30px !important; }
  .sm-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-p-md {
    padding: 30px !important; }
  .sm-up-p-md-child > * {
    padding: 30px !important; }
  .sm-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-md {
    padding-top: 30px !important; }
  .sm-up-pt-md-child > * {
    padding-top: 30px !important; }
  .sm-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-md {
    padding-bottom: 30px !important; }
  .sm-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .sm-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-md {
    padding-left: 30px !important; }
  .sm-up-pl-md-child > * {
    padding-left: 30px !important; }
  .sm-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-md {
    padding-right: 30px !important; }
  .sm-up-pr-md-child > * {
    padding-right: 30px !important; }
  .sm-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-m-lg {
    margin: 50px !important; }
  .sm-up-m-lg-child > * {
    margin: 50px !important; }
  .sm-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-lg {
    margin-top: 50px !important; }
  .sm-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .sm-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-lg {
    margin-bottom: 50px !important; }
  .sm-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .sm-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-lg {
    margin-left: 50px !important; }
  .sm-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .sm-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-lg {
    margin-right: 50px !important; }
  .sm-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .sm-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-p-lg {
    padding: 50px !important; }
  .sm-up-p-lg-child > * {
    padding: 50px !important; }
  .sm-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-lg {
    padding-top: 50px !important; }
  .sm-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .sm-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-lg {
    padding-bottom: 50px !important; }
  .sm-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .sm-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-lg {
    padding-left: 50px !important; }
  .sm-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .sm-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-lg {
    padding-right: 50px !important; }
  .sm-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .sm-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xl {
    margin: 70px !important; }
  .sm-up-m-xl-child > * {
    margin: 70px !important; }
  .sm-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xl {
    margin-top: 70px !important; }
  .sm-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .sm-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xl {
    margin-bottom: 70px !important; }
  .sm-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .sm-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xl {
    margin-left: 70px !important; }
  .sm-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .sm-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xl {
    margin-right: 70px !important; }
  .sm-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .sm-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xl {
    padding: 70px !important; }
  .sm-up-p-xl-child > * {
    padding: 70px !important; }
  .sm-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xl {
    padding-top: 70px !important; }
  .sm-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .sm-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xl {
    padding-bottom: 70px !important; }
  .sm-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .sm-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xl {
    padding-left: 70px !important; }
  .sm-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .sm-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xl {
    padding-right: 70px !important; }
  .sm-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .sm-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xxl {
    margin: 140px !important; }
  .sm-up-m-xxl-child > * {
    margin: 140px !important; }
  .sm-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xxl {
    margin-top: 140px !important; }
  .sm-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .sm-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xxl {
    margin-bottom: 140px !important; }
  .sm-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .sm-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xxl {
    margin-left: 140px !important; }
  .sm-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .sm-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xxl {
    margin-right: 140px !important; }
  .sm-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .sm-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xxl {
    padding: 140px !important; }
  .sm-up-p-xxl-child > * {
    padding: 140px !important; }
  .sm-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xxl {
    padding-top: 140px !important; }
  .sm-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .sm-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xxl {
    padding-bottom: 140px !important; }
  .sm-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .sm-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xxl {
    padding-left: 140px !important; }
  .sm-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .sm-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xxl {
    padding-right: 140px !important; }
  .sm-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .sm-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-m-0 {
    margin: 0 !important; }
  .md-up-m-0-child > * {
    margin: 0 !important; }
  .md-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mt-0 {
    margin-top: 0 !important; }
  .md-up-mt-0-child > * {
    margin-top: 0 !important; }
  .md-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mb-0 {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-ml-0 {
    margin-left: 0 !important; }
  .md-up-ml-0-child > * {
    margin-left: 0 !important; }
  .md-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mr-0 {
    margin-right: 0 !important; }
  .md-up-mr-0-child > * {
    margin-right: 0 !important; }
  .md-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-p-0 {
    padding: 0 !important; }
  .md-up-p-0-child > * {
    padding: 0 !important; }
  .md-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pt-0 {
    padding-top: 0 !important; }
  .md-up-pt-0-child > * {
    padding-top: 0 !important; }
  .md-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pb-0 {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pl-0 {
    padding-left: 0 !important; }
  .md-up-pl-0-child > * {
    padding-left: 0 !important; }
  .md-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pr-0 {
    padding-right: 0 !important; }
  .md-up-pr-0-child > * {
    padding-right: 0 !important; }
  .md-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-m-xs {
    margin: 5px !important; }
  .md-up-m-xs-child > * {
    margin: 5px !important; }
  .md-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xs {
    margin-top: 5px !important; }
  .md-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xs {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xs {
    margin-left: 5px !important; }
  .md-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xs {
    margin-right: 5px !important; }
  .md-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-p-xs {
    padding: 5px !important; }
  .md-up-p-xs-child > * {
    padding: 5px !important; }
  .md-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xs {
    padding-top: 5px !important; }
  .md-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xs {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xs {
    padding-left: 5px !important; }
  .md-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xs {
    padding-right: 5px !important; }
  .md-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-m-sm {
    margin: 15px !important; }
  .md-up-m-sm-child > * {
    margin: 15px !important; }
  .md-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mt-sm {
    margin-top: 15px !important; }
  .md-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mb-sm {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-ml-sm {
    margin-left: 15px !important; }
  .md-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mr-sm {
    margin-right: 15px !important; }
  .md-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-p-sm {
    padding: 15px !important; }
  .md-up-p-sm-child > * {
    padding: 15px !important; }
  .md-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pt-sm {
    padding-top: 15px !important; }
  .md-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pb-sm {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pl-sm {
    padding-left: 15px !important; }
  .md-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pr-sm {
    padding-right: 15px !important; }
  .md-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-m-md {
    margin: 30px !important; }
  .md-up-m-md-child > * {
    margin: 30px !important; }
  .md-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mt-md {
    margin-top: 30px !important; }
  .md-up-mt-md-child > * {
    margin-top: 30px !important; }
  .md-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mb-md {
    margin-bottom: 30px !important; }
  .md-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .md-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-ml-md {
    margin-left: 30px !important; }
  .md-up-ml-md-child > * {
    margin-left: 30px !important; }
  .md-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mr-md {
    margin-right: 30px !important; }
  .md-up-mr-md-child > * {
    margin-right: 30px !important; }
  .md-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-p-md {
    padding: 30px !important; }
  .md-up-p-md-child > * {
    padding: 30px !important; }
  .md-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pt-md {
    padding-top: 30px !important; }
  .md-up-pt-md-child > * {
    padding-top: 30px !important; }
  .md-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pb-md {
    padding-bottom: 30px !important; }
  .md-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .md-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pl-md {
    padding-left: 30px !important; }
  .md-up-pl-md-child > * {
    padding-left: 30px !important; }
  .md-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pr-md {
    padding-right: 30px !important; }
  .md-up-pr-md-child > * {
    padding-right: 30px !important; }
  .md-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-m-lg {
    margin: 50px !important; }
  .md-up-m-lg-child > * {
    margin: 50px !important; }
  .md-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mt-lg {
    margin-top: 50px !important; }
  .md-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .md-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mb-lg {
    margin-bottom: 50px !important; }
  .md-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .md-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-ml-lg {
    margin-left: 50px !important; }
  .md-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .md-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mr-lg {
    margin-right: 50px !important; }
  .md-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .md-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-p-lg {
    padding: 50px !important; }
  .md-up-p-lg-child > * {
    padding: 50px !important; }
  .md-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pt-lg {
    padding-top: 50px !important; }
  .md-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .md-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pb-lg {
    padding-bottom: 50px !important; }
  .md-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .md-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pl-lg {
    padding-left: 50px !important; }
  .md-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .md-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pr-lg {
    padding-right: 50px !important; }
  .md-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .md-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-m-xl {
    margin: 70px !important; }
  .md-up-m-xl-child > * {
    margin: 70px !important; }
  .md-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xl {
    margin-top: 70px !important; }
  .md-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .md-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xl {
    margin-bottom: 70px !important; }
  .md-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .md-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xl {
    margin-left: 70px !important; }
  .md-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .md-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xl {
    margin-right: 70px !important; }
  .md-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .md-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-p-xl {
    padding: 70px !important; }
  .md-up-p-xl-child > * {
    padding: 70px !important; }
  .md-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xl {
    padding-top: 70px !important; }
  .md-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .md-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xl {
    padding-bottom: 70px !important; }
  .md-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .md-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xl {
    padding-left: 70px !important; }
  .md-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .md-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xl {
    padding-right: 70px !important; }
  .md-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .md-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-m-xxl {
    margin: 140px !important; }
  .md-up-m-xxl-child > * {
    margin: 140px !important; }
  .md-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xxl {
    margin-top: 140px !important; }
  .md-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .md-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xxl {
    margin-bottom: 140px !important; }
  .md-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .md-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xxl {
    margin-left: 140px !important; }
  .md-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .md-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xxl {
    margin-right: 140px !important; }
  .md-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .md-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-p-xxl {
    padding: 140px !important; }
  .md-up-p-xxl-child > * {
    padding: 140px !important; }
  .md-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xxl {
    padding-top: 140px !important; }
  .md-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .md-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xxl {
    padding-bottom: 140px !important; }
  .md-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .md-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xxl {
    padding-left: 140px !important; }
  .md-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .md-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xxl {
    padding-right: 140px !important; }
  .md-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .md-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-m-0 {
    margin: 0 !important; }
  .lg-up-m-0-child > * {
    margin: 0 !important; }
  .lg-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mt-0 {
    margin-top: 0 !important; }
  .lg-up-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mb-0 {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-ml-0 {
    margin-left: 0 !important; }
  .lg-up-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mr-0 {
    margin-right: 0 !important; }
  .lg-up-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-p-0 {
    padding: 0 !important; }
  .lg-up-p-0-child > * {
    padding: 0 !important; }
  .lg-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pt-0 {
    padding-top: 0 !important; }
  .lg-up-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pb-0 {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pl-0 {
    padding-left: 0 !important; }
  .lg-up-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pr-0 {
    padding-right: 0 !important; }
  .lg-up-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-m-xs {
    margin: 5px !important; }
  .lg-up-m-xs-child > * {
    margin: 5px !important; }
  .lg-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xs {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xs {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xs {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xs {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xs {
    padding: 5px !important; }
  .lg-up-p-xs-child > * {
    padding: 5px !important; }
  .lg-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xs {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xs {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xs {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xs {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-m-sm {
    margin: 15px !important; }
  .lg-up-m-sm-child > * {
    margin: 15px !important; }
  .lg-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-sm {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-sm {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-sm {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-sm {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-p-sm {
    padding: 15px !important; }
  .lg-up-p-sm-child > * {
    padding: 15px !important; }
  .lg-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-sm {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-sm {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-sm {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-sm {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-m-md {
    margin: 30px !important; }
  .lg-up-m-md-child > * {
    margin: 30px !important; }
  .lg-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-md {
    margin-top: 30px !important; }
  .lg-up-mt-md-child > * {
    margin-top: 30px !important; }
  .lg-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-md {
    margin-bottom: 30px !important; }
  .lg-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .lg-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-md {
    margin-left: 30px !important; }
  .lg-up-ml-md-child > * {
    margin-left: 30px !important; }
  .lg-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-md {
    margin-right: 30px !important; }
  .lg-up-mr-md-child > * {
    margin-right: 30px !important; }
  .lg-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-p-md {
    padding: 30px !important; }
  .lg-up-p-md-child > * {
    padding: 30px !important; }
  .lg-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-md {
    padding-top: 30px !important; }
  .lg-up-pt-md-child > * {
    padding-top: 30px !important; }
  .lg-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-md {
    padding-bottom: 30px !important; }
  .lg-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .lg-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-md {
    padding-left: 30px !important; }
  .lg-up-pl-md-child > * {
    padding-left: 30px !important; }
  .lg-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-md {
    padding-right: 30px !important; }
  .lg-up-pr-md-child > * {
    padding-right: 30px !important; }
  .lg-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-m-lg {
    margin: 50px !important; }
  .lg-up-m-lg-child > * {
    margin: 50px !important; }
  .lg-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-lg {
    margin-top: 50px !important; }
  .lg-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .lg-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-lg {
    margin-bottom: 50px !important; }
  .lg-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .lg-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-lg {
    margin-left: 50px !important; }
  .lg-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .lg-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-lg {
    margin-right: 50px !important; }
  .lg-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .lg-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-p-lg {
    padding: 50px !important; }
  .lg-up-p-lg-child > * {
    padding: 50px !important; }
  .lg-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-lg {
    padding-top: 50px !important; }
  .lg-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .lg-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-lg {
    padding-bottom: 50px !important; }
  .lg-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .lg-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-lg {
    padding-left: 50px !important; }
  .lg-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .lg-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-lg {
    padding-right: 50px !important; }
  .lg-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .lg-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-m-xl {
    margin: 70px !important; }
  .lg-up-m-xl-child > * {
    margin: 70px !important; }
  .lg-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xl {
    margin-top: 70px !important; }
  .lg-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .lg-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xl {
    margin-bottom: 70px !important; }
  .lg-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .lg-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xl {
    margin-left: 70px !important; }
  .lg-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .lg-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xl {
    margin-right: 70px !important; }
  .lg-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .lg-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xl {
    padding: 70px !important; }
  .lg-up-p-xl-child > * {
    padding: 70px !important; }
  .lg-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xl {
    padding-top: 70px !important; }
  .lg-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .lg-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xl {
    padding-bottom: 70px !important; }
  .lg-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .lg-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xl {
    padding-left: 70px !important; }
  .lg-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .lg-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xl {
    padding-right: 70px !important; }
  .lg-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .lg-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-m-xxl {
    margin: 140px !important; }
  .lg-up-m-xxl-child > * {
    margin: 140px !important; }
  .lg-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xxl {
    margin-top: 140px !important; }
  .lg-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .lg-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xxl {
    margin-bottom: 140px !important; }
  .lg-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .lg-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xxl {
    margin-left: 140px !important; }
  .lg-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .lg-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xxl {
    margin-right: 140px !important; }
  .lg-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .lg-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xxl {
    padding: 140px !important; }
  .lg-up-p-xxl-child > * {
    padding: 140px !important; }
  .lg-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xxl {
    padding-top: 140px !important; }
  .lg-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .lg-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xxl {
    padding-bottom: 140px !important; }
  .lg-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .lg-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xxl {
    padding-left: 140px !important; }
  .lg-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .lg-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xxl {
    padding-right: 140px !important; }
  .lg-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .lg-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-0 {
    margin: 0 !important; }
  .xl-up-m-0-child > * {
    margin: 0 !important; }
  .xl-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-0 {
    margin-top: 0 !important; }
  .xl-up-mt-0-child > * {
    margin-top: 0 !important; }
  .xl-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-0 {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-0 {
    margin-left: 0 !important; }
  .xl-up-ml-0-child > * {
    margin-left: 0 !important; }
  .xl-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-0 {
    margin-right: 0 !important; }
  .xl-up-mr-0-child > * {
    margin-right: 0 !important; }
  .xl-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-p-0 {
    padding: 0 !important; }
  .xl-up-p-0-child > * {
    padding: 0 !important; }
  .xl-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-0 {
    padding-top: 0 !important; }
  .xl-up-pt-0-child > * {
    padding-top: 0 !important; }
  .xl-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-0 {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-0 {
    padding-left: 0 !important; }
  .xl-up-pl-0-child > * {
    padding-left: 0 !important; }
  .xl-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-0 {
    padding-right: 0 !important; }
  .xl-up-pr-0-child > * {
    padding-right: 0 !important; }
  .xl-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-m-xs {
    margin: 5px !important; }
  .xl-up-m-xs-child > * {
    margin: 5px !important; }
  .xl-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-xs {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-xs {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-xs {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-xs {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-xs {
    padding: 5px !important; }
  .xl-up-p-xs-child > * {
    padding: 5px !important; }
  .xl-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-xs {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-xs {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-xs {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-xs {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-sm {
    margin: 15px !important; }
  .xl-up-m-sm-child > * {
    margin: 15px !important; }
  .xl-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-sm {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-sm {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-sm {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-sm {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-sm {
    padding: 15px !important; }
  .xl-up-p-sm-child > * {
    padding: 15px !important; }
  .xl-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-sm {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-sm {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-sm {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-sm {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-md {
    margin: 30px !important; }
  .xl-up-m-md-child > * {
    margin: 30px !important; }
  .xl-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-md {
    margin-top: 30px !important; }
  .xl-up-mt-md-child > * {
    margin-top: 30px !important; }
  .xl-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-md {
    margin-bottom: 30px !important; }
  .xl-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .xl-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xl-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xl-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-md {
    margin-left: 30px !important; }
  .xl-up-ml-md-child > * {
    margin-left: 30px !important; }
  .xl-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-md {
    margin-right: 30px !important; }
  .xl-up-mr-md-child > * {
    margin-right: 30px !important; }
  .xl-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xl-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xl-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-md {
    padding: 30px !important; }
  .xl-up-p-md-child > * {
    padding: 30px !important; }
  .xl-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-md {
    padding-top: 30px !important; }
  .xl-up-pt-md-child > * {
    padding-top: 30px !important; }
  .xl-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-md {
    padding-bottom: 30px !important; }
  .xl-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .xl-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xl-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xl-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-md {
    padding-left: 30px !important; }
  .xl-up-pl-md-child > * {
    padding-left: 30px !important; }
  .xl-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-md {
    padding-right: 30px !important; }
  .xl-up-pr-md-child > * {
    padding-right: 30px !important; }
  .xl-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xl-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xl-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-lg {
    margin: 50px !important; }
  .xl-up-m-lg-child > * {
    margin: 50px !important; }
  .xl-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-lg {
    margin-top: 50px !important; }
  .xl-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .xl-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-lg {
    margin-bottom: 50px !important; }
  .xl-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .xl-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xl-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xl-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-lg {
    margin-left: 50px !important; }
  .xl-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .xl-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-lg {
    margin-right: 50px !important; }
  .xl-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .xl-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xl-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xl-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-lg {
    padding: 50px !important; }
  .xl-up-p-lg-child > * {
    padding: 50px !important; }
  .xl-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-lg {
    padding-top: 50px !important; }
  .xl-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .xl-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-lg {
    padding-bottom: 50px !important; }
  .xl-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .xl-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xl-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xl-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-lg {
    padding-left: 50px !important; }
  .xl-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .xl-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-lg {
    padding-right: 50px !important; }
  .xl-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .xl-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xl-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xl-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-xl {
    margin: 70px !important; }
  .xl-up-m-xl-child > * {
    margin: 70px !important; }
  .xl-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-xl {
    margin-top: 70px !important; }
  .xl-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .xl-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-xl {
    margin-bottom: 70px !important; }
  .xl-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .xl-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xl-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xl-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-xl {
    margin-left: 70px !important; }
  .xl-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .xl-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-xl {
    margin-right: 70px !important; }
  .xl-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .xl-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xl-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xl-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-xl {
    padding: 70px !important; }
  .xl-up-p-xl-child > * {
    padding: 70px !important; }
  .xl-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-xl {
    padding-top: 70px !important; }
  .xl-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .xl-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-xl {
    padding-bottom: 70px !important; }
  .xl-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .xl-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xl-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xl-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-xl {
    padding-left: 70px !important; }
  .xl-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .xl-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-xl {
    padding-right: 70px !important; }
  .xl-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .xl-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xl-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xl-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-xxl {
    margin: 140px !important; }
  .xl-up-m-xxl-child > * {
    margin: 140px !important; }
  .xl-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-xxl {
    margin-top: 140px !important; }
  .xl-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .xl-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-xxl {
    margin-bottom: 140px !important; }
  .xl-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .xl-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xl-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xl-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-xxl {
    margin-left: 140px !important; }
  .xl-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .xl-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-xxl {
    margin-right: 140px !important; }
  .xl-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .xl-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xl-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xl-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-xxl {
    padding: 140px !important; }
  .xl-up-p-xxl-child > * {
    padding: 140px !important; }
  .xl-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-xxl {
    padding-top: 140px !important; }
  .xl-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .xl-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-xxl {
    padding-bottom: 140px !important; }
  .xl-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .xl-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xl-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xl-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-xxl {
    padding-left: 140px !important; }
  .xl-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .xl-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-xxl {
    padding-right: 140px !important; }
  .xl-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .xl-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xl-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xl-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/
@font-face {
  font-family: 'TheSans';
  src: url("../fonts/thesans/TheSans-B3Light.woff2") format("woff2"), url("../fonts/thesans/TheSans-B3Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'TheSans';
  src: url("../fonts/thesans/TheSans-B5Plain.woff2") format("woff2"), url("../fonts/thesans/TheSans-B5Plain.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'TheSans';
  src: url("../fonts/thesans/TheSans-B6SemiBold.woff2") format("woff2"), url("../fonts/thesans/TheSans-B6SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'TheSans';
  src: url("../fonts/thesans/TheSans-B7Bold.woff2") format("woff2"), url("../fonts/thesans/TheSans-B7Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-family: "TheSans", sans-serif;
  color: #A5764E;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden; }
  body.overflowhidden {
    overflow: hidden; }

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  font-family: "TheSans", sans-serif;
  font-weight: 400;
  line-height: 1.2; }

button:focus,
input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important; }

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none; }

img {
  max-width: inherit; }

a,
img,
span,
button {
  display: inline-block; }

a {
  color: #A5764E;
  text-decoration: none; }
  a:hover {
    color: #A5764E; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style-type: none; }

.m-a {
  margin: auto; }

.d-b {
  display: block; }

/*
|
| Bg img default
|----------------------
|
*/
/*
|-------------------
| Bg img creation
|-------------------
| - Generated classes: .bg-img-{size}
|
*/
.bg-img, .bg-img-square, .bg-img-almost-square, .bg-img-landscape, .bg-img-landscape-sm, .bg-img-portrait, .bg-img-video, .bg-img-square-sm, .bg-img-landscape-xs {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .bg-img:after, .bg-img-square:after, .bg-img-almost-square:after, .bg-img-landscape:after, .bg-img-landscape-sm:after, .bg-img-portrait:after, .bg-img-video:after, .bg-img-square-sm:after, .bg-img-landscape-xs:after {
    content: '';
    display: block; }

.bg-img-video:after {
  padding-bottom: 56.25%; }

.bg-img-square:after {
  padding-bottom: 100%; }

.bg-img-square-sm:after {
  padding-bottom: 80%; }

.bg-img-landscape:after {
  padding-bottom: 70%; }

.bg-img-landscape-sm:after {
  padding-bottom: 56%; }

.bg-img-landscape-xs:after {
  padding-bottom: 30%; }

.bg-img-portrait:after {
  padding-bottom: 148%; }

.img-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
  margin: auto; }

.bg-container {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: hidden;
  z-index: 0; }
  .bg-container .img-hero {
    height: 100%;
    width: 100%; }
    .bg-container .img-hero img {
      height: 100%;
      object-fit: cover; }

.lazy-ctr {
  display: block;
  width: 100%;
  margin: 0px; }
  .lazy-ctr img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    outline: 1px solid transparent;
    display: block;
    top: 0;
    left: 0; }

.lazy-contain img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  outline: 1px solid transparent;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.img-landscape {
  padding-bottom: 56.25%; }

.img-portrait {
  padding-bottom: 120%; }

.img-full {
  max-width: 100%;
  width: auto; }

.video-container {
  padding-bottom: 56.25%;
  height: 0; }
  .video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/*
-------------------------------- 
LAZYLOAD COMPONENT
--------------------------------
*/
.lazy-ctr {
  position: relative;
  overflow: hidden; }
  .lazy-ctr .img-placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #F6F6F6;
    transition: opacity .5s ease;
    transition-delay: .3s;
    pointer-events: none;
    user-select: none;
    z-index: 2; }
  .lazy-ctr .img-lazy {
    z-index: initial; }
  .lazy-ctr.is-load .img-placeholder {
    opacity: 0; }

.lazy-ctr-cover {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.lazy-ctr-contain {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .lazy-ctr-contain img {
    object-fit: contain;
    width: 85%;
    height: 85%;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
  .lazy-ctr-contain.--full img {
    width: 95%;
    height: 95%; }

/*
-------------------------------- 
Overlay
--------------------------------
*/
.overlay:before {
  content: '';
  z-index: 1;
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 0.2s linear;
  background-color: #000000; }

@media (max-width: 767.98px) {
  .modal-video {
    width: 100% !important;
    max-width: 100% !important; } }

.modal-video.iziModal .iziModal-header-buttons {
  position: absolute;
  top: -30px;
  right: 0; }

.link-video {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100; }

.block-video {
  position: relative !important; }

.ytp-pause-overlay-controls-hidden .ytp-pause-overlay,
.ytp-pause-overlay {
  opacity: 0 !important; }

/*
|      Principal bottom
|--------------------
*/
#header {
  width: 100%;
  position: fixed;
  z-index: 500;
  /*
    |      Search box 
    |--------------------
    */
  /*
    |      MENU PRINCIPAL 
    |--------------------
    */
  /*
    |      Global
    |--------------------
    */ }
  @media (max-width: 1199.98px) {
    #header {
      position: fixed;
      top: 0; } }
  #header .item-link {
    color: #6E273D;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.2px;
    transition: 0.2s linear; }
    #header .item-link:hover {
      text-decoration: none;
      color: #949494; }
  #header .item-sub-link:not(:last-child) {
    margin-bottom: 10px; }
  #header #search-box {
    position: absolute;
    bottom: -1px;
    transform: translateY(100%);
    left: 0;
    right: 0;
    overflow: hidden;
    pointer-events: none; }
    #header #search-box .search-form {
      padding: 15px;
      display: flex;
      align-items: center;
      background-color: #C59217;
      transform: translateY(-100%);
      transition: 0.6s cubic-bezier(0.755, 0.05, 0.855, 0.06); }
      #header #search-box .search-form input[type="search"] {
        position: relative;
        border: 0px;
        padding: 15px;
        width: 100%; }
      #header #search-box .search-form .btn-block {
        width: 80px; }
        #header #search-box .search-form .btn-block .icon svg [fill^="#"] {
          fill: #C59217; }
        #header #search-box .search-form .btn-block .icon:after {
          display: none; }
        #header #search-box .search-form .btn-block:hover .icon svg [fill^="#"] {
          fill: #C59217; }
    #header #search-box.is-active {
      pointer-events: auto; }
      #header #search-box.is-active .search-form {
        transform: translateY(0%); }
  #header .header-surmenu {
    position: relative;
    z-index: 5;
    background-color: #FFFFFF; }
    #header .header-surmenu .submenu-line {
      position: absolute;
      left: 10px;
      bottom: 0;
      width: 98%;
      height: 1px;
      z-index: 5;
      background-color: #d9dffc; }
    #header .header-surmenu .item-surmenu-link {
      position: relative;
      color: #c59217; }
      #header .header-surmenu .item-surmenu-link:hover .icon-svg-gold {
        transform: translate3d(3px, -3px, 0px); }
      #header .header-surmenu .item-surmenu-link .title-link {
        margin-right: 30px; }
      #header .header-surmenu .item-surmenu-link .icon-svg-gold {
        position: absolute;
        display: flex;
        right: 2px;
        bottom: 5px;
        transition: transform 0.4s ease-in-out; }
        #header .header-surmenu .item-surmenu-link .icon-svg-gold svg {
          display: inline-block;
          width: 12px;
          height: 12px; }
  #header .header-surmenu,
  #header .header-principal {
    position: relative;
    z-index: 5;
    background-color: #FFFFFF;
    /*
        |      MTYPE DE SOUS menu
        |--------------------
        */ }
    #header .header-surmenu .header-container,
    #header .header-principal .header-container {
      border-bottom: 1px solid #FFFFFF;
      padding-top: 15px;
      padding-bottom: 15px;
      position: relative; }
      @media (max-width: 1199.98px) {
        #header .header-surmenu .header-container,
        #header .header-principal .header-container {
          max-width: 100%; } }
    #header .header-surmenu .menu-item,
    #header .header-principal .menu-item {
      margin-right: 32px;
      display: flex;
      align-items: center; }
      @media (max-width: 1399.98px) {
        #header .header-surmenu .menu-item,
        #header .header-principal .menu-item {
          margin-right: 30px; } }
      @media (max-width: 1199.98px) {
        #header .header-surmenu .menu-item,
        #header .header-principal .menu-item {
          margin-right: 15px; } }
      #header .header-surmenu .menu-item.menu-item-btn,
      #header .header-principal .menu-item.menu-item-btn {
        margin-left: 32px;
        margin-right: auto !important; }
      #header .header-surmenu .menu-item .btn-block .icon,
      #header .header-principal .menu-item .btn-block .icon {
        transition: 0.2s ease-In-Out;
        width: 40px;
        border-left: 0px;
        border-right: 0px;
        border-top: 0px;
        padding: 5px; }
        #header .header-surmenu .menu-item .btn-block .icon:after,
        #header .header-principal .menu-item .btn-block .icon:after {
          height: 2px;
          background-color: #FFFFFF; }
      #header .header-surmenu .menu-item .btn-block:hover .icon:after,
      #header .header-principal .menu-item .btn-block:hover .icon:after {
        background-color: #1A4194; }
      #header .header-surmenu .menu-item:last-child,
      #header .header-principal .menu-item:last-child {
        margin-right: 0px; }
      #header .header-surmenu .menu-item .item-link,
      #header .header-principal .menu-item .item-link {
        padding: 20px 15px 20px 15px;
        height: auto; }
        #header .header-surmenu .menu-item .item-link .text,
        #header .header-principal .menu-item .item-link .text {
          position: relative;
          z-index: 2; }
        #header .header-surmenu .menu-item .item-link:hover,
        #header .header-principal .menu-item .item-link:hover {
          color: #C59217; }
      #header .header-surmenu .menu-item .header-submenu,
      #header .header-principal .menu-item .header-submenu {
        transition: 0.4s ease-in-out;
        opacity: 0;
        visibility: hidden;
        transition: opacity 1.2s cubic-bezier(0.19, 1, 0.22, 1); }
      #header .header-surmenu .menu-item.current_page_item .item-link:before,
      #header .header-principal .menu-item.current_page_item .item-link:before {
        opacity: 1;
        transform: scaleY(1);
        transform-origin: 0 50%; }
      #header .header-surmenu .menu-item.menu-item-has-children .item-link,
      #header .header-principal .menu-item.menu-item-has-children .item-link {
        padding-right: 30px; }
      #header .header-surmenu .menu-item.menu-item-has-children:hover .header-submenu,
      #header .header-principal .menu-item.menu-item-has-children:hover .header-submenu {
        opacity: 1;
        visibility: visible; }
        #header .header-surmenu .menu-item.menu-item-has-children:hover .header-submenu .menu-panel,
        #header .header-principal .menu-item.menu-item-has-children:hover .header-submenu .menu-panel {
          transform: translateY(0%); }
  #header .header-submenu {
    z-index: 1;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0; }
  #header .header-container {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px; }
    @media (max-width: 1199.98px) {
      #header .header-container {
        padding-top: 15px;
        padding-bottom: 15px; } }
    #header .header-container .nav-ctr {
      flex: 1;
      display: flex; }
    #header .header-container .item-logo {
      width: 90px;
      height: auto;
      justify-content: center;
      align-items: center; }
      @media (max-width: 1199.98px) {
        #header .header-container .item-logo {
          padding-bottom: 0px;
          width: 80px;
          height: 70px; } }
      #header .header-container .item-logo svg {
        width: 100%;
        height: 100%; }
    #header .header-container .item-nav {
      display: flex;
      justify-content: flex-end;
      flex: 1; }
      #header .header-container .item-nav .item-ul {
        display: flex;
        width: 100%; }
        @media (max-width: 991.98px) {
          #header .header-container .item-nav .item-ul .menu-item {
            display: none;
            margin-right: 0px; } }
        @media (max-width: 991.98px) {
          #header .header-container .item-nav .item-ul .menu-item.menu-item-btn {
            display: block; } }
  #header li.menu-item-has-children span.ico-target {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    margin: auto;
    transition: 0.2s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg); }
    #header li.menu-item-has-children span.ico-target svg {
      width: 100%;
      height: 100%; }
      #header li.menu-item-has-children span.ico-target svg path {
        fill: #E42022; }
  #header li.menu-item-has-children:hover span.ico-target {
    transform: rotate(-90deg); }
  @media (max-width: 1399.98px) {
    #header .btn-default {
      padding: 15px 20px 15px 20px; } }
  #header .btn-menu {
    margin-top: 5px;
    margin-right: 50px; }
    @media (max-width: 1399.98px) {
      #header .btn-menu {
        margin-right: 20px; } }
    @media (max-width: 1199.98px) {
      #header .btn-menu {
        margin-right: 0px; } }
    @media (max-width: 991.98px) {
      #header .btn-menu {
        margin-top: 0px;
        margin-right: 0px;
        transform: scale(0.9); } }

.logo-menu-item img {
  width: 70px; }

/*
-------------------------------- 
Nav Fullscreen
--------------------------------
*/
#header-fullscreen {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: block;
  opacity: 0;
  pointer-events: none; }
  #header-fullscreen .menu-item {
    min-height: 84px;
    transition: 0.2s linear; }
    @media (max-width: 991.98px) {
      #header-fullscreen .menu-item {
        min-height: auto; } }
    #header-fullscreen .menu-item .item-link {
      font-weight: 500;
      font-size: 1.2em;
      padding: 25px 25px 25px 0px;
      position: relative;
      transition: 0.2s linear; }
      @media (max-width: 991.98px) {
        #header-fullscreen .menu-item .item-link {
          padding: 15px 25px 15px 0px; } }
      #header-fullscreen .menu-item .item-link .icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0px;
        height: max-content;
        margin: auto;
        transition: 0.2s linear; }
        @media (max-width: 991.98px) {
          #header-fullscreen .menu-item .item-link .icon {
            right: 25px; } }
    #header-fullscreen .menu-item:hover .item-link .icon .vertical {
      transform: scale(0); }
  @media (min-width: 992px) {
    #header-fullscreen .nav-firstlevel:hover > .menu-item {
      opacity: 0.3; } }
  #header-fullscreen .nav-firstlevel > .menu-item {
    transition: 0.2s linear; }
    #header-fullscreen .nav-firstlevel > .menu-item > .item-link {
      display: flex;
      color: #6E273D;
      width: 500px;
      max-width: 75%;
      margin: auto; }
      @media (max-width: 991.98px) {
        #header-fullscreen .nav-firstlevel > .menu-item > .item-link {
          width: 100%;
          max-width: 100%;
          padding-left: 15px;
          padding-right: 45px; } }
      #header-fullscreen .nav-firstlevel > .menu-item > .item-link .icon {
        color: #C59217; }
    @media (min-width: 768px) {
      #header-fullscreen .nav-firstlevel > .menu-item:hover {
        background-color: #6E273D;
        opacity: 1; }
        #header-fullscreen .nav-firstlevel > .menu-item:hover > .item-link {
          color: #FFFFFF; } }
    @media (max-width: 991.98px) {
      #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children.is-active {
        background-color: #6E273D; }
        #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children.is-active .item-link {
          color: #FFFFFF; }
        #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children.is-active .nav {
          position: relative;
          display: block;
          opacity: 1;
          pointer-events: auto; } }
    #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children .nav {
      position: absolute;
      left: 100%;
      top: 0;
      background-color: #6E273D;
      transition: 0.2s linear;
      pointer-events: none; }
      @media (max-width: 991.98px) {
        #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children .nav {
          position: relative;
          left: auto; } }
      #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children .nav .menu-item .item-link {
        font-size: 1.1em;
        font-weight: 400;
        padding: 27px 60px;
        width: 100%;
        color: #FFFFFF; }
        @media (max-width: 991.98px) {
          #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children .nav .menu-item .item-link {
            font-size: 1em;
            font-weight: 400;
            padding: 17px 36px;
            text-align: left; } }
        #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children .nav .menu-item .item-link .icon {
          right: 25px;
          transform: scale(0.6); }
      #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children .nav .menu-item:hover {
        background-color: #501d2d; }
        #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children .nav .menu-item:hover .item-link .icon .vertical {
          transform: scale(0); }
    #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children > .nav {
      width: 50%;
      opacity: 0; }
      @media (max-width: 991.98px) {
        #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children > .nav {
          width: 100%;
          display: none; } }
      #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children > .nav .menu-item {
        width: 100%;
        text-align: center; }
        #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children > .nav .menu-item:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
          background-color: #FFFFFF; }
        #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children > .nav .menu-item:last-child:after {
          display: none; }
        #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children > .nav .menu-item.menu-item-has-children .nav {
          position: relative;
          transform: none;
          left: 0;
          background-color: #501d2d;
          opacity: 1;
          height: max-content; }
          #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children > .nav .menu-item.menu-item-has-children .nav .menu-item:hover {
            background-color: #381520; }
          #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children > .nav .menu-item.menu-item-has-children .nav .item-link {
            font-size: 1em; }
    @media (min-width: 768px) {
      #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children:hover {
        background-color: #6E273D; }
        #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children:hover .nav {
          pointer-events: auto; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children:hover .nav.is-active {
        position: relative;
        display: block; } }
    @media (min-width: 768px) {
        #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children:hover > .item-link {
          color: #FFFFFF; }
          #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children:hover > .item-link .icon {
            color: #FFFFFF; }
        #header-fullscreen .nav-firstlevel > .menu-item.menu-item-has-children:hover > .nav {
          opacity: 1;
          visibility: visible; } }
  #header-fullscreen .bg-black {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: transparent; }
    #header-fullscreen .bg-black:hover {
      cursor: pointer; }
  #header-fullscreen .header-bg {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    height: 100vh;
    width: 50%;
    background-color: #FFFFFF;
    box-shadow: 6px 0px 20px 0px #0a030330; }
    @media (max-width: 991.98px) {
      #header-fullscreen .header-bg {
        width: 100%; } }
  #header-fullscreen .fullscreen-container {
    position: relative;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column; }
    #header-fullscreen .fullscreen-container .inner {
      position: relative;
      z-index: 10;
      padding-top: 15px;
      padding-bottom: 15px; }
      @media (max-width: 991.98px) {
        #header-fullscreen .fullscreen-container .inner {
          width: 100%; } }
    #header-fullscreen .fullscreen-container .inner-btn {
      padding: 18px 15px;
      text-align: center;
      width: 50%; }
      @media (max-width: 991.98px) {
        #header-fullscreen .fullscreen-container .inner-btn {
          width: 100%; } }
    #header-fullscreen .fullscreen-container .inner-nav {
      flex: 1;
      width: 100%;
      overflow-y: scroll;
      padding-top: 0px; }
      #header-fullscreen .fullscreen-container .inner-nav::-webkit-scrollbar {
        width: 1px; }
      #header-fullscreen .fullscreen-container .inner-nav::-webkit-scrollbar-track {
        background: #FFFFFF; }
      #header-fullscreen .fullscreen-container .inner-nav::-webkit-scrollbar-thumb {
        background: #FFFFFF; }
      #header-fullscreen .fullscreen-container .inner-nav::-webkit-scrollbar-thumb:hover {
        background: #FFFFFF; }
      #header-fullscreen .fullscreen-container .inner-nav .fullscreen-nav {
        width: calc(50% + 1px); }
        @media (max-width: 991.98px) {
          #header-fullscreen .fullscreen-container .inner-nav .fullscreen-nav {
            width: 100%; } }
  #header-fullscreen .fullscreen-nav {
    position: relative;
    z-index: 10;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%; }
    #header-fullscreen .fullscreen-nav .list-btn {
      text-align: center;
      display: flex;
      justify-content: center; }
  #header-fullscreen .nav {
    padding: 0px;
    counter-reset: section; }
    #header-fullscreen .nav .menu-item {
      position: relative;
      list-style: none;
      color: #FFFFFF; }
      #header-fullscreen .nav .menu-item .nav {
        padding: 0px; }
      #header-fullscreen .nav .menu-item:last-child {
        margin-bottom: 0px; }
  #header-fullscreen.is-menu {
    pointer-events: auto;
    opacity: 1; }

/*
|--------------------
|      FOOTER
|--------------------
*/
#ot-sdk-btn-floating {
  display: none !important; }

.item-nav-marque {
  margin-left: 0; }

.item-rs-marque {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
  margin-bottom: 10px; }
  .item-rs-marque .ul-rs {
    display: flex;
    align-items: center; }
    .item-rs-marque .ul-rs .item-rs {
      margin-right: 15px; }
      .item-rs-marque .ul-rs .item-rs:last-child {
        margin-right: 0px; }
  @media (max-width: 991.98px) {
    .item-rs-marque {
      flex-direction: column;
      justify-content: center; }
      .item-rs-marque > p {
        font-size: 14px !important;
        margin-bottom: 10px; } }

#footer {
  position: relative;
  padding: 50px 0px;
  overflow: hidden;
  background-color: #f9f4e7; }
  #footer .footer-top {
    position: relative; }
  #footer .footer-bottom {
    padding-top: 20px;
    position: relative; }
    #footer .footer-bottom .item-row {
      position: relative; }
    #footer .footer-bottom .cms-no-break p {
      color: #623C1B;
      font-size: 16px; }
    @media (max-width: 991.98px) {
      #footer .footer-bottom .cms-no-break {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
        font-size: 0.9em; } }
    #footer .footer-bottom .cms-no-break ul {
      padding: 0px;
      padding-top: 30px;
      display: flex;
      align-items: flex-end;
      list-style: none;
      margin: 0px; }
      @media (max-width: 991.98px) {
        #footer .footer-bottom .cms-no-break ul {
          flex-direction: column;
          align-items: center; } }
      #footer .footer-bottom .cms-no-break ul li {
        display: inline-block;
        position: relative;
        padding-left: 0;
        margin-left: 0;
        margin-right: 15px;
        font-size: 14px; }
        @media (max-width: 991.98px) {
          #footer .footer-bottom .cms-no-break ul li {
            font-size: 14px;
            margin-bottom: 10px; } }
        #footer .footer-bottom .cms-no-break ul li:last-child {
          margin-right: 0px; }
        #footer .footer-bottom .cms-no-break ul li #ot-sdk-btn {
          color: #623C1B;
          border: 0px;
          font-size: 14px;
          padding: 0; }
          #footer .footer-bottom .cms-no-break ul li #ot-sdk-btn:hover {
            background: none;
            color: #C59217;
            transition: 0.2s linear; }
        #footer .footer-bottom .cms-no-break ul li a {
          text-decoration: underline;
          transition: 0.2s linear;
          color: #623C1B; }
        #footer .footer-bottom .cms-no-break ul li:hover a {
          color: #C59217; }
  #footer .item-link {
    color: #623C1B;
    font-size: 16px;
    font-weight: 400;
    transition: 0.2s linear;
    margin-bottom: 15px; }
    #footer .item-link:hover {
      text-decoration: underline;
      color: #C59217; }
  #footer .col-logo {
    margin-bottom: 15px; }
    @media (max-width: 767.98px) {
      #footer .col-logo {
        text-align: center;
        margin-bottom: 35px; } }
    #footer .col-logo .item-logo {
      width: 90px;
      height: auto; }
  #footer .col-nav ul {
    display: flex;
    flex-wrap: wrap; }
    #footer .col-nav ul .menu-item {
      flex: 0 0 33%;
      max-width: 33%; }
      @media (max-width: 767.98px) {
        #footer .col-nav ul .menu-item {
          text-align: center; } }
  #footer .col-rs {
    padding-top: 60px;
    margin-bottom: 5px; }
    @media (max-width: 767.98px) {
      #footer .col-rs {
        text-align: center; } }
    #footer .col-rs .item-link {
      margin-bottom: 15px; }

/*
|--------------------
|      CONTENT
|--------------------
*/
/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
  /*
    * Card img
    */
  /*
    * Card content
    */
  /*
    * Hover state
    */ }
  .custom-card .custom-card-link {
    display: block; }
  .custom-card .card-img-container {
    position: relative;
    overflow: hidden; }
    .custom-card .card-img-container:after {
      content: "";
      display: block;
      padding-bottom: 100%; }
    .custom-card .card-img-container .card-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      will-change: transform; }
  .custom-card .card-content .date {
    color: rgba(58, 58, 58, 0.6); }
  .custom-card:hover .card-img-container .card-img {
    transform: scale(1.05); }

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0; }
  .cookie-banner .item-close {
    font-size: 24px;
    cursor: pointer; }
  @media (max-width: 767.98px) {
    .cookie-banner {
      padding: 20px 0;
      font-size: 12px; } }
  @media (max-width: 575.98px) {
    .cookie-banner {
      padding: 10px 0 20px 0; }
      .cookie-banner .item-close {
        font-size: 18px; } }

/*
|
| Pagination
|---------------
*/
.pagination-container li {
  display: inline-block; }
  .pagination-container li a {
    transition: all 0.3s ease-out;
    padding: 0 8px; }
    .pagination-container li a:hover {
      color: #333333; }
  .pagination-container li .current {
    padding: 0 8px;
    font-weight: 800;
    color: #333333; }

/*
|
| CMS
|------
*/
.cms ul {
  margin-bottom: 25px;
  margin-top: 0px; }
  .cms ul li {
    position: relative;
    padding-left: 24px;
    margin-bottom: 8px; }
    .cms ul li:before {
      content: "";
      transform: scale(0.7);
      position: absolute;
      display: inline-block;
      height: 8px;
      width: 8px;
      left: 1px;
      top: 9px;
      border-radius: 100%;
      background: #C59217; }

/*
|
| CMS
|------
*/
.cms span {
  display: inline; }

.cms h1,
.cms h2,
.cms h3,
.cms h4,
.cms h5,
.cms h6 {
  margin-bottom: 30px; }

.cms img {
  display: block;
  width: 100%;
  height: auto; }

.cms strong {
  font-weight: 800; }

.cms p {
  margin-bottom: 15px; }

.cms.cms-no-break p {
  margin-bottom: 0; }

.cms blockquote {
  position: relative;
  width: 80%;
  margin-right: 0;
  margin-left: auto;
  padding: 130px 0;
  font-size: 30px;
  line-height: 1.5em;
  font-weight: 300; }
  @media (max-width: 991.98px) {
    .cms blockquote {
      margin: auto; } }
  .cms blockquote:before {
    content: "";
    position: absolute;
    background-image: url("../img/icon/quote-left.svg");
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: translateX(-60px) translateY(-30px); }
  .cms blockquote:after {
    content: "";
    position: absolute;
    right: 0;
    background-image: url("../img/icon/quote-right.svg");
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: translateX(0) translateY(-50px); }
    @media (max-width: 991.98px) {
      .cms blockquote:after {
        transform: translateX(60px) translateY(-50px); } }

/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: 173px; }
  @media (max-width: 1199.98px) {
    .page-offset {
      padding-top: 100px; } }

.page-offset-2 {
  padding-top: 173px; }
  @media (max-width: 1199.98px) {
    .page-offset-2 {
      padding-top: 100px; } }

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block; }
  .custom-loader.cl-center {
    margin-left: auto;
    margin-right: auto; }
  .custom-loader svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: #000000; }
    .custom-loader svg circle {
      stroke: #000000; }
  .custom-loader.ajax-loader {
    display: none; }

/*
|
| Banner
|---------
*/
.banner {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%; }
  .banner .item-content {
    width: 100%; }
  .banner .lazy-ctr .img-placeholder {
    background: #6E273D; }

.banner--single {
  padding-top: 200px;
  padding-bottom: 20px; }

.banner--default {
  min-height: auto;
  align-items: flex-end;
  overflow: hidden; }
  .banner--default .item-content {
    position: relative;
    z-index: 5;
    padding: 120px  0; }
    .banner--default .item-content .item-text p {
      font-size: 17px !important; }
  .banner--default .banner-background {
    background-color: #C59217; }
  .banner--default.bannerSmall .item-content {
    padding: 100px  0; }
  .banner--default.banneHome {
    min-height: 60vh;
    align-items: center; }
    .banner--default.banneHome .item-content {
      padding: 100px  0; }
  .banner--default.bannerEvents .item-content {
    padding: 80px 0px; }
  .banner--default.bannerEvents .lazy-ctr .img-placeholder {
    background-color: #6E273D; }
  .banner--default .eventsCard-link.banner-date {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0px; }
    .banner--default .eventsCard-link.banner-date .eventsCard-date {
      padding-right: 45px;
      width: 120px;
      color: #FFFFFF; }
      .banner--default .eventsCard-link.banner-date .eventsCard-date.--au {
        padding: 0px;
        width: auto; }
        .banner--default .eventsCard-link.banner-date .eventsCard-date.--au:after {
          display: none; }
      .banner--default .eventsCard-link.banner-date .eventsCard-date.--start {
        padding-right: 25px; }
        .banner--default .eventsCard-link.banner-date .eventsCard-date.--start:after {
          display: none; }
      .banner--default .eventsCard-link.banner-date .eventsCard-date.--end {
        padding-left: 25px; }
    .banner--default .eventsCard-link.banner-date .eventsCard-lieu {
      padding-left: 50px;
      padding-right: 10px;
      width: max-content; }

.section-newsletter {
  position: relative;
  z-index: 10; }
  .section-newsletter .item-content {
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
    z-index: 10;
    background: #6E273D; }
    @media (max-width: 767.98px) {
      .section-newsletter .item-content {
        padding: 70px 0; } }

@media (max-width: 991.98px) {
  body.page-template-home .section.section-newsletter {
    padding-top: 0px !important;
    padding-bottom: 0px !important; } }

/*
*
*	ressourceCard
*---------------
*/
.hebdoCard-ctr {
  margin-bottom: 30px; }

.news-hebdo .hebdoCard-link {
  border-color: #C59217; }

.news-hebdo .hebdo-label {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 37px;
  height: 100%;
  background-color: #C59217;
  margin: auto; }
  .news-hebdo .hebdo-label:after {
    content: 'nouveau';
    text-transform: uppercase;
    color: #FFFFFF;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    transform: rotate(-90deg) translate(-50%, 100%);
    transform-origin: left bottom;
    height: 37px;
    font-size: 0.8em;
    line-height: 0.8em;
    letter-spacing: 2px;
    font-weight: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto; }

.hebdoCard-link {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0px;
  transition: 0.2s linear;
  background-color: #f9f4e7;
  border: 2px solid #f9f4e7; }
  .hebdoCard-link .hebdoCard-content {
    width: 100%;
    padding: 25px 30px 25px 60px;
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    color: #6E273D;
    border-bottom: 1px solid #C59217; }
    @media (max-width: 767.98px) {
      .hebdoCard-link .hebdoCard-content {
        flex-direction: column; } }
  .hebdoCard-link > div {
    width: 100%; }
  .hebdoCard-link .hebdoCard-text {
    width: 100%;
    padding: 30px 30px 20px 60px;
    line-height: 1.7em;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .hebdoCard-link .hebdoCard-date {
    padding-right: 20px;
    text-align: center;
    width: 80px;
    position: relative; }
    .hebdoCard-link .hebdoCard-date:after {
      content: '';
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 0;
      height: 45px;
      width: 4px;
      background-color: #BED600;
      transform: translateX(50%); }
    .hebdoCard-link .hebdoCard-date .day-date {
      font-size: 2em;
      line-height: 1em;
      font-weight: 600; }
    .hebdoCard-link .hebdoCard-date .month-date {
      font-size: 0.9em;
      font-weight: 400; }
    .hebdoCard-link .hebdoCard-date .year-date {
      font-weight: 200;
      font-size: 1.1em;
      line-height: 1.2em; }
  .hebdoCard-link .hebdoCard-title {
    padding: 0px 0px 0px 30px;
    flex: 1; }
  .hebdoCard-link .hebdoCard-lieu {
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: 10px;
    width: 120px; }
  .hebdoCard-link .hebdoCard-svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: max-content; }
  .hebdoCard-link:hover {
    text-decoration: none; }

.section-presentation {
  position: relative;
  min-height: 550px;
  display: flex;
  align-items: center; }
  .section-presentation .presentation-content {
    position: relative;
    z-index: 5; }
    @media (max-width: 991.98px) {
      .section-presentation .presentation-content img {
        max-width: 270px; } }
    .section-presentation .presentation-content .cms {
      padding-right: 50px; }
      @media (max-width: 991.98px) {
        .section-presentation .presentation-content .cms {
          padding-right: 0px; } }
  .section-presentation .presentation-img {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    flex: 0 0 52%;
    max-width: 52%;
    background-color: #FFFFFF; }
    @media (max-width: 991.98px) {
      .section-presentation .presentation-img {
        flex: 0 0 100%;
        max-width: 100%;
        height: 300px;
        bottom: auto; } }
  .section-presentation.section-presentation-left .presentation-img {
    right: auto;
    left: 0; }
  .section-presentation.section-presentation-left .presentation-content .row-item {
    flex-direction: row-reverse; }
  .section-presentation.section-presentation-right .presentation-content .row-item {
    flex-direction: row; }
  .section-presentation.section-presentation-right .presentation-img {
    right: 0;
    left: auto; }
  .section-presentation.presentation-home {
    min-height: 550px; }
    @media (max-width: 991.98px) {
      .section-presentation.presentation-home {
        min-height: auto;
        padding-top: 350px !important; } }
  @media (max-width: 991.98px) {
    .section-presentation.conseil-administration {
      min-height: auto;
      padding-top: 350px !important; } }
  .section-presentation.conseil-administration .img-container .lazy-ctr {
    background-color: #f3e5ca; }
  .section-presentation.presentation-cms {
    min-height: auto; }
  .section-presentation.presentation-single {
    min-height: auto; }
    .section-presentation.presentation-single .row-reverse {
      flex-direction: row-reverse; }
      .section-presentation.presentation-single .row-reverse .presentation-text {
        padding-right: 0px;
        padding-left: 80px; }
        @media (max-width: 991.98px) {
          .section-presentation.presentation-single .row-reverse .presentation-text {
            padding-left: 15px;
            padding-right: 15px; } }
    .section-presentation.presentation-single .presentation-text {
      padding-right: 80px;
      padding-left: 0px; }
      @media (max-width: 991.98px) {
        .section-presentation.presentation-single .presentation-text {
          padding-left: 15px;
          padding-right: 15px; } }
    .section-presentation.presentation-single .presentation-title {
      padding-right: 10%; }
      @media (max-width: 991.98px) {
        .section-presentation.presentation-single .presentation-title {
          padding-left: 15px;
          padding-right: 15px; } }
    .section-presentation.presentation-single .presentation-text-right {
      padding-left: 80px; }
      @media (max-width: 991.98px) {
        .section-presentation.presentation-single .presentation-text-right {
          padding-left: 15px; } }

.section-thrid-colonne .bg-img-portrait.bg-img-portrait-bottom .lazy-ctr-contain img {
  bottom: auto; }

.events-list-home-ctr {
  padding-left: 70px; }
  @media (max-width: 991.98px) {
    .events-list-home-ctr {
      padding-left: 15px; } }

.events-list-home {
  background-color: #f9f4e7;
  padding: 70px 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (max-width: 991.98px) {
    .events-list-home {
      padding: 70px 20px 30px 20px; } }
  .events-list-home .list-events .title {
    width: 100%; }
  .events-list-home .btn-list {
    flex: auto;
    display: flex;
    align-items: flex-end; }

.events-list-agenda {
  background-color: #f9f4e7;
  padding: 70px 50px 50px 50px; }
  @media (max-width: 991.98px) {
    .events-list-agenda {
      padding: 40px 15px 50px 15px; } }
  .events-list-agenda .eventsCard-ctr {
    margin-bottom: 20px; }

.list-filter-events {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px; }
  .list-filter-events .item-events {
    list-style: none; }
    @media (max-width: 991.98px) {
      .list-filter-events .item-events {
        flex: 0 0 50%;
        text-align: center; } }
    .list-filter-events .item-events .label-events {
      position: relative;
      border: 1px solid #FFFFFF;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-right: 20px;
      padding: 10px 40px;
      font-weight: 200;
      text-transform: uppercase;
      line-height: 1.1em; }
      @media (max-width: 1199.98px) {
        .list-filter-events .item-events .label-events {
          padding: 10px 30px; } }
      .list-filter-events .item-events .label-events span {
        position: relative;
        z-index: 1;
        color: #FFFFFF; }
      .list-filter-events .item-events .label-events.is-active {
        background-color: #FFFFFF; }
        .list-filter-events .item-events .label-events.is-active span {
          color: #C59217; }
    .list-filter-events .item-events:last-child label {
      margin-right: 0px; }

/*
*
*	ressourceCard
*---------------
*/
.eventsCard-ctr {
  padding-right: 0px; }
  @media (max-width: 767.98px) {
    .eventsCard-ctr {
      padding-left: 0px; } }

.eventsCard-link {
  width: 100%;
  display: flex;
  overflow: hidden;
  padding: 0px;
  position: relative;
  transition: 0.2s linear;
  border-bottom: 1px solid rgba(165, 118, 78, 0.2); }
  .eventsCard-link .eventsCard-content {
    width: 100%;
    padding: 15px 40px 15px 0;
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    color: #6E273D;
    box-sizing: border-box; }
  .eventsCard-link > div {
    width: 100%; }
  .eventsCard-link .eventsCard-date {
    padding-right: 15px;
    text-align: center;
    width: 80px;
    position: relative; }
    .eventsCard-link .eventsCard-date:after {
      content: '';
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 0;
      height: 50px;
      width: 4px;
      background-color: #BED600;
      transform: translateX(50%); }
    @media (max-width: 767.98px) {
      .eventsCard-link .eventsCard-date {
        flex: 0 0 33%;
        max-width: 33%; } }
    .eventsCard-link .eventsCard-date .day-date {
      font-size: 2em;
      line-height: 1em;
      font-weight: 600; }
    .eventsCard-link .eventsCard-date .month-date {
      font-size: 0.9em;
      font-weight: 400; }
    .eventsCard-link .eventsCard-date .year-date {
      font-weight: 200;
      font-size: 1.1em;
      line-height: 1.2em; }
  .eventsCard-link .eventsCard-title {
    padding: 0px 10px 0px 25px;
    flex: 1; }
    @media (max-width: 767.98px) {
      .eventsCard-link .eventsCard-title {
        flex: 0 0 66%;
        max-width: 66%; } }
  .eventsCard-link .eventsCard-lieu {
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: 10px;
    width: 120px; }
    @media (max-width: 767.98px) {
      .eventsCard-link .eventsCard-lieu {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 9px;
        padding-top: 5px; } }
  .eventsCard-link .eventsCard-svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: max-content; }
  .eventsCard-link:hover {
    text-decoration: none; }

@media (max-width: 767.98px) {
  .swiper-agenda-ctr {
    padding: 0px; } }

.swiper-team-ctr .non-swiper-slide,
.swiper-agenda-ctr .non-swiper-slide {
  display: none !important; }

.swiper-team-ctr .swiper-navigation,
.swiper-agenda-ctr .swiper-navigation {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .swiper-team-ctr .swiper-navigation .swiper-scrollbar,
  .swiper-agenda-ctr .swiper-navigation .swiper-scrollbar {
    height: 2px;
    width: 100%; }
    .swiper-team-ctr .swiper-navigation .swiper-scrollbar .swiper-scrollbar-drag,
    .swiper-agenda-ctr .swiper-navigation .swiper-scrollbar .swiper-scrollbar-drag {
      background-color: #C59217; }
  .swiper-team-ctr .swiper-navigation .swiper-button,
  .swiper-agenda-ctr .swiper-navigation .swiper-button {
    display: flex;
    align-items: center;
    padding-left: 2px; }
    .swiper-team-ctr .swiper-navigation .swiper-button .swiper-btn-prev svg,
    .swiper-agenda-ctr .swiper-navigation .swiper-button .swiper-btn-prev svg {
      transform: rotate3d(0, 1, 0, 180deg); }
    .swiper-team-ctr .swiper-navigation .swiper-button .swiper-btn-prev,
    .swiper-team-ctr .swiper-navigation .swiper-button .swiper-btn-next,
    .swiper-agenda-ctr .swiper-navigation .swiper-button .swiper-btn-prev,
    .swiper-agenda-ctr .swiper-navigation .swiper-button .swiper-btn-next {
      padding-left: 15px;
      position: relative;
      left: auto;
      top: auto;
      bottom: auto;
      right: auto;
      margin: 0px;
      transform: none; }
      .swiper-team-ctr .swiper-navigation .swiper-button .swiper-btn-prev.swiper-button-disabled,
      .swiper-team-ctr .swiper-navigation .swiper-button .swiper-btn-next.swiper-button-disabled,
      .swiper-agenda-ctr .swiper-navigation .swiper-button .swiper-btn-prev.swiper-button-disabled,
      .swiper-agenda-ctr .swiper-navigation .swiper-button .swiper-btn-next.swiper-button-disabled {
        opacity: 0.5; }
      .swiper-team-ctr .swiper-navigation .swiper-button .swiper-btn-prev:hover,
      .swiper-team-ctr .swiper-navigation .swiper-button .swiper-btn-next:hover,
      .swiper-agenda-ctr .swiper-navigation .swiper-button .swiper-btn-prev:hover,
      .swiper-agenda-ctr .swiper-navigation .swiper-button .swiper-btn-next:hover {
        cursor: pointer; }

.actu-list-home {
  padding-top: 70px; }

/*
*
*	ressourceCard
*---------------
*/
.actuCard-link {
  display: flex;
  height: 100%;
  overflow: hidden;
  padding: 0px;
  position: relative;
  transition: 0.2s linear; }
  .actuCard-link .actuCard-content {
    padding: 0 0 30px 0;
    position: relative;
    z-index: 10;
    display: flex; }
    @media (max-width: 767.98px) {
      .actuCard-link .actuCard-content {
        flex-direction: column; } }
  .actuCard-link > div {
    width: 100%; }
  .actuCard-link .actuCard-bg-ctr {
    width: calc(50% - 15px);
    margin-right: 30px;
    position: relative; }
    @media (max-width: 767.98px) {
      .actuCard-link .actuCard-bg-ctr {
        margin-right: 0px;
        width: 100%; } }
    .actuCard-link .actuCard-bg-ctr .img-cover {
      bottom: 30px; }
  .actuCard-link .actuCard-title {
    margin-bottom: 15px;
    padding-top: 5px;
    color: #6E273D; }
  .actuCard-link .actuCard-detail-ctr {
    padding-bottom: 15px;
    flex: 1;
    text-align: left; }
    @media (max-width: 767.98px) {
      .actuCard-link .actuCard-detail-ctr {
        padding-right: 0;
        padding-bottom: 10px; } }
  .actuCard-link .actuCard-detail {
    font-size: 0.9em;
    display: flex;
    align-items: center;
    color: #BED600; }
  .actuCard-link .actuCard-text {
    min-height: 50px;
    color: #A5764E; }
  .actuCard-link .btn-list {
    margin-top: 5px; }
  .actuCard-link .actuCard-bg {
    position: relative;
    overflow: hidden;
    background-color: #333333; }
    .actuCard-link .actuCard-bg .img-fluid {
      transform: scale(1.2);
      transition: 1s cubic-bezier(0.4, 0, 0.2, 1); }
  .actuCard-link:hover {
    text-decoration: none; }
    .actuCard-link:hover .actuCard-bg-ctr .img-fluid {
      transform: scale(1); }

.center-svg {
  display: flex;
  justify-content: center;
  align-items: center; }

.item-mission {
  position: relative;
  padding: 20px 60px 20px 0;
  display: flex;
  align-items: center; }
  .item-mission .icone-mission {
    width: 50px;
    margin-right: 15px;
    transition: 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .item-mission .icone-text {
    flex: 1;
    transition: 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .item-mission .icone-svg {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: max-content; }
  .item-mission:hover .icone-mission {
    transform: translateX(15px); }
  .item-mission:hover .icone-text {
    transform: translateX(20px);
    color: #C59217; }

.section-archive {
  position: relative;
  z-index: 10; }
  .section-archive .form-filter {
    transform: translateY(-50%);
    padding: 30px 60px;
    background-color: #C59217; }
    @media (max-width: 991.98px) {
      .section-archive .form-filter {
        transform: none; } }

.section-listing-docs .btn-container {
  position: relative; }
  .section-listing-docs .btn-container.loading::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
    background-image: url(../img/svg-loaders/puff-orange.svg);
    background-repeat: no-repeat;
    background-size: 2.5rem;
    background-position: center; }

.section-listing-docs .btn-load-more-doc {
  position: relative;
  pointer-events: auto;
  transition: 0.2s linear; }
  .section-listing-docs .btn-load-more-doc.d-none {
    pointer-events: none;
    opacity: 0.5; }
  .section-listing-docs .btn-load-more-doc.no-more {
    pointer-events: none;
    opacity: 0; }

.section-more-doc {
  position: relative;
  z-index: 10; }
  .section-more-doc .item-content {
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
    z-index: 10;
    background: #C59217; }
    @media (max-width: 767.98px) {
      .section-more-doc .item-content {
        padding: 70px 15px; } }

/*
*
*	ressourceCard
*---------------
*/
.teamCard-ctr {
  padding-top: 40px; }

.teamCard-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px;
  transition: 0.2s linear;
  padding: 30px 60px 50px 60px;
  position: relative;
  background-color: #FFFFFF;
  text-align: left; }
  .teamCard-content > div {
    width: 100%; }
  .teamCard-content .teamCard-bg-ctr {
    width: 100%;
    margin-right: 30px;
    position: relative; }
    @media (max-width: 767.98px) {
      .teamCard-content .teamCard-bg-ctr {
        margin-right: 0px;
        width: 100%; } }
  .teamCard-content .teamCard-detail {
    font-size: 0.7em;
    color: #FFFFFF;
    background-color: #C59217;
    text-transform: uppercase;
    position: absolute;
    padding: 8px 15px;
    letter-spacing: 1.5px;
    font-weight: 200;
    width: auto;
    top: 0px;
    left: 40px;
    right: 40px;
    transform: translateY(-50%);
    text-align: center; }
  .teamCard-content .teamCard-title {
    margin-bottom: 0;
    padding-top: 10px;
    color: #6E273D;
    line-height: 1.2em; }
  .teamCard-content .teamCard-text {
    color: #6E273D;
    line-height: 1.2em;
    margin-top: 5px;
    margin-bottom: 25px; }
  .teamCard-content .teamCard-bg {
    position: relative;
    overflow: hidden;
    background-color: #333333; }
    .teamCard-content .teamCard-bg .img-fluid {
      transform: scale(1.2);
      transition: 1s cubic-bezier(0.4, 0, 0.2, 1); }
  .teamCard-content:hover .teamCard-bg-ctr .img-fluid {
    transform: scale(1); }

.collapse-content .card-title {
  display: flex;
  align-items: center;
  padding: 30px 50px 30px 0px; }
  @media (max-width: 991.98px) {
    .collapse-content .card-title {
      padding: 10px 0px 10px 0px;
      align-items: left;
      margin-bottom: 10px;
      text-align: left; } }
  .collapse-content .card-title.collapsed .icon {
    transform: rotate(90deg); }
  .collapse-content .card-title .title {
    letter-spacing: 1.5px; }
  .collapse-content .card-title .icon {
    margin-left: 15px;
    transform: rotate(-90deg);
    transition: 0.4s linear; }

.section-thrid-colonne .item-row {
  position: relative;
  padding: 70px; }
  @media (max-width: 991.98px) {
    .section-thrid-colonne .item-row {
      padding: 30px 15px; } }

.section-thrid-colonne .item-col {
  padding-left: 60px;
  padding-right: 30px; }
  @media (max-width: 991.98px) {
    .section-thrid-colonne .item-col {
      padding-left: 15px;
      padding-right: 15px; } }

.section-thrid-colonne .presentation-img-ctr {
  padding: 0px;
  padding-top: 35px; }
  .section-thrid-colonne .presentation-img-ctr .presentation-img .lazy-ctr-contain img {
    width: 100%;
    height: 100%;
    object-position: top; }

@media (max-width: 991.98px) {
  .item-block-ctr {
    margin-bottom: 20px; } }

.item-block-ctr .item-block {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center; }
  @media (max-width: 991.98px) {
    .item-block-ctr .item-block {
      flex-direction: column; } }
  .item-block-ctr .item-block .bg-img-portrait {
    width: 220px; }
    @media (max-width: 991.98px) {
      .item-block-ctr .item-block .bg-img-portrait {
        height: 220px;
        width: 100%; } }
  .item-block-ctr .item-block .item-content {
    display: flex;
    flex-direction: column;
    padding: 50px 50px;
    flex: 1;
    justify-content: space-between; }
    @media (max-width: 991.98px) {
      .item-block-ctr .item-block .item-content {
        padding: 40px 20px; } }

.item-block-ctr.reverse {
  flex-direction: row-reverse; }

/*
|--------------------
|     PAGE HOME
|--------------------
*/
/*
|--------------------
|      NEWS
|--------------------
*/
.load-more-container {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .load-more-container .loader-container {
    display: none; }

/*
|--------------------
|      SINGLE
|--------------------
*/
.post-date {
  font-size: 12px;
  color: rgba(51, 51, 51, 0.6);
  text-transform: uppercase;
  font-weight: 400; }

.post-excerpt {
  font-size: 22px;
  color: rgba(58, 58, 58, 0.6);
  font-weight: 400; }

@media (min-width: 768px) {
  #page-news-single .presentation-image {
    width: 450px;
    height: 600px;
    margin: 0 auto; } }

@media (min-width: 768px) {
  #page-news-single .presentation-image .img-cover .wrapper-img {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center; } }

@media (min-width: 768px) {
  #page-news-single .presentation-image .img-cover .wrapper-img img {
    display: inline-block;
    position: relative;
    width: 450px;
    height: 600px;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto; } }

/*
|--------------------
|       Contact
|--------------------
*/
#page-contact {
  /*
    |
    | Section contact
    |------------------
    */ }
  #page-contact .section-map #map {
    position: relative;
    width: 100%;
    height: 50vh;
    background-color: #000000; }

/*
|--------------------
|       404
|--------------------
*/
#page-404 {
  /*
    |
    | Section contact
    |------------------
    */ }
  #page-404 .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #3A3A3A;
    color: #FFFFFF;
    text-align: center; }
    #page-404 .section-content .item-title {
      font-size: 8vw;
      font-weight: 300;
      line-height: 1; }

.section-min {
  min-height: 50vh; }

.section-two-colonne {
  position: relative;
  z-index: 10; }
  .section-two-colonne .item-content {
    padding: 40px 30px;
    position: relative;
    z-index: 10;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (max-width: 767.98px) {
      .section-two-colonne .item-content {
        padding: 70px 15px; } }

@media (min-width: 992px) {
  #page-about .swiper-team-ctr.slider-controls-off .swiper-navigation {
    display: none; } }

.docCard-ctr {
  margin-bottom: 30px; }
  @media (max-width: 991.98px) {
    .docCard-ctr {
      margin-bottom: 20px; } }

.docCard-content {
  display: flex;
  height: 100%;
  overflow: hidden;
  padding: 0px;
  position: relative;
  transition: 0.2s linear;
  padding: 40px;
  background-color: #f9f4e7;
  display: flex;
  align-items: center; }
  @media (max-width: 991.98px) {
    .docCard-content {
      padding: 30px 15px; } }
  .docCard-content .docCard-detail {
    flex: 1;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
  .docCard-content .docCard-link {
    display: flex;
    align-items: flex-end; }
  .docCard-content:hover {
    text-decoration: none; }

.blocCard-ctr {
  margin-bottom: 30px; }

.blocCard-content {
  height: 100%;
  overflow: hidden;
  padding: 0px;
  position: relative;
  transition: 0.2s linear;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .blocCard-content .blocCard-bg-ctr {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%; }
  .blocCard-content .blocCard-detail {
    position: relative;
    padding: 60px 40px;
    z-index: 20;
    flex: auto;
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .blocCard-content .blocCard-detail .blocCard-icon {
      text-align: center; }
      .blocCard-content .blocCard-detail .blocCard-icon img, .blocCard-content .blocCard-detail .blocCard-icon svg {
        width: 20px; }
  .blocCard-content:hover {
    text-decoration: none; }

.keyCard-ctr {
  margin-bottom: 30px; }

.keyCard-content {
  height: 100%;
  overflow: hidden;
  padding: 0px;
  position: relative;
  transition: 0.2s linear;
  background-color: #f9f4e7;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .keyCard-content .keyCard-bg-ctr {
    width: 100%; }
  .keyCard-content .keyCard-detail {
    flex: auto;
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 40px;
    justify-content: center; }
    .keyCard-content .keyCard-detail .keyCard-title {
      color: #6E273D; }
    .keyCard-content .keyCard-detail .keyCard-icon {
      text-align: center; }
      .keyCard-content .keyCard-detail .keyCard-icon img, .keyCard-content .keyCard-detail .keyCard-icon svg {
        width: 26px; }
  .keyCard-content:hover {
    text-decoration: none; }

.ficheCard-ctr {
  margin-bottom: 30px; }

.ficheCard-link {
  display: flex;
  height: 100%;
  overflow: hidden;
  padding: 0px;
  position: relative;
  transition: 0.2s linear;
  padding: 40px;
  background-color: #f9f4e7; }
  @media (max-width: 767.98px) {
    .ficheCard-link {
      padding: 30px; } }
  .ficheCard-link .ficheCard-content {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media (max-width: 767.98px) {
      .ficheCard-link .ficheCard-content {
        flex-direction: column; } }
  .ficheCard-link > div {
    width: 100%; }
  .ficheCard-link .ficheCard-title {
    margin-bottom: 15px;
    padding-top: 5px;
    color: #6E273D;
    min-height: 20px; }
  .ficheCard-link .ficheCard-detail {
    font-size: 0.9em;
    display: flex;
    align-items: center;
    color: #BED600; }
  .ficheCard-link .ficheCard-text {
    min-height: 50px;
    color: #A5764E; }
  .ficheCard-link .btn-list {
    margin-top: 5px; }
  .ficheCard-link:hover {
    text-decoration: none; }

/*
|--------------------
|      color
|--------------------
*/
.btn-default.btn-light-gold {
  background-color: #f9f4e7;
  border-color: #C59217;
  color: #C59217; }

.btn-default.btn-gold {
  background-color: #C59217;
  border-color: #C59217;
  color: #FFFFFF; }

.btn-download {
  text-transform: none !important; }
  .btn-download.tt-u {
    text-transform: uppercase !important; }

/*
|--------------------
|      BUTTONS
|--------------------
*/
.btn-target {
  display: inline-flex;
  align-items: center;
  padding: 2px 4px 2px 4px;
  box-shadow: none !important;
  font-size: 1em;
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 1px; }
  .btn-target .icon {
    margin-left: 15px;
    margin-right: 0px;
    transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .btn-target .text {
    position: relative;
    display: inline-block;
    overflow: hidden; }
    .btn-target .text span {
      transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      transform: translate3d(0, 0, 0); }
    .btn-target .text:before {
      transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      position: absolute;
      top: 100%;
      content: attr(data-hover);
      letter-spacing: 0.9px;
      font-weight: 700;
      transform: translate3d(0, 0, 0); }
  .btn-target.reverse {
    flex-direction: row-reverse; }
    .btn-target.reverse .icon {
      margin-left: 0px;
      margin-right: 15px; }
    .btn-target.reverse:hover .icon {
      transform: none; }
  .btn-target.btn-target-simple:hover .icon {
    transform: translatex(10px); }
  .btn-target.single-icon {
    padding: 0px; }
    .btn-target.single-icon .icon {
      margin: 0px; }
  .btn-target.btn-target-white {
    color: #FFFFFF; }
  .btn-target.btn-target-brown {
    color: #623C1B; }
  .btn-target.btn-target-burgundy {
    color: #6E273D; }
  .btn-target.btn-target-gold {
    color: #C59217; }
  .btn-target.btn-target-light-gold {
    color: #f9f4e7; }
  .btn-target:hover .text span {
    transform: translateY(-100%); }
  .btn-target:hover .text:before {
    transform: translateY(-100%); }

/*
|--------------------
|      BUTTONS
|--------------------
*/
.btn-menu {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  padding: 0;
  border: none;
  background-color: #6E273D;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 100%; }
  .btn-menu .text-burger {
    color: #FFFFFF;
    text-transform: uppercase;
    line-height: 1em;
    text-transform: uppercase;
    font-size: 0.8em;
    margin-top: 9px; }
  .btn-menu > .item-burger {
    display: block;
    width: 25px;
    margin-top: 10px; }
    .btn-menu > .item-burger > span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #FFFFFF;
      border-radius: 2px; }
      .btn-menu > .item-burger > span:nth-child(2) {
        margin: 6px 0;
        transform: scale(0.7); }
  .btn-menu.is-close > .item-burger {
    margin-top: 0px; }
    .btn-menu.is-close > .item-burger > span:nth-child(2) {
      opacity: 0; }
    .btn-menu.is-close > .item-burger > span:first-child {
      transform: translate3d(0, 9px, 0) rotate3d(0, 0, 1, -45deg); }
    .btn-menu.is-close > .item-burger > span:last-child {
      transform: translate3d(0, -7px, 0) rotate3d(0, 0, 1, 45deg); }

/*
|--------------------
|  Smooth Scrollbar
|--------------------
*/
/*
|
| Scroll area
|--------------
*/
.scroll-area .scrollbar-track.scrollbar-track-y {
  width: 4px !important; }
  .scroll-area .scrollbar-track.scrollbar-track-y .scrollbar-thumb {
    width: 100% !important;
    background: #000000 !important; }

/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/
.block-form .search-form {
  padding: 15px;
  display: flex;
  align-items: center;
  background-color: #C59217;
  transition: 0.6s cubic-bezier(0.755, 0.05, 0.855, 0.06); }
  .block-form .search-form input[type="search"] {
    position: relative;
    border: 0px;
    padding: 15px;
    width: 100%; }
  .block-form .search-form .btn-block {
    width: 80px; }
    .block-form .search-form .btn-block .icon svg [fill^="#"] {
      fill: #C59217; }
    .block-form .search-form .btn-block .icon:after {
      display: none; }
    .block-form .search-form .btn-block:hover .icon svg [fill^="#"] {
      fill: #C59217; }

.block-form .gform_validation_errors {
  margin-top: 5px;
  color: red;
  font-size: 14px;
  width: 100%;
  border: 1px solid;
  padding: 15px 20px;
  margin-bottom: 20px; }

.block-form .gform_body .gsection {
  margin-bottom: 20px;
  margin-top: 50px; }

.block-form .gform_body .gsection_title {
  font-size: 2.2em;
  font-weight: 500;
  color: #C59217;
  margin-bottom: 20px; }

.block-form .gform_body .hidden_label .gfield_label {
  display: none !important; }

.block-form .gform_body .ginput_container_fileupload .gform_fileupload_rules {
  display: none; }

.block-form .gform_body .gfield_label {
  text-transform: uppercase;
  position: absolute;
  font-size: 16px;
  top: 18px;
  left: 15px;
  margin-bottom: 0px;
  font-weight: 400;
  line-height: 1em;
  transition: 0.2s linear;
  color: #6E273D;
  letter-spacing: 0.4px;
  top: -6px;
  font-size: 0.8em; }
  .block-form .gform_body .gfield_label .gfield_required {
    color: red;
    padding-left: 2px; }
  .block-form .gform_body .gfield_label.active {
    top: -6px;
    font-size: 0.8em; }
  .block-form .gform_body .gfield_label.gfield_label_before_complex {
    position: relative;
    left: auto;
    top: auto;
    font-size: 1em;
    margin-bottom: 20px; }

.block-form .gform_body input:not([type="checkbox"]),
.block-form .gform_body textarea,
.block-form .gform_body select {
  background-color: transparent;
  -webkit-appearance: none;
  padding: 12px 20px 10px 0;
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 1px solid #C59217;
  font-size: 16px;
  font-weight: 400;
  color: #6E273D;
  transition: 0.2s linear; }
  .block-form .gform_body input:not([type="checkbox"]):focus,
  .block-form .gform_body textarea:focus,
  .block-form .gform_body select:focus {
    outline: none;
    border-color: #6E273D; }
  .block-form .gform_body input:not([type="checkbox"])::placeholder,
  .block-form .gform_body textarea::placeholder,
  .block-form .gform_body select::placeholder {
    font-weight: 400; }

.block-form .gform_body select {
  background-image: url("../img/icon/arrow-down-small.svg");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: calc(100% - 10px) center;
  padding-right: 50px; }

.block-form .gform_body .g-radio {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .block-form .gform_body .g-radio .gfield_label {
    position: relative;
    top: auto;
    left: auto;
    font-size: 1em;
    margin-right: 20px;
    color: #C59217;
    font-weight: 400;
    text-transform: none; }

.block-form .gform_body .gfield_radio li,
.block-form .gform_body .gfield_checkbox li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px; }
  .block-form .gform_body .gfield_radio li input,
  .block-form .gform_body .gfield_checkbox li input {
    width: auto;
    margin-right: 0px;
    margin-bottom: 2px;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../img/icon/Check.svg");
    border-bottom: 0px; }
    .block-form .gform_body .gfield_radio li input:checked,
    .block-form .gform_body .gfield_checkbox li input:checked {
      background-color: #C59217; }
  .block-form .gform_body .gfield_radio li label,
  .block-form .gform_body .gfield_checkbox li label {
    padding-left: 20px;
    cursor: pointer; }

.block-form .gform_body .gfield_radio {
  display: flex; }
  .block-form .gform_body .gfield_radio li {
    margin-bottom: 0px;
    margin-right: 40px; }
    .block-form .gform_body .gfield_radio li input {
      border-radius: 100%;
      background-image: url("../img/icon/Radio.svg");
      border-bottom: 0px; }
    .block-form .gform_body .gfield_radio li label {
      color: #6E273D;
      cursor: pointer; }

.block-form .gform_body .gfield_radio_checkbox .gfield_radio {
  flex-direction: column; }
  .block-form .gform_body .gfield_radio_checkbox .gfield_radio li {
    margin-bottom: 15px; }
    .block-form .gform_body .gfield_radio_checkbox .gfield_radio li label {
      padding-left: 20px;
      cursor: pointer;
      color: #C59217; }

.block-form .gform_body textarea {
  height: 200px; }

.block-form .gform_body .file-rgpd .gfield_label {
  display: none; }

.block-form .gform_body .file-rgpd .ginput_container {
  display: flex; }
  .block-form .gform_body .file-rgpd .ginput_container input {
    width: 20px;
    margin: 7px 20px 7px 0; }

.block-form .gform_body .file-rgpd a {
  color: #C59217;
  padding-left: 0px;
  text-decoration: underline; }
  .block-form .gform_body .file-rgpd a:hover {
    color: #623C1B; }

.block-form .gform_body .gform_fields input,
.block-form .gform_body .gform_fields textarea,
.block-form .gform_body .gform_fields select {
  width: 100%; }

.block-form .gform_body .gform_fields .gfield {
  margin-bottom: 30px; }

.block-form .gform_body .gform_body {
  margin-bottom: 20px; }

.block-form .gform_body .gform_confirmation_message {
  color: #3A3A3A; }

.block-form .gform_body .validation_message {
  margin-top: 5px;
  color: red;
  font-size: 14px; }

.block-form .gform_footer {
  text-align: left;
  padding-top: 0; }

.block-form.block-form-demande .gform_body .g-table {
  background-color: #f9f4e7; }
  .block-form.block-form-demande .gform_body .g-table .ginput_container_list {
    max-height: 70vh;
    overflow-y: scroll;
    padding-right: 10px; }
    .block-form.block-form-demande .gform_body .g-table .ginput_container_list::-webkit-scrollbar {
      width: 4px; }
    .block-form.block-form-demande .gform_body .g-table .ginput_container_list::-webkit-scrollbar-track {
      background: rgba(197, 146, 23, 0.2); }
    .block-form.block-form-demande .gform_body .g-table .ginput_container_list::-webkit-scrollbar-thumb {
      background: #C59217; }
    .block-form.block-form-demande .gform_body .g-table .ginput_container_list::-webkit-scrollbar-thumb:hover {
      background: #6E273D; }
  .block-form.block-form-demande .gform_body .g-table .gfield_list_icons {
    display: none; }
  .block-form.block-form-demande .gform_body .g-table .gfield_label {
    position: relative;
    top: auto;
    left: auto;
    font-size: 1em; }
  .block-form.block-form-demande .gform_body .g-table.g-table-col {
    padding: 80px 60px 50px 60px; }
  .block-form.block-form-demande .gform_body .g-table.g-table-total {
    padding: 80px 60px 50px 60px; }
  .block-form.block-form-demande .gform_body .g-table.g-table-total-2 {
    display: none; }

.block-form.block-form-demande .gform_body #wrap-bank {
  margin-top: 20px;
  display: flex;
  max-width: 700px;
  border: 1px solid #6E273D; }
  .block-form.block-form-demande .gform_body #wrap-bank #wrap-iban {
    flex: 1; }
    .block-form.block-form-demande .gform_body #wrap-bank #wrap-iban > div {
      padding: 15px 30px; }
    .block-form.block-form-demande .gform_body #wrap-bank #wrap-iban .bank-th {
      color: #FFFFFF;
      background-color: #6E273D; }
    .block-form.block-form-demande .gform_body #wrap-bank #wrap-iban .bank-line {
      color: #6E273D;
      border-right: 1px solid #6E273D; }
  .block-form.block-form-demande .gform_body #wrap-bank #wrap-bic {
    flex: 1; }
    .block-form.block-form-demande .gform_body #wrap-bank #wrap-bic > div {
      padding: 15px 30px; }
    .block-form.block-form-demande .gform_body #wrap-bank #wrap-bic .bank-th {
      color: #FFFFFF;
      background-color: #6E273D; }
    .block-form.block-form-demande .gform_body #wrap-bank #wrap-bic .bank-line {
      color: #6E273D; }

.block-form.block-form-demande .gform_body #montant {
  padding: 30px 50px;
  background-color: #6E273D; }
  .block-form.block-form-demande .gform_body #montant p {
    color: #FFFFFF; }
  .block-form.block-form-demande .gform_body #montant .label {
    font-size: 1.1em;
    font-weight: 200; }
    .block-form.block-form-demande .gform_body #montant .label span {
      display: block;
      font-size: 0.7em; }
  .block-form.block-form-demande .gform_body #montant > div {
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #FFFFFF;
    display: flex;
    justify-content: space-between; }

.block-form.block-form-demande .gform_body .gfield_list {
  width: 100%; }
  .block-form.block-form-demande .gform_body .gfield_list thead {
    border-bottom: 1px solid rgba(197, 146, 23, 0.7); }
    .block-form.block-form-demande .gform_body .gfield_list thead th {
      text-transform: uppercase;
      padding-bottom: 20px;
      color: #C59217;
      font-weight: 600;
      font-size: 0.9em; }
  .block-form.block-form-demande .gform_body .gfield_list .gfield_list_group {
    border-bottom: 1px solid rgba(197, 146, 23, 0.7);
    padding: 5px 0px; }
  .block-form.block-form-demande .gform_body .gfield_list .gfield_list_37_cell1,
  .block-form.block-form-demande .gform_body .gfield_list .gfield_list_37_cell2 {
    position: relative; }
    .block-form.block-form-demande .gform_body .gfield_list .gfield_list_37_cell1:after,
    .block-form.block-form-demande .gform_body .gfield_list .gfield_list_37_cell2:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 3; }
    .block-form.block-form-demande .gform_body .gfield_list .gfield_list_37_cell1 p,
    .block-form.block-form-demande .gform_body .gfield_list .gfield_list_37_cell2 p {
      padding-top: 15px;
      padding-bottom: 15px;
      line-height: 1.1em; }
  .block-form.block-form-demande .gform_body .gfield_list .gfield_list_37_cell1 {
    padding-right: 80px; }
    .block-form.block-form-demande .gform_body .gfield_list .gfield_list_37_cell1 p {
      color: #A5764E;
      font-weight: 400; }
  .block-form.block-form-demande .gform_body .gfield_list .gfield_list_37_cell2 {
    width: 130px; }
    .block-form.block-form-demande .gform_body .gfield_list .gfield_list_37_cell2 p {
      color: #A5764E;
      font-weight: 500; }
  .block-form.block-form-demande .gform_body .gfield_list .gfield_list_37_cell3 {
    width: 130px; }
  .block-form.block-form-demande .gform_body .gfield_list .gfield_list_37_cell3 {
    padding: 10px; }
  .block-form.block-form-demande .gform_body .gfield_list select,
  .block-form.block-form-demande .gform_body .gfield_list input {
    border: 0px;
    padding: 5px 10px 5px 30px;
    font-weight: 600;
    background-color: #FFFFFF;
    border: none;
    width: max-content;
    color: #A5764E; }
    .block-form.block-form-demande .gform_body .gfield_list select::-webkit-inner-spin-button,
    .block-form.block-form-demande .gform_body .gfield_list input::-webkit-inner-spin-button {
      opacity: 1; }

.block-form.block-form-newsletter {
  position: relative;
  padding: 50px;
  background-color: #6E273D; }
  .block-form.block-form-newsletter .form-title {
    clear: both;
    width: 100%;
    color: white;
    text-align: center; }
  .block-form.block-form-newsletter .form-radio .gfield_label {
    font-size: 16px; }
  .block-form.block-form-newsletter .form-radio label {
    left: 0 !important;
    padding-left: 10px !important;
    position: relative !important;
    color: white !important;
    padding: 0 !important; }
  .block-form.block-form-newsletter .form-radio .gfield_radio {
    color: white;
    padding: 0; }
    .block-form.block-form-newsletter .form-radio .gfield_radio .gchoice input {
      margin: 0 10px 0 0 !important;
      background-size: cover;
      padding: 0 !important;
      border: 1px solid #fff; }
    .block-form.block-form-newsletter .form-radio .gfield_radio .gchoice input:checked {
      background: #fff; }
  .block-form.block-form-newsletter .form-infos {
    color: white; }
    .block-form.block-form-newsletter .form-infos input {
      cursor: pointer !important;
      border: none !important;
      padding: 0 !important;
      background-size: cover !important;
      background-image: url("../img/icon/Check-w.svg") !important; }
    .block-form.block-form-newsletter .form-infos input:checked {
      background-color: #fff !important; }
    .block-form.block-form-newsletter .form-infos label {
      width: calc(100% - 20px); }
  .block-form.block-form-newsletter .validation_error {
    color: #FFFFFF !important;
    font-weight: 200;
    font-size: 0.8em; }
  .block-form.block-form-newsletter .gform_body .gfield_label,
  .block-form.block-form-newsletter .gform_body .gfield_required {
    color: #FFFFFF; }
  .block-form.block-form-newsletter .gform_body .validation_message {
    margin-bottom: 10px;
    font-size: 0.8em;
    font-weight: 200;
    color: red !important; }
  .block-form.block-form-newsletter input:not([type="checkbox"]),
  .block-form.block-form-newsletter textarea,
  .block-form.block-form-newsletter select {
    border-color: #FFFFFF;
    color: #FFFFFF; }
    .block-form.block-form-newsletter input:not([type="checkbox"]):focus,
    .block-form.block-form-newsletter textarea:focus,
    .block-form.block-form-newsletter select:focus {
      border-color: #C59217; }
  .block-form.block-form-newsletter .gform_footer {
    text-align: left; }

/*
|
| Gravity form bootstrap
|-------------------------
*/
.gravity-form-bootstrap .gform_fields input,
.gravity-form-bootstrap .gform_fields textarea {
  width: 100%;
  background-color: transparent; }

/*
|
| Contact form 
|---------------
*/
.form {
  /*
  |
  | Reset form elements
  |-------------------------
  */ }
  .form input,
  .form textarea,
  .form select {
    -webkit-appearance: none;
    border-radius: 0; }
    .form input:focus,
    .form textarea:focus,
    .form select:focus {
      outline: none; }
  .form select {
    -webkit-appearance: none;
    border: 0px;
    background-color: transparent;
    background-image: url("../img/icon/arrow-down-small.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: calc(100% - 10px) center;
    padding: 5px 50px 5px 15px;
    letter-spacing: 1px;
    font-weight: 300;
    max-width: 100%; }
    @media (max-width: 991.98px) {
      .form select {
        margin-top: 10px; } }
  .form label {
    color: #3A3A3A; }
  .form textarea {
    border: 2px #949494 solid;
    height: 200px;
    padding: 15px;
    color: #000000;
    font-size: 18px;
    border-radius: 2px; }
    .form textarea::placeholder {
      color: #000000; }
  .form .gform_body {
    margin-bottom: 20px; }
  .form .gform_footer {
    text-align: right; }
  .form input[type="submit"] {
    position: relative;
    background-color: transparent;
    color: #646464;
    padding: 15px 30px;
    transition: all 0.3s ease-out;
    text-transform: uppercase;
    cursor: pointer;
    border: 2px solid #949494;
    border-radius: 2px; }
    .form input[type="submit"]:hover {
      color: #FFFFFF;
      background: #949494; }
  .form .gform_confirmation_message {
    color: #3A3A3A; }
  .form .validation_message {
    margin-top: 5px;
    color: red;
    font-size: 14px; }

.form-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: inherit; }
  .form-filter select {
    color: #FFFFFF;
    background-image: url("../img/icon/arrow-down-small-white.svg");
    cursor: pointer; }
    .form-filter select:hover {
      cursor: pointer; }
    .form-filter select option {
      color: initial;
      text-transform: inherit; }
    .form-filter select:not(:last-child) {
      margin-right: 20px; }

.validation_error {
  margin-bottom: 30px;
  color: #bb0b0b !important; }

#gform_ajax_spinner_1 {
  margin-left: 10px;
  display: inline-block;
  width: 50px;
  height: 50px; }

.title_field {
  font-weight: 400;
  line-height: 1em;
  transition: 0.2s linear;
  color: #6e273d;
  letter-spacing: 0.4px;
  padding-right: 15px;
  padding-left: 15px; }

.nbr_field {
  margin-bottom: 17px !important; }
  .nbr_field .gfield_label {
    text-transform: initial !important;
    position: relative !important;
    min-width: 180px !important;
    float: left;
    font-family: TheSans, sans-serif;
    color: #a5764e !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 1.6 !important;
    left: 0 !important;
    top: 0 !important;
    padding-right: 20px !important; }
  .nbr_field .ginput_container {
    position: relative;
    float: left; }
  .nbr_field input {
    padding: 0px !important;
    max-width: 40px;
    margin-right: 10px;
    text-align: center; }

.other_field .gfield_label {
  text-transform: initial !important;
  position: relative !important;
  min-width: 60px !important;
  float: left;
  font-family: TheSans, sans-serif;
  color: #a5764e !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 1.6 !important;
  left: 0 !important;
  top: 0 !important;
  padding-right: 20px !important; }

.other_field .ginput_container {
  position: relative;
  float: left; }

.other_field input {
  width: 280px !important;
  clear: both;
  display: block !important;
  max-width: 250px !important;
  text-align: left !important;
  padding: 0 !important; }

/*
|
| Page Loader
|--------------
*/
.page-loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  display: none; }
  .page-loader.active {
    display: flex; }
  .page-loader .item-content {
    width: 100%;
    color: #C59217;
    text-align: center; }
    .page-loader .item-content svg {
      width: 120px; }
  .page-loader .item-loadbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    margin: auto; }
    .page-loader .item-loadbar .item-loadbar-inner {
      width: 100%;
      height: 100%;
      border-radius: 1px;
      background-color: #C59217;
      animation: loadbar 1.2s cubic-bezier(0.92, 0, 0.09, 1);
      transform-origin: left top; }

@keyframes loadbar {
  from {
    transform: scaleX(0); }
  to {
    transform: scaleX(0.7); } }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  transform: scale(0.7); }

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #C59217;
  border-color: #C59217 transparent #C59217 transparent;
  animation: lds-dual-ring 1.2s linear infinite; }

.container-loader-btn {
  position: relative; }
  .container-loader-btn .btn-target {
    opacity: 1;
    display: inline-flex; }
  .container-loader-btn .loader {
    display: block;
    pointer-events: none;
    position: absolute;
    width: max-content;
    height: max-content;
    left: 0;
    right: 0;
    top: -25px;
    margin: auto;
    transition: 0.2s linear;
    opacity: 0;
    pointer-events: none; }
  .container-loader-btn.is-loading .btn-target {
    opacity: 0;
    pointer-events: none; }
  .container-loader-btn.is-loading .loader {
    top: -5px;
    opacity: 1; }
  .container-loader-btn.is-finish .btn-target {
    opacity: 0;
    display: none;
    pointer-events: none; }
  .container-loader-btn.is-finish .loader {
    opacity: 0;
    display: none;
    pointer-events: none; }

[data-splittext] {
  opacity: 0; }
  [data-splittext].split-ready {
    opacity: 1; }

[data-kira-item="splitline"] .item-line {
  overflow: hidden; }

[data-kira-item^="fadeIn"] {
  opacity: 0; }

[data-kira-item^="fadeInLeft.stagger"],
[data-kira-item^="fadeInUp.stagger"] {
  opacity: 1; }
  [data-kira-item^="fadeInLeft.stagger"] [data-stagger-item],
  [data-kira-item^="fadeInUp.stagger"] [data-stagger-item] {
    opacity: 0; }

@media print {
  .print-section .col-sm-1, .print-section .col-sm-2, .print-section .col-sm-3, .print-section .col-sm-4, .print-section .col-sm-5, .print-section .col-sm-6, .print-section .col-sm-7, .print-section .col-sm-8, .print-section .col-sm-9, .print-section .col-sm-10, .print-section .col-sm-11, .print-section .col-sm-12 {
    float: left; }
  .print-section .col-sm-4 {
    width: 33.33333333%; }
  .print-section .title-lg, .print-section .cms h3, .cms .print-section h3 {
    font-size: 20px; }
  .print-section .print-btn {
    display: none; }
  .print-section .keyCard-content {
    background-color: #e0d0a7; }
  .print-section .keyCard-content .keyCard-detail {
    padding-left: 15px;
    padding-right: 15px; }
  .print-section .clear {
    clear: both; } }
