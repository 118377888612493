.section-thrid-colonne{
	.item-row{
		position: relative;
		padding: 70px;
		@include media-breakpoint-down(md){
		    padding: 30px 15px;
		}
	}

	.item-col{
		padding-left: 60px;
		padding-right: 30px;
		@include media-breakpoint-down(md){
		  	padding-left: 15px;
			padding-right: 15px;
		}
	}

	.presentation-img-ctr{
		padding: 0px;
		padding-top: 35px;
		.presentation-img{
			.lazy-ctr-contain img{
				width: 100%;
				height: 100%;
			    object-position: top;
			}
		}

	}
}