/*
-------------------------------- 
Nav Fullscreen
--------------------------------
*/
#header-fullscreen {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: block;
    opacity: 0;
    pointer-events: none;
    .menu-item{
        min-height: 84px;
        transition: 0.2s linear;
        @include media-breakpoint-down(md){
            min-height: auto;
        }
        .item-link{
            font-weight: 500;
            font-size: 1.2em;
            padding: 25px 25px 25px 0px;
            position: relative;
            transition: 0.2s linear;
            @include media-breakpoint-down(md){
                padding: 15px 25px 15px 0px;
            }
            .icon{
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0px;
                height: max-content;
                margin: auto;
                transition: 0.2s linear;
                @include media-breakpoint-down(md){
                    right: 25px;
                }
            }
        }
        &:hover{
            .item-link{
                .icon{
                    .vertical{
                        transform: scale(0);
                    }
                }

            }
        }   
    }
    .nav-firstlevel{
        &:hover{
            &>.menu-item{
                @include media-breakpoint-up(lg){
                    opacity: 0.3;
                }
            }
        }
        &>.menu-item{
            transition: 0.2s linear;
            &>.item-link{
                display: flex;
                color: $burgundy;
                width: 500px;
                max-width: 75%;
                margin: auto;
                @include media-breakpoint-down(md){
                    width: 100%;
                    max-width: 100%;
                    padding-left: 15px;
                    padding-right: 45px;
                }
                .icon{
                    color: $gold;
                }
            }
            &:hover{
               @include media-breakpoint-up(md){
                    background-color: $burgundy;
                    opacity: 1;
                    &>.item-link{
                        color: $white;
                    }
                }
            }
            &.menu-item-has-children{

               @include media-breakpoint-down(md){
                    &.is-active{
                        background-color: $burgundy;
                        .item-link{
                            color: $white;
                        }
                        .nav{
                            position: relative;
                            display: block;
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }
                }

                .nav{
                    position: absolute;
                    left: 100%;
                    top: 0;
                    background-color: $burgundy;
                    transition: 0.2s linear;
                    pointer-events: none;
                    @include media-breakpoint-down(md){
                        position: relative;
                        left: auto;
                    }
                    .menu-item{
                        .item-link{
                            font-size: 1.1em;
                            font-weight: 400;
                            padding: 27px 60px;
                            width: 100%;
                            color: $white;
                            @include media-breakpoint-down(md){
                                font-size: 1em;
                                font-weight: 400;
                                padding: 17px 36px;
                                text-align: left;
                            }
                            .icon{
                                right: 25px;
                                transform: scale(0.6);

                                 .vertical{
                                }
                            }
                        }
                        &:hover{
                            background-color: $dark-burgundy;
                            .item-link{
                                .icon{
                                    .vertical{
                                        transform: scale(0);
                                    }
                                }
                            }
                        }
                    }
                }
                &>.nav{
                    width: 50%;
                    opacity: 0;
                    @include media-breakpoint-down(md){
                        width: 100%;
                        display: none;
                    }
                    .menu-item{
                        width: 100%;
                        text-align: center;
                        &:after{
                            content:'';
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            height: 1px;
                            background-color: $white;
                        }
                        &:last-child{
                            &:after{
                                display: none;
                            }
                        }
                        &.menu-item-has-children{
                            .nav{
                                position: relative;
                                transform: none;
                                left: 0;
                                background-color: $dark-burgundy;
                                //opacity: 0;
                                //height: 0;
                                opacity: 1;
                                height: max-content;
                                .menu-item{
                                    &:hover{
                                        background-color: $very-dark-burgundy;
                                    }
                                }
                                .item-link{
                                    font-size: 1em;

                                }
                                 &:hover{
                                    .nav{
                                        //opacity: 1;
                                        //height: max-content;
                                    }
                                }
                            }   

                           
                        }
                    }

                   
                } 

                
                &:hover{
                    @include media-breakpoint-up(md){
                        background-color: $burgundy;
                        .nav{
                            pointer-events: auto;
                            @include media-breakpoint-down(md){
                                &.is-active{
                                    position: relative;
                                    display: block;
                                }
                            }
                        }
                        &>.item-link{
                            color: $white;
                             .icon{
                                color: $white;
                            }
                        }
                        &>.nav{
                             opacity: 1;
                             visibility: visible;
                        }
                    }
                }
            }
        }
    }


    .bg-black{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: transparent;
        &:hover{
            cursor: pointer;
        }
    }

    .btn-menu{
        
    }

    .header-bg{
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        height: 100vh;
        width: 50%;
        background-color: $white;
        box-shadow: 6px 0px 20px 0px #0a030330;

        @include media-breakpoint-down(md){
            width: 100%;
        }
    }

    .fullscreen-container{
        position: relative;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        max-height: 100vh;
        padding-bottom: 0px;
        display: flex;
        flex-direction: column;
       
        .inner{
            position: relative;
            z-index: 10;
            padding-top: 15px;
            padding-bottom: 15px;
             @include media-breakpoint-down(md){
                width: 100%;
            }
        }
        .inner-btn{
            padding: 18px 15px;
            text-align: center;
            width: 50%;
            @include media-breakpoint-down(md){
                width: 100%;
            }
        }
        .inner-nav{
            flex: 1;
            width: 100%;
            overflow-y: scroll;
            padding-top: 0px;

             &::-webkit-scrollbar {
              width: 1px;
            }
            &::-webkit-scrollbar-track {
              background: $white;
            }
            &::-webkit-scrollbar-thumb {
              background: $white;
            }
            &::-webkit-scrollbar-thumb:hover {
              background: $white;
            }

            .fullscreen-nav{
                width: calc(50% + 1px);
                @include media-breakpoint-down(md){
                    width: 100%;
                }
            }
        }
    }

    .fullscreen-nav{
        position: relative;
        z-index: 10;
        padding-left: 0px;
        padding-right: 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
       .list-btn{
            text-align: center;
            display: flex;
            justify-content: center;
       }
    }

    .nav{
        padding: 0px;
        counter-reset: section;
        .menu-item{
            position: relative;
            list-style: none;
            color: $white;
            .nav{
                padding: 0px;
            }
            &:last-child{
                margin-bottom: 0px;
            }
        }
        > .menu-item{
            > a.link-item{
                //@extend .link-title;
            }
        }
    }


    &.is-menu{
        pointer-events: auto;
        opacity: 1;
        .burger {
           
        }

    }
}


