.collapse-content{

	.card-title{
		display: flex;
		align-items: center;
		padding: 30px 50px 30px 0px;
	    @include media-breakpoint-down('md'){
	    	padding: 10px 0px 10px 0px;
	    	align-items: left;
	    	margin-bottom: 10px;
    	    text-align: left;
	    }
		&.collapsed{
			.icon{
				transform: rotate(90deg);
			}
		}
		.title{
			letter-spacing: 1.5px;
		}
		.icon{
			margin-left: 15px;
			transform: rotate(-90deg);
			transition: 0.4s linear;
		}
	}
	.card-content{

	}
}