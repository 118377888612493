/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/
.block-form {
  .search-form {
    padding: 15px;
    display: flex;
    align-items: center;
    background-color: $gold;
    transition: 0.6s $easeInQuint;

    input[type="search"] {
      position: relative;
      border: 0px;
      padding: 15px;
      width: 100%;
    }

    .btn-block {
      width: 80px;

      .icon {
        svg {
          [fill^="#"] {
            fill: $gold;
          }
        }

        &:after {
          display: none;
        }
      }

      &:hover {
        .icon {
          svg {
            [fill^="#"] {
              fill: $gold;
            }
          }
        }
      }
    }
  }

  .gform_validation_errors {
    margin-top: 5px;
    color: red;
    font-size: 14px;
    width: 100%;
    border: 1px solid;
    padding: 15px 20px;
    margin-bottom: 20px;
  }

  .gform_body {
    .gsection {
      margin-bottom: 20px;
      margin-top: 50px;
    }

    .gsection_title {
      font-size: 2.2em;
      font-weight: 500;
      color: $gold;
      margin-bottom: 20px;
    }

    .hidden_label {
      .gfield_label {
        display: none !important;
      }
    }

    .ginput_container_fileupload {
      .gform_fileupload_rules {
        display: none;
      }
    }

    .gfield_label {
      text-transform: uppercase;
      position: absolute;
      font-size: 16px;
      top: 18px;
      left: 15px;
      margin-bottom: 0px;
      font-weight: 400;
      line-height: 1em;
      transition: 0.2s linear;
      color: $burgundy;
      letter-spacing: 0.4px;

      top: -6px;
      font-size: 0.8em;

      .gfield_required {
        color: red;
        padding-left: 2px;
      }

      &.active {
        top: -6px;
        font-size: 0.8em;
      }

      &.gfield_label_before_complex {
        position: relative;
        left: auto;
        top: auto;
        font-size: 1em;
        margin-bottom: 20px;
      }
    }

    input:not([type="checkbox"]),
    textarea,
    select {
      background-color: transparent;
      -webkit-appearance: none;
      padding: 12px 20px 10px 0;
      border-left: 0px;
      border-top: 0px;
      border-right: 0px;
      border-bottom: 1px solid $gold;
      font-size: 16px;
      font-weight: 400;
      color: $burgundy;
      transition: 0.2s linear;

      &:focus {
        outline: none;
        border-color: $burgundy;
      }

      &::placeholder {
        font-weight: 400;
      }
    }

    select {
      background-image: url("../img/icon/arrow-down-small.svg");
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: calc(100% - 10px) center;
      padding-right: 50px;
    }

    .g-radio {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .gfield_label {
        position: relative;
        top: auto;
        left: auto;
        font-size: 1em;
        margin-right: 20px;
        color: $gold;
        font-weight: 400;
        text-transform: none;
      }
    }

    .gfield_radio,
    .gfield_checkbox {
      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;

        input {
          width: auto;
          margin-right: 0px;
          margin-bottom: 2px;
          height: 20px;
          width: 20px;
          -webkit-appearance: none;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("../img/icon/Check.svg");
          border-bottom: 0px;

          &:checked {
            background-color: $gold;
          }
        }

        label {
          padding-left: 20px;
          cursor: pointer;
        }
      }
    }

    .gfield_radio {
      display: flex;

      li {
        margin-bottom: 0px;
        margin-right: 40px;

        input {
          border-radius: 100%;
          background-image: url("../img/icon/Radio.svg");
          border-bottom: 0px;
        }

        label {
          color: $burgundy;
          cursor: pointer;
        }
      }
    }

    .gfield_radio_checkbox .gfield_radio {
      flex-direction: column;

      li {
        margin-bottom: 15px;

        label {
          padding-left: 20px;
          cursor: pointer;
          color: $gold;
        }
      }
    }

    textarea {
      height: 200px;
    }

    .file-rgpd {
      .gfield_label {
        display: none;
      }

      .ginput_container {
        display: flex;

        input {
          width: 20px;
          margin: 7px 20px 7px 0;
        }

        .gfield_consent_label {
        }
      }

      a {
        color: $gold;
        padding-left: 0px;
        text-decoration: underline;

        &:hover {
          color: $dark-gold;
        }
      }
    }

    .gform_fields {
      @extend .row;

      input,
      textarea,
      select {
        width: 100%;
      }

      .gfield {
        margin-bottom: 30px;
      }
    }

    .gfield_description {
    }

    .gform_body {
      margin-bottom: 20px;
    }

    .gform_confirmation_message {
      color: $very-dark-grey;
    }

    .validation_message {
      margin-top: 5px;
      color: red;
      font-size: 14px;
    }
  }

  .gform_footer {
    text-align: left;
    padding-top: 0;

    button[type="submit"] {
    }
  }

  &.block-form-demande {
    .gform_body {
      .g-table {
        background-color: $light-gold;

        .ginput_container_list {
          max-height: 70vh;
          overflow-y: scroll;
          padding-right: 10px;

          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-track {
            background: rgba($gold, 0.2);
          }

          &::-webkit-scrollbar-thumb {
            background: $gold;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: $burgundy;
          }
        }

        .gfield_list_icons {
          display: none;
        }

        input {
        }

        .gfield_label {
          position: relative;
          top: auto;
          left: auto;
          font-size: 1em;
        }

        &.g-table-col {
          padding: 80px 60px 50px 60px;
        }

        &.g-table-total {
          padding: 80px 60px 50px 60px;
        }

        &.g-table-total-2 {
          display: none;
        }
      }

      #wrap-bank {
        margin-top: 20px;
        display: flex;
        max-width: 700px;
        border: 1px solid $burgundy;

        #wrap-iban {
          flex: 1;

          > div {
            padding: 15px 30px;
          }

          .bank-th {
            color: $white;
            background-color: $burgundy;
          }

          .bank-line {
            color: $burgundy;
            border-right: 1px solid $burgundy;
          }
        }

        #wrap-bic {
          flex: 1;

          > div {
            padding: 15px 30px;
          }

          .bank-th {
            color: $white;
            background-color: $burgundy;
          }

          .bank-line {
            color: $burgundy;
          }
        }
      }

      #montant {
        padding: 30px 50px;
        background-color: $burgundy;

        p {
          color: $white;
        }

        .label {
          font-size: 1.1em;
          font-weight: 200;

          span {
            display: block;
            font-size: 0.7em;
          }
        }

        > div {
          padding: 20px 0 10px 0;
          border-bottom: 1px solid $white;
          display: flex;
          justify-content: space-between;
        }
      }

      .gfield_list {
        width: 100%;

        thead {
          border-bottom: 1px solid rgba($gold, 0.7);

          th {
            text-transform: uppercase;
            padding-bottom: 20px;
            color: $gold;
            font-weight: 600;
            font-size: 0.9em;
          }
        }

        .gfield_list_group {
          border-bottom: 1px solid rgba($gold, 0.7);
          padding: 5px 0px;
        }

        .gfield_list_37_cell1,
        .gfield_list_37_cell2 {
          position: relative;

          &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 3;
          }

          p {
            padding-top: 15px;
            padding-bottom: 15px;
            line-height: 1.1em;
          }
        }

        .gfield_list_37_cell1 {
          padding-right: 80px;

          p {
            color: $light-brown;
            font-weight: 400;
          }
        }

        .gfield_list_37_cell2 {
          width: 130px;

          p {
            color: $light-brown;
            font-weight: 500;
          }
        }

        .gfield_list_37_cell3 {
          width: 130px;
        }

        .gfield_list_37_cell3 {
          padding: 10px;
        }

        select,
        input {
          border: 0px;
          padding: 5px 10px 5px 30px;
          font-weight: 600;
          background-color: $white;
          border: none;
          width: max-content;
          color: $light-brown;

          &::-webkit-inner-spin-button {
            opacity: 1;
          }
        }
      }
    }
  }

  &.block-form-newsletter {
    position: relative;
    padding: 50px;
    background-color: $burgundy;
    .form-title {
      clear: both;
      width: 100%;
      color: white;
      text-align: center;
    }

    .form-radio {
      .gfield_label {
        font-size: 16px;
      }
      label {
        left: 0 !important;
        padding-left: 10px !important;
        position: relative !important;
        color: white !important;
        padding: 0 !important;
      }
      .gfield_radio {
        color: white;
        padding: 0;
        .gchoice {
          input {
            margin: 0 10px 0 0 !important;
            background-size: cover;
            padding: 0 !important;
            border: 1px solid #fff;
          }
          input:checked {
            background: #fff;
          }
        }
      }
    }

    .form-infos {
      color: white;
      input {
        cursor: pointer !important;
        border: none !important;
        padding: 0 !important;
        background-size: cover !important;
        background-image: url("../img/icon/Check-w.svg") !important;
      }
      input:checked {
        background-color: #fff !important;
      }
      label {
        width: calc(100% - 20px);
      }
    }

    .validation_error {
      color: $white !important;
      font-weight: 200;
      font-size: 0.8em;
    }

    .gform_body {
      .gfield_label,
      .gfield_required {
        color: $white;
      }

      .validation_message {
        margin-bottom: 10px;
        font-size: 0.8em;
        font-weight: 200;
        color: red !important;
      }
    }

    input:not([type="checkbox"]),
    textarea,
    select {
      border-color: $white;
      color: $white;

      &:focus {
        border-color: $gold;
      }
    }

    .gform_footer {
      text-align: left;
    }
  }
}

/*
|
| Gravity form bootstrap
|-------------------------
*/
.gravity-form-bootstrap {
  .gform_fields {
    @extend .row;

    input,
    textarea {
      width: 100%;
      background-color: transparent;
    }
  }
}

/*
|
| Contact form 
|---------------
*/
.form {
  /*
  |
  | Reset form elements
  |-------------------------
  */
  input,
  textarea,
  select {
    -webkit-appearance: none;
    border-radius: 0;

    &:focus {
      outline: none;
    }
  }

  select {
    -webkit-appearance: none;
    border: 0px;
    background-color: transparent;
    background-image: url("../img/icon/arrow-down-small.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: calc(100% - 10px) center;
    padding: 5px 50px 5px 15px;
    letter-spacing: 1px;
    font-weight: 300;
    max-width: 100%;
    @include media-breakpoint-down(md) {
      margin-top: 10px;
    }
  }

  label {
    color: $very-dark-grey;
  }

  textarea {
    border: 2px $light-grey solid;
    height: 200px;
    padding: 15px;
    color: $black;
    font-size: 18px;
    border-radius: 2px;

    &::placeholder {
      color: $black;
    }
  }

  .gform_body {
    margin-bottom: 20px;
  }

  .gform_footer {
    text-align: right;
  }

  input[type="submit"] {
    position: relative;
    background-color: transparent;
    color: $dark-grey;
    padding: 15px 30px;
    transition: all 0.3s ease-out;
    text-transform: uppercase;
    cursor: pointer;
    border: 2px solid $light-grey;
    border-radius: 2px;

    &:hover {
      color: $white;
      background: $light-grey;
    }
  }

  .gform_confirmation_message {
    color: $very-dark-grey;
  }

  .validation_message {
    margin-top: 5px;
    color: red;
    font-size: 14px;
  }
}

.form-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: inherit;

  select {
    color: $white;
    background-image: url("../img/icon/arrow-down-small-white.svg");
    cursor: pointer;

    &:hover {
      cursor: pointer;
    }

    option {
      color: initial;
      text-transform: inherit;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.validation_error {
  margin-bottom: 30px;
  color: #bb0b0b !important;
}

#gform_ajax_spinner_1 {
  //display: none;
  margin-left: 10px;
  display: inline-block;
  width: 50px;
  height: 50px;
}

.title_field {
  font-weight: 400;
  line-height: 1em;
  transition: 0.2s linear;
  color: #6e273d;
  letter-spacing: 0.4px;
  padding-right: 15px;
  padding-left: 15px;
}

.nbr_field {
  margin-bottom: 17px !important;
  .gfield_label {
    text-transform: initial !important;
    position: relative !important;
    min-width: 180px !important;
    float: left;
    font-family: TheSans, sans-serif;
    color: #a5764e !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 1.6 !important;
    left: 0 !important;
    top: 0 !important;
    padding-right: 20px !important;
  }

  .ginput_container {
    position: relative;
    float: left;
  }

  input {
    padding: 0px !important;
    max-width: 40px;
    margin-right: 10px;
    text-align: center;
  }
}

.other_field {
  .gfield_label {
    text-transform: initial !important;
    position: relative !important;
    min-width: 60px !important;
    float: left;
    font-family: TheSans, sans-serif;
    color: #a5764e !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 1.6 !important;
    left: 0 !important;
    top: 0 !important;
    padding-right: 20px !important;
  }
  .ginput_container {
    position: relative;
    float: left;
  }
  input {
    width: 280px !important;
    clear: both;
    display: block !important;
    max-width: 250px !important;
    text-align: left !important;
    padding: 0 !important;
  }
}
