/*
|--------------------
|       Contact
|--------------------
*/

#page-contact{
    /*
    |
    | Section contact
    |------------------
    */
    .section-map{
        #map {
            position: relative;
            width: 100%;
            height: 50vh;
            background-color: $black;
        }
    }   

}
