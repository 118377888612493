.block-colonne{


}

.item-block-ctr{
	@include media-breakpoint-down(md){
		margin-bottom: 20px;
	}
	.item-block{
		height: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		@include media-breakpoint-down(md){
			flex-direction: column
		}	
		.bg-img-portrait{
			width: 220px;
			@include media-breakpoint-down(md){
				height: 220px;
				width: 100%;
			}
		}
		.item-content{
		    display: flex;
		    flex-direction: column;
		    padding: 50px 50px;
			flex: 1;
		    justify-content: space-between;
			@include media-breakpoint-down(md){
		    	padding: 40px 20px;
		    }
		}
	}	

	&.reverse{
		flex-direction: row-reverse;
	}
}	