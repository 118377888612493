/*
|--------------------
|      SINGLE
|--------------------
*/

.post-date {
    font-size: 12px;
    color: rgba($grey, 0.6);
    text-transform: uppercase;
    font-weight: 400;
}

.post-excerpt {
    font-size: 22px;
    color: rgba($very-dark-grey, 0.6);
    font-weight: 400;
}

#page-news-single {
    .presentation-image {
        @include media-breakpoint-up(md) {
            width: 450px;
            height: 600px;
            margin: 0 auto;
        }
        .img-cover {
            .wrapper-img {
                @include media-breakpoint-up(md) {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    // border: 1px solid;
                    align-items: center;
                    justify-content: center;
                }
                img {
                    @include media-breakpoint-up(md) {
                        display: inline-block;
                        position: relative;
                        width: 450px;
                        height: 600px;
                        object-fit: cover;
                        margin-left: auto;
                        margin-right: auto;
                        // border: 1px solid red;
                    }
                }
            }
        }
    }
}