.section-more-doc{
	position: relative;
	z-index: 10;
	.item-content{
		padding-top: 60px;
		padding-bottom: 60px;
		position: relative;
		z-index: 10;
		background: $gold;
        @include media-breakpoint-down(sm) {
			padding: 70px 15px;
        }
	}
}