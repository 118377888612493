/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| Colors declaration
|----------------------
|
*/
$black:           #000000;
$very-dark-grey:  #3A3A3A; 
$dark-grey:       #646464; 
$grey:            #333333;
$light-grey:      #949494; 
$very-light-grey: #F6F6F6;
$white:           #FFFFFF;
$very-light-blue: #ECF0FA;
$light-blue:      #EBEFFA;
$blue:            #1A4194;
$dark-blue:       #29264C; 
$red:             #E42022; 
$dark-red:        #E42022;
$light-gold:       #f9f4e7; //rgba(#C59217,0.1);//
$gold:             #C59217; //
$dark-gold:        #623C1B; //
$burgundy:         #6E273D; //
$dark-burgundy:    #501d2d; //
$very-dark-burgundy : #381520; //
$green:   #BED600; //
$beige: #dec27d;//
$light-brown:  #A5764E;
$brown:    #623C1B; //
$white-opacity:   rgba(255, 255, 255, 0.8);
$black-opacity:   rgba(0, 0, 0, 0.6);


/*
|
| Assign colors
|---------------
|
*/
$border-color:                  $grey;
$text-color:                    $light-brown;
$default-background-overlay:    $black-opacity;

/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .color-{color}
|
*/
$colors : (
  //Required colors 
  'green'             : $green,
  'very-light-blue'   : $very-light-blue,
  'light-blue'        : $light-blue,
  'blue'              : $blue,
  'dark-blue'         : $dark-blue,
  'red'               : $red,
  'dark-red'          : $dark-red,
  'burgundy'          : $burgundy,
  'dark-burgundy'     : $dark-burgundy,
  'very-dark-burgundy': $very-dark-burgundy,
  'light-gold'        : $light-gold,
  'gold'            : $gold,
  'dark-gold'       : $dark-gold,
  'black'          : $black,
  'light-brown'    : $light-brown,
  'brown'          : $brown,
  'very-dark-grey' : $very-dark-grey,
  'dark-grey'      : $dark-grey,
  'grey'           : $grey,
  'beige'           : $beige,
  'light-grey'     : $light-grey,
  'very-light-grey': $very-light-grey,
  'white'          : $white
);



