.btn-list{

}

/*
|--------------------
|      color
|--------------------
*/
.btn-default{
    &.btn-light-gold{
        background-color: $light-gold; 
        border-color: $gold; 
        color: $gold;
        &:hover{

        }
    } 

    &.btn-gold{
        background-color: $gold; 
        border-color: $gold; 
        color: $white;
        &:hover{

        }
    }
} 


.btn-download{
   text-transform: none !important;
   &.tt-u{
       text-transform: uppercase !important;
   }
}
/*
|--------------------
|      BUTTONS
|--------------------
*/
.btn-target{
    display: inline-flex;
    align-items: center;
    padding : 2px 4px 2px 4px;
    box-shadow: none !important;
    font-size : 1em;
    text-transform: uppercase;
    color: $white;
    font-weight: 400;
    letter-spacing: 1px;
    .icon{
        margin-left: 15px;
        margin-right: 0px;
        transition: transform 0.2s $easeInOutQuad;
    }
    .text{
        position: relative;
        display: inline-block;
        overflow: hidden;
        span{
            transition: transform 0.3s $easeInOutQuad;
            transform: translate3d(0,0,0);
        }
        &:before{
            transition: transform 0.3s $easeInOutQuad;
            position: absolute;
            top: 100%;
            content: attr(data-hover);
            letter-spacing: 0.9px;
            font-weight: 700;
            transform: translate3d(0,0,0);
        }
    }
    &.reverse{
        flex-direction: row-reverse;
         .icon{
            margin-left: 0px;
            margin-right: 15px;
        }
        &:hover{
            .icon{
               transform: none;
            }
        }
    }
    &.btn-target-simple{
        &:hover{
            .icon{
               transform: translatex(10px);
            }
        }
    }

    &.single-icon{
        padding: 0px;
        .icon{
            margin: 0px;
        }
    }
    &.btn-target-white{
        color: $white;
    }
    &.btn-target-brown{
        color: $brown;
    }
    &.btn-target-burgundy{
        color: $burgundy;
    }
    &.btn-target-gold{
        color: $gold;
    }
    &.btn-target-light-gold{
        color: $light-gold;
    }

    &:hover{
        .text{
            span{transform: translateY(-100%);}
            &:before{transform: translateY(-100%);}
        }
        
    }


}

/*
|--------------------
|      BUTTONS
|--------------------
*/
.btn-menu {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    padding: 0;
    border: none;
    background-color: $burgundy;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 100%;
    .text-burger{
        color: $white;
        text-transform: uppercase;
        line-height: 1em;
        text-transform: uppercase;
        font-size: 0.8em;
        margin-top: 9px;
    }
    &>.item-burger {
        display: block;
        width: 25px;
        margin-top: 10px;
        &>span {
            display: block;
            width: 100%;
            height: 2px;
            background-color: $white;
            border-radius: 2px;
            &:nth-child(2) {
                margin: 6px 0;
                transform: scale(0.7);
            }
        }
    }

     &.is-close{
	    &>.item-burger {
	    	margin-top: 0px;
	        &>span {
	            &:nth-child(2) {
	                opacity: 0;
	            }
	            &:first-child {
	                transform: translate3d(0, 9px, 0) rotate3d(0, 0, 1, -45deg);
	            }
	            &:last-child {
	                transform: translate3d(0, -7px, 0) rotate3d(0, 0, 1, 45deg);
	            }
	        }
        }
    }
}