#page-about {
    .swiper-team-ctr {
        &.slider-controls-off {
            .swiper-navigation {
                @include media-breakpoint-up(lg){
                    display: none;
                }
            }
        }
    }
}