
/*
*
*	ressourceCard
*---------------
*/
.teamCard-ctr{
	padding-top: 40px;
}
.teamCard-content{
	display: flex;
	flex-direction: column;
    height: 100%;
    padding: 0px;
	transition: 0.2s linear;
	padding: 30px 60px 50px 60px;
	position: relative;
	background-color: $white;
	text-align: left;
	> div{
		width: 100%;
	}
	.teamCard-bg-ctr{
	    width: 100%;
	    margin-right: 30px;
	    position: relative;
	    @include media-breakpoint-down(sm){
	   	 	margin-right: 0px;
    		width: 100%;
    	}
	}
	.teamCard-detail{
		font-size: 0.7em;
		color: $white;
		background-color: $gold;
		text-transform: uppercase;
		position: absolute;
		padding: 8px 15px;
		letter-spacing: 1.5px;
		font-weight: 200;
		width: auto;
		top: 0px;
		left: 40px;
		right: 40px;
		transform: translateY(-50%);
		text-align: center;
	}
	.teamCard-title{
		margin-bottom: 0;
		padding-top: 10px;
		color: $burgundy;
		line-height: 1.2em;
	}
	.teamCard-text {
		color: $burgundy;
		line-height: 1.2em;
		margin-top: 5px;
		margin-bottom: 25px;
	}
	.teamCard-bg{
    	position: relative;
    	overflow: hidden;
    	background-color: $grey;
    	.img-fluid{
			transform: scale(1.2);
			transition: 1s cubic-bezier(0.4, 0, 0.2, 1);
		}
	}
	&:hover{
		.teamCard-bg-ctr{
			.img-fluid{
				transform: scale(1);
			}
		}	
	}
}
