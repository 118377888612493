.modal-video{
	@include media-breakpoint-down(sm) {
	    width: 100% !important;
    	max-width: 100% !important;
	}
    &.iziModal .iziModal-header-buttons{
        position: absolute;
        top: -30px;
        right: 0;
    }
}
.link-video{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.block-video{
    position: relative !important;
}
.ytp-pause-overlay-controls-hidden .ytp-pause-overlay,
.ytp-pause-overlay{
    opacity: 0 !important;
}