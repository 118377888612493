.section-two-colonne{
	position: relative;
	z-index: 10;
	.item-content{
		padding: 40px 30px;
		position: relative;
		z-index: 10;
		height: 100%;
	    display: flex;
	    flex-direction: column;
	    justify-content: center;
        @include media-breakpoint-down(sm) {
			padding: 70px 15px;
        }
	}
}