/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}
* {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    font-family: $font-family-default;
    color: $light-brown;
    font-size: $default-font-size;
    line-height: $default-line-height;
    font-weight: 400;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-x: hidden;
    &.overflowhidden {
        overflow: hidden;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @each $property, $value in $default-titles {
        #{$property}: $value;
    }
}

button,
input,
textarea {
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

button {
    -webkit-appearance: none;
    padding: 0;
    background-color: transparent;
    border: none;
}

img {
    max-width: inherit;
}

a,
img,
span,
button {
    display: inline-block;
}

a {
    color: $default-link-color;
    text-decoration: none;

    &:hover {
        color: $default-link-color;
    }
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
    }
}

.m-a {
    margin: auto;
}
.d-b {
    display: block;
}
