/*
|
| Page Loader
|--------------
*/
.page-loader {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: $white;
    display: none;
    &.active{
        display: flex;
    }
    .item-content{
        width: 100%;
        color: $gold;
        text-align: center;
        svg{
            width: 120px;
        }
    }

    .item-loadbar{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        margin: auto;
        .item-loadbar-inner{
            width: 100%;
            height: 100%;
            border-radius: 1px;
            background-color: $gold;
            animation: loadbar 1.2s cubic-bezier(.92, 0, .09, 1);
            transform-origin: left top;
        }
    }
}

@keyframes loadbar{
  from {transform: scaleX(0)}
  to {transform: scaleX(0.7)}
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  transform: scale(0.7);
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid $gold;
  border-color: $gold transparent $gold transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.container-loader-btn{
    position: relative;
    .btn-target{
        opacity: 1;
        display: inline-flex;
    }
    .loader{
        display: block;
        pointer-events: none;
        position: absolute;
        width: max-content;
        height: max-content;
        left:0; right:0;
        top: -25px ;margin: auto;
        transition: 0.2s linear;
        opacity: 0;
        pointer-events: none;
    }

    &.is-loading{
        .btn-target{
            opacity: 0;
            pointer-events: none;
        }
        .loader{
            top: -5px ;
            opacity: 1;
        }
    }
    &.is-finish{
        .btn-target{
            opacity: 0;
            display: none;
            pointer-events: none;
        }
        .loader{
            opacity: 0;
            display: none;
            pointer-events: none;
        }
    }
}