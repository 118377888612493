/*
|
| Banner
|---------
*/
.banner{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    .item-content{
        width: 100%;
    }
    .lazy-ctr .img-placeholder{
    	background: $burgundy;
	}
}

.banner--single{
    padding-top: 200px;
    padding-bottom: 20px;
}

.banner--default{
    min-height: auto;
    align-items: flex-end;
    overflow: hidden;
	.item-content{
		position: relative;
		z-index: 5;
        padding: 120px  0;
		.item-title{}
		.item-text{
			p{
				font-size: 17px !important;
			}
		}
		.item-btn{ }
	}
	.banner-background{
		background-color: $gold;
	}

	&.bannerSmall{
		.item-content{
        	padding: 100px  0;
		}
	}

	&.banneHome{
		min-height: 60vh;
		align-items: center;
		.item-content{
       		 padding: 100px  0;
		}
	}
	&.bannerEvents{
		.item-content{
			padding: 80px 0px;
		}
		.lazy-ctr .img-placeholder{
			background-color: $burgundy;
		}
	}
	.eventsCard-link{
		&.banner-date{
			margin-top: 15px;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    border-bottom: 0px;
		    .eventsCard-date{
		    	padding-right: 45px;
		    	width: 120px;
		    	color: $white;
	    		&.--au{
					padding: 0px;
		    		width: auto;
					&:after{ display: none;}
				}
				&.--start{
					padding-right: 25px;
					&:after{ display: none;}
				}
				&.--end{
					padding-left: 25px;

				}
		    }
		    .eventsCard-lieu{
		   		padding-left: 50px;
	   			padding-right: 10px;
		        width: max-content;
		   	} 
		}
	}
}
