/*
|--------------------
|      FOOTER
|--------------------
*/
#ot-sdk-btn-floating{
    display: none !important;
}
.item-nav-marque{
    margin-left: 0;
}
.item-rs-marque{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px;
    margin-bottom: 10px;
    .ul-rs{
        display: flex;
        align-items: center;
        .item-rs{
            margin-right: 15px;
            &:last-child{
                margin-right: 0px;
            }
        }
    }
    @include media-breakpoint-down(md) {
        flex-direction: column;
        justify-content: center;

        > p{
            font-size: 14px !important;
            margin-bottom: 10px;
        }
    }
}

#footer{
    position: relative;
    padding: 50px 0px;
    overflow: hidden;
    background-color: $light-gold;
    .footer-top{
        position: relative;
    }

    .footer-bottom{
        padding-top: 20px;
        position: relative;
        .item-row{
            position: relative;
        }
        .cms-no-break{
            p{
                color: $brown;
                font-size: 16px;
            }
            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
                max-width: 100%;
                text-align: center;
                font-size: 0.9em;
            }   

            ul{
                padding: 0px;
                padding-top: 30px;
                display: flex;
                align-items: flex-end;
                list-style: none;
                margin: 0px;
                @include media-breakpoint-down(md) {
                    flex-direction: column;
                    align-items: center;
                }

                li{
                    display: inline-block;
                    position: relative;
                    padding-left: 0;
                    margin-left: 0;
                    margin-right: 15px;
                    font-size: 14px;
                    @include media-breakpoint-down(md) {
                        font-size: 14px;
                        margin-bottom: 10px;
                    }
                    &:last-child{
                        margin-right: 0px;
                    }
                    #ot-sdk-btn{
                        color: $brown;
                        border: 0px;
                        font-size: 14px;
                        padding: 0;
                        &:hover{
                            background: none;
                            color: $gold;
                            transition: 0.2s linear;
                        }
                    }
                    a{
                        text-decoration: underline;
                        transition: 0.2s linear;
                        color: $brown;
                    }
                    &:hover{
                       a{
                            color: $gold;
                       }
                    }
                 
                }
            }
        }
    }


    .item-link{
        color: $brown;
        font-size: 16px;
        font-weight: 400;
        transition: 0.2s linear;
        margin-bottom: 15px;
        &:hover{
            text-decoration: underline;
            color: $gold;
        }
    }
   
    .col-logo{
        margin-bottom: 15px;
        @include media-breakpoint-down(sm) {
            text-align: center;
            margin-bottom: 35px;
        }  
        .item-logo{
            width: 90px;
            height: auto; 
        }
    }
    .col-nav{
        ul{
            display: flex;
            flex-wrap: wrap;
            .menu-item{
                flex: 0 0 33%;
                max-width: 33%;
                @include media-breakpoint-down(sm) {
                    text-align: center;
                }
            }
        }
    }
    .col-rs{
        padding-top: 60px;
        margin-bottom: 5px;
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
        .item-link{
            margin-bottom: 15px;
        }
    }



}