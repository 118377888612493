.docCard-ctr{
	margin-bottom: 30px;
    @include media-breakpoint-down('md'){
		margin-bottom: 20px;
	}
}
.docCard-content{
	display: flex;
    height: 100%;
    overflow: hidden;
    padding: 0px;
    position: relative;
	transition: 0.2s linear;
	padding: 40px;
	background-color: $light-gold;
	display: flex;
	align-items: center;
    @include media-breakpoint-down('md'){
		padding: 30px 15px;
	}
	.docCard-detail{
		flex:1;
		margin-right: 20px;
		display: flex;
	    flex-direction: column;
	    align-items: flex-start;
	    justify-content: center;
	}
	.docCard-link {
	    display: flex;
	    align-items: flex-end;
	}
	&:hover{
		text-decoration: none;
	}
}


.blocCard-ctr{
	margin-bottom: 30px;
}
.blocCard-content{
    height: 100%;
    overflow: hidden;
    padding: 0px;
    position: relative;
	transition: 0.2s linear;
	display: flex;
	justify-content: center;
	flex-direction: column;
	.blocCard-bg-ctr{
		position: absolute;
		z-index: 1;
		height: 100%;
		width: 100%;
	}
	.blocCard-detail{
		position: relative;
		padding:  60px 40px ;
		z-index: 20;
		flex: auto;
		font-size: 0.9em;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.btn-list{
			
		}
		.blocCard-icon{
			text-align: center;
			img, svg{
				width: 20px;
			}
		}
	}
	&:hover{
		text-decoration: none;
		
	}
}


.keyCard-ctr{
	margin-bottom: 30px;
}
.keyCard-content{
    height: 100%;
    overflow: hidden;
    padding: 0px;
    position: relative;
	transition: 0.2s linear;
	background-color: $light-gold;
	display: flex;
	justify-content: center;
	flex-direction: column;
	.keyCard-bg-ctr{
		width: 100%;
	}
	.keyCard-detail{
		flex: auto;
		font-size: 0.9em;
		display: flex;
		flex-direction: column;
		align-items: center;
	    text-align: center;
		padding: 20px 40px;
		justify-content: center;
		.keyCard-title{
			color: $burgundy;
		}
		.keyCard-text{
			
		}
		.keyCard-icon{
			text-align: center;
			img, svg{
				width: 26px;
			}
		}
	}
	&:hover{
		text-decoration: none;
		
	}
}


.ficheCard-ctr{
	margin-bottom: 30px;
}
.ficheCard-link{
	display: flex;
    height: 100%;
    overflow: hidden;
    padding: 0px;
    position: relative;
	transition: 0.2s linear;
	padding: 40px;
	background-color: $light-gold;
	@include media-breakpoint-down(sm){
		padding: 30px;
	}
	.ficheCard-content{
		position: relative;
    	z-index: 10;
    	display: flex;
    	flex-direction: column;
    	justify-content: space-between;
    	@include media-breakpoint-down(sm){
    		flex-direction: column;
    	}
	}
	> div{
		width: 100%;
	}
	.ficheCard-title{
		margin-bottom: 15px;
		padding-top: 5px;
		color: $burgundy;
		min-height: 20px;
	}

	.ficheCard-detail{
		font-size: 0.9em;
		display: flex;
		align-items: center;
		color: $green;
	}
	.ficheCard-text {
		min-height: 50px;
		color: $light-brown;
	}
	.btn-list{
		margin-top: 5px;
	}

	&:hover{
		text-decoration: none;
		
	}
}