/*
|
| Bg img default
|----------------------
|
*/
$bg-img-default : (
    background-size: cover,
    background-position: center center,
    background-repeat: no-repeat
);

/*
|-------------------
| Bg img creation
|-------------------
| - Generated classes: .bg-img-{size}
|
*/
$bg-imgs: (
    'video' : 56.25%,
    'square' : 100%,
    'square-sm' : 80%,
    'landscape' : 70%,
    'landscape-sm' : 56%,
    'landscape-xs' : 30%,
    'portrait' : 148%
);


.bg-img{
    @include generate-properties-from-map($bg-img-default);
    &:after {
        content: '';
        display: block;
    }
}

@each $key, $value in $bg-imgs{
    .bg-img-#{$key}{
        @extend .bg-img;
        &:after{
            padding-bottom: $value;
        }
    }
}

// Images Container
.img-cover{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 0;
    margin: auto;
}
.bg-container {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: hidden;
    z-index: 0;
    .img-hero {
        height: 100%;
        width: 100%;
        img {
            height: 100%;
            object-fit: cover;
        }
    }
}

.lazy-ctr{
    display: block;
    width: 100%;
    margin: 0px;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        outline: 1px solid transparent;
        display: block;
        top: 0;
        left: 0;
    }
}

.lazy-contain{
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        position: absolute;
        outline: 1px solid transparent;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}



// Images Portrait
.img-landscape {
    padding-bottom: 56.25%;
}

// Images landscape
.img-portrait {
    padding-bottom: 120%;
}

.img-full{
    max-width: 100%;
    width: auto;
}
// Video container
.video-container {
    padding-bottom: 56.25%;
    height: 0;
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.img-cover{
    
}


/*
-------------------------------- 
LAZYLOAD COMPONENT
--------------------------------
*/
.lazy-ctr {
    position: relative;
    overflow: hidden;
    .img-placeholder {
        position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
        background: $very-light-grey;
        transition: opacity .5s ease;
        transition-delay: .3s;
        pointer-events: none;
        user-select: none;
        z-index: 2;
    }

    .img-lazy{
        z-index: initial;
    }

    &.is-load {
        .img-placeholder {
            opacity: 0;
        }
    }
}
.lazy-ctr-cover{
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.lazy-ctr-contain{
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    img{
        object-fit: contain;
        width: 85%;
        height: 85%;
        text-align: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    &.--full{
        img{
            width: 95%;
            height: 95%;
        }
    }
}
/*
-------------------------------- 
Overlay
--------------------------------
*/
.overlay{
   &:before{
        content: '';
        z-index: 1;
        opacity: 0.5;
        position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
        transition: 0.2s linear;
        background-color: $black; 
   }
}

