.events-list-home-ctr{
    padding-left: 70px;
    @include media-breakpoint-down('md'){
    	padding-left: 15px;
	}
}
.events-list-home{
	background-color: $light-gold;
	padding: 70px 50px; 
	height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media-breakpoint-down('md'){
		padding: 70px 20px 30px 20px; 
	}
	.list-events{
		.title{
			width: 100%;
		}
	}
	.btn-list{
		flex: auto;
	    display: flex;
	    align-items: flex-end;
	}
}
.events-list-agenda{
	background-color: $light-gold;
	padding: 70px 50px 50px 50px; 
    @include media-breakpoint-down('md'){
    	padding: 40px 15px 50px 15px;
	}
	.eventsCard-ctr{
		margin-bottom: 20px;
	}
}



.list-filter-events{
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 0px;
	.item-events{
		list-style: none;
	    @include media-breakpoint-down(md){
			flex: 0 0 50%;
		    text-align: center;
		}
		.label-events{
			position: relative;
			border: 1px solid $white;
			margin-bottom: 10px;
			margin-top: 10px;
			margin-right: 20px;
	    	padding: 10px 40px;
   			font-weight: 200;
   			text-transform: uppercase;
		    line-height: 1.1em;
		    @include media-breakpoint-down(lg){
		    	padding: 10px 30px;
		    }
   			span{
   				position: relative;
   				z-index: 1;
				color: $white;
   			}
   			&.is-active{
				background-color: $white;
				 span{
					color: $gold;
				}
			}
		}
		&:last-child{
			label{
				margin-right: 0px;
			}
		}
	}
}

/*
*
*	ressourceCard
*---------------
*/
.eventsCard-ctr{
	padding-right: 0px;
    	@include media-breakpoint-down(sm){
    		padding-left: 0px;
    	}
}
.eventsCard-link{
    width: 100%;
    display: flex;
    overflow: hidden;
    padding: 0px;
    position: relative;
	transition: 0.2s linear;
	border-bottom: 1px solid rgba($light-brown,0.2);
	.eventsCard-content{
		width: 100%;
		padding:  15px 40px 15px 0;
		position: relative;
    	z-index: 10;
    	display: flex;
    	flex-wrap: wrap;
    	align-items: center;
	    width: 100%;
	    color: $burgundy;
	    box-sizing: border-box;
    	@include media-breakpoint-down(sm){
    	}
	}
	> div{
		width: 100%;
	}
	.eventsCard-date{
		padding-right: 15px;
		text-align: center;
		width: 80px;
		position: relative;
		&:after{
			content: '';
			position: absolute;
			margin: auto;
			top: 0;
			bottom: 0;
			right: 0;
			height: 50px;
			width: 4px;
			background-color: $green;
			transform: translateX(50%);
		}

    	@include media-breakpoint-down(sm){
    		flex: 0 0 33%;
    		max-width: 33%;
		}
		.day-date{
			font-size: 2em;
			line-height: 1em;
			font-weight: 600;
		}
		.month-date{
			font-size: 0.9em;
			font-weight: 400;
		}
		.year-date{
			font-weight: 200;
			font-size: 1.1em;
			line-height: 1.2em;
		}
	
	}
	.eventsCard-title{
		padding:  0px 10px 0px 25px;
		flex: 1;

    	@include media-breakpoint-down(sm){
    		flex: 0 0 66%;
    		max-width: 66%;
		}
	}
	.eventsCard-lieu{
		display: flex;
		align-items:center;
		padding-left: 0;
		padding-right: 10px;
		width: 120px;

    	@include media-breakpoint-down(sm){
    		flex: 0 0 100%;
    		max-width: 100%;
			padding-left: 9px;
			padding-top: 5px;
		}
	}
	.eventsCard-svg{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		height: max-content;
	}
	
	&:hover{
		text-decoration: none;
		
	}
}


.swiper-agenda-ctr{
	@include media-breakpoint-down(sm){
		padding: 0px;
	}
}


.swiper-team-ctr,
.swiper-agenda-ctr{
	.non-swiper-slide{
		display: none !important;
	}
	.swiper-agenda{


	}
    .swiper-navigation{
    	margin-top: 50px;
    	display: flex;
    	align-items: center;
    	justify-content: flex-end;
    	.swiper-scrollbar{
    		height: 2px;
    		width: 100%;
    		.swiper-scrollbar-drag{
    			background-color: $gold;
    		}
    	}
    	.swiper-button{
		    display: flex;
		    align-items: center;
		    padding-left: 2px;
		    .swiper-btn-prev{
		    	svg{
		    		transform: rotate3d(0, 1, 0, 180deg);
		    	}
		    }
    		.swiper-btn-prev,
    		.swiper-btn-next{
    			padding-left: 15px;
    			position: relative;
    			left: auto; top: auto; bottom: auto; right: auto;
    			margin: 0px;
    			transform: none;
    			 &.swiper-button-disabled{
			    	opacity: 0.5;
			    }
			    &:hover{
			    	cursor: pointer;
			    }
    		}  
    	}
    }
}