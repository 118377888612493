.section-missions{

}

.list-missions{
	.item-mission-ctr{
	}
}

.item-mission{
    position: relative;
	padding: 20px 60px 20px 0;
	display: flex;
	align-items: center;
	.icone-mission{
	    width: 50px;
    	margin-right: 15px;
    	transition: 0.2s $easeInOutQuad;
	}
	.icone-text{
		flex: 1;
		transition: 0.2s $easeInOutQuad;
	}
	.icone-svg{
		position: absolute;
		right: 10px;
		top: 0;
		bottom: 0;
		margin: auto;
	    height: max-content;
	}
	&:hover{
		.icone-mission{
			transform: translateX(15px);
		}
		.icone-text{
			transform: translateX(20px);
			color: $gold;
		}
	}
}