.actu-list-home-ctr{

}
.actu-list-home{
	padding-top: 70px;
}

/*
*
*	ressourceCard
*---------------
*/
.actuCard-ctr{
}
.actuCard-link{
	display: flex;
    height: 100%;
    overflow: hidden;
    padding: 0px;
    position: relative;
	transition: 0.2s linear;
	.actuCard-content{
		padding:  0 0 30px 0;
		position: relative;
    	z-index: 10;
    	display: flex;
    	@include media-breakpoint-down(sm){
    		flex-direction: column;
    	}
	}
	> div{
		width: 100%;
	}
	.actuCard-bg-ctr{
	    width: calc(50% - 15px);
	    margin-right: 30px;
	    position: relative;
	    @include media-breakpoint-down(sm){
	   	 	margin-right: 0px;
    		width: 100%;
    	}
    	.img-cover{
		    bottom: 30px;
    	}
    	.svg{

    	}
	}
	.actuCard-title{
		margin-bottom: 15px;
		padding-top: 5px;
		color: $burgundy;
	}
	.actuCard-detail-ctr{
		padding-bottom: 15px;
		flex: 1;
		text-align: left;
	    @include media-breakpoint-down(sm){
			padding-right: 0;
			padding-bottom: 10px;
	    }
	}
	.actuCard-detail{
		font-size: 0.9em;
		display: flex;
		align-items: center;
		color: $green;
	}
	.actuCard-text {
		min-height: 50px;
		color: $light-brown;
	}
	.btn-list{
		margin-top: 5px;
	}
	.actuCard-bg{
    	position: relative;
    	overflow: hidden;
    	background-color: $grey;
    	.img-fluid{
			transform: scale(1.2);
			transition: 1s cubic-bezier(0.4, 0, 0.2, 1);
		}
	}
	&:hover{
		text-decoration: none;
		.actuCard-bg-ctr{
			.img-fluid{
				transform: scale(1);
			}
		}	
	}
}

.center-svg{
	display: flex;
	justify-content: center;
	align-items: center;
}