@media print {
	.print-section{
	   .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
	        float: left;
	   }
	   .col-sm-4 {
	        width: 33.33333333%;
	   }
	   .title-lg{
	   		font-size: 20px;
	   }
	   .print-btn{
	   	display: none;
	   }
	   .keyCard-content{
	   	background-color: #e0d0a7;
	   }
	   .keyCard-content .keyCard-detail{
	   	padding-left: 15px;
	   	padding-right: 15px;
	   }
	   .clear{
	   	clear: both;
	   }
	}
}